import clsx from 'clsx'
import React from 'react'
import {
  ICompanyFinancialAnalysis,
  IRequestComputeForm,
  IBumiputeraStatus,
} from '../model/SmeModelModel'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { KTIcon } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type TProps = {
  name?: string
  label?: string
  type?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
  value?: IRequestComputeForm | ICompanyFinancialAnalysis
  options: IBumiputeraStatus[]
  error?: string
  required?: boolean
  disabled?: boolean
  onchange?: (
    value: string | number,
    param1: string,
    param2: string,
    param3?: string,
    param4?: string
  ) => void
  tooltip?: any
}

export default function LineSelect({
  name,
  label,
  type,
  param1,
  param2,
  param3,
  param4,
  value,
  options,
  error,
  required,
  disabled,
  onchange,
  tooltip,
}: TProps) {
  const intl = useIntl()
  const getValue = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return value![p1][p2][p3][p4]
    } else if (p1 && p2 && p3) {
      return value![p1][p2][p3]
    } else if (p1 && p2) {
      return value![p1][p2]
    } else if (p1) {
      return value![p1]
    }
  }

  return (
    <>
      <div className='row mb-2'>
        <label
          className={clsx('col-lg-6', 'col-form-label', 'fw-bold', 'fs-6', {
            'bg-light-primary': disabled,
            required: required,
          })}
        >
          {label}{' '}
          {tooltip && (
            <>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id='tooltip-right' className='fs-8'>
                    {tooltip}
                  </Tooltip>
                }
              >
                <span className=''>
                  <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                </span>
              </OverlayTrigger>
            </>
          )}
        </label>
        <div className='col-lg-6 fv-row'>
          <select
            value={getValue(param1!, param2!, param3!, param4!) ?? ''}
            className='form-select'
            aria-label='Select example'
            onChange={(e) => onchange!(e.target.value, param1!, param2!, param3!, param4!)}
          >
            <option value=''>{intl.formatMessage({id: 'SELECT.Please Choose'})}</option>
            {options?.map((item, index) => (
              <option key={index} value={item.name}>
                {intl.formatMessage({id: 'SELECT.' + item.name})}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}
