import React from 'react'

function LoadingOverlay() {
  const styles = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overFlow: 'hidden',
  }
  const stylesBox = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }
  return (
    <>
      <div style={{...styles, position: 'absolute'}}>
        <div style={{...stylesBox, position: 'absolute', textAlign: 'center'}}>Processing...</div>
      </div>
    </>
  )
}

export default LoadingOverlay
