import React, { useState } from 'react'
import MultiRangeSlider from '../../../../components/vendors/MultiRangeSlider'
import { useIntl } from 'react-intl'

function Step6({ companyAnalysis, handleChange }) {
  const intl = useIntl()
  const handleInput = (e) => {
    console.log('SLIDER Min---- ', e.minValue)
    console.log('SLIDER Max---- ', e.maxValue)
    // round e.minValue to the nearest 5
    handleChange(Math.round(e.minValue / 5) * 5, 'companyAnalysis', 'quantitative_factor_weight')
    handleChange(
      100 - Math.round(e.minValue / 5) * 5,
      'companyAnalysis',
      'qualitative_factor_weight'
    )
    // handleChange(e.minValue, 'companyAnalysis', 'quantitative_factor_weight')
    // handleChange(100 - e.minValue, 'companyAnalysis', 'qualitative_factor_weight')
  }
  return (
    <>
      <div className='pb-10 pb-lg-15 mt-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({ id: 'CM.FINANCIAL_ANALYSIS_TO_NON_FINANCIAL_ANALYSIS' })}:
        </h2>
      </div>
      <div className='fw-row'>
        <table className='table table-bordered text-center'>
          <tbody>
            <tr>
              <td className='w-50'>{intl.formatMessage({ id: 'CM.FINANCIAL_ANALYSIS_TO_NON_FINANCIAL_ANALYSIS_MAP' })} </td>
              <td className='w-50'>
                {companyAnalysis.quantitative_factor_weight}:
                {companyAnalysis.qualitative_factor_weight}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='fv-row'>
        <div className='row mb-6'>
          <MultiRangeSlider
            min={0}
            max={100}
            step={5}
            minValue={companyAnalysis.quantitative_factor_weight}
            maxValue={100}
            onChange={(e) => {
              handleInput(e)
            }}
            disableRightThumb
            barRightColor='#009ef7'
            barInnerColor='#ec194e'
            canMinMaxValueSame
          />
        </div>
      </div>
    </>
  )
}

export default Step6