import React from 'react'

type TProps = {
  lineType?: string
  offset?: boolean
}

function TableEqualLine({lineType, offset}: TProps) {
  return (
    <>
      {lineType === 'single' && !offset ? (
        <>
          {/* Double Equal to line */}
          <td
            style={{
              borderTop: '1px solid black',
            }}
          ></td>
        </>
      ) : null}

      {lineType === 'double' && !offset ? (
        <>
          {/* Double Equal to line */}
          <td
            style={{
              borderTop: '1px solid black',
              borderBottom: '1px solid black',
            }}
          ></td>
        </>
      ) : null}

      {lineType === 'single' && offset ? (
        <>
          {/* Double Equal to line */}
          <td
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0)',
            }}
          ></td>
        </>
      ) : null}

      {lineType === 'double' && offset ? (
        <>
          {/* Double Equal to line */}
          <td
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0)',
              borderBottom: '1px solid rgba(0, 0, 0, 0)',
            }}
          ></td>
        </>
      ) : null}
    </>
  )
}

export default TableEqualLine
