import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useAuth } from '../../../hooks/auth';
import axios from '../../../lib/axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';
import ListLoading from './components/ListLoading';
import SearchFilterDropDown from './components/SearchFilterDropDown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { REPORT_TYPE_MAP, CORPORATE_MODEL_REPORT_BURSA } from '../../settings/constants';
import { useToast } from '../../components/ToastProvider';
import LoadingOverlay from './components/compute/components/LoadingOverlay';
import { verifyProductAccessible, getUsageCountByProductKey, convertUnderscoreToSpaceAndConvertAllUppercase } from '../../../util/checkAccessUtil'
import { User, ProductList, ProductDataFetched, Report, ReportData } from '../../shared_models/SharedModel'
import { useIntl } from 'react-intl';

const PRODUCT_IDENTIFIER = REPORT_TYPE_MAP.corporate_model_report_bursa;
const PRODUCT_NAME = CORPORATE_MODEL_REPORT_BURSA;

async function getProductData(): Promise<ProductDataFetched> {
  try {
    const response = await axios.get('api/products/product-data');
    return response.data.products;
  } catch (err) {
    console.error('Error fetching product data:', err);
    return { products: [] };
  }
}

async function getReports(page = 1, column = '', search = ''): Promise<Report> {
  const searchURI = column && search ? `&column=${column}&q=${search}` : '';
  try {
    const url = `/api/model/corporate/list?data_source=klci&page=${page}${searchURI}`;
    const response = await axios.get(url);
    return response.data.data;
  } catch (err: any) {
    console.error('Error fetching reports:', err);
    throw new Error('Failed to fetch report');
  }
}

async function CreateReport(report_type: string): Promise<string> {
  try {
    const url = '/api/model/corporate/init'
    const response = await axios.post(url, {
      data_source: 'klci',
      report_type: report_type,
      product_identifier: CORPORATE_MODEL_REPORT_BURSA,
    });
    return response.data.data.corporate_model_no;
  } catch (err: any) {
    console.error('Error creating report:', err);
    throw new Error('Failed to create report');
  }
}

export function CorporateModelBursa() {
  const { user } = useAuth('auth');
  const navigate = useNavigate();
  const { showSuccess, showError } = useToast();
  const intl = useIntl();

  const [reports, setReports] = useState<Report>({
    data: [],
    links: [],
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' }));
  const [column, setColumn] = useState('company_name');
  const [productsFetched, setProductsFetched] = useState<ProductList | null>(null);
  const [userState, setUserState] = useState({
    createReportAccessible: false,
    userSubscriptionActive: false,
    productAccessible: false,
    useCount: 0,
    usageLimit: 0,
    usageUnlimited: false,
  });

  const titleButton = useMemo(() => {
    if (userState.usageUnlimited) {
      return `${userState.useCount}`;
    } else {
      const totalUsage = user?.usage?.usage_all ?? 0;
      const remainingUsage = Number(userState.usageLimit) - totalUsage;
      return `${userState.useCount}/${remainingUsage}`;
    }
  }, [userState, user?.usage?.usage_all]);

  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    try {
      const [reportsData, productData] = await Promise.all([
        getReports(),
        getProductData()
      ]);
      setReports(reportsData);
      setProductsFetched(productData);
    } catch (error) {
      console.error('Error loading data:', error);
      showError('Failed to load initial data');
    } finally {
      setLoading(false);
    }
  }, [showError]);

  useEffect(() => {
    if (user && user?.role !== 'Super Admin') {
      setUserState({
        createReportAccessible: user.subscription_active || user.role === 'Super Admin',
        userSubscriptionActive: user.subscription_active || false,
        productAccessible: verifyProductAccessible(user, PRODUCT_NAME, PRODUCT_IDENTIFIER),
        useCount: getUsageCountByProductKey(user, PRODUCT_IDENTIFIER) ?? 0,
        usageLimit: user.limit?.total_limit || 0,
        usageUnlimited: user.usage?.usage_is_unlimited || false,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user?.has_personal_subscription === true && user?.subscription_active === true) {
      fetchInitialData();
    } else {
      setReports({
        data: [],
        links: [],
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
      });
    }
  }, [fetchInitialData, user]);

  const handlePage = useCallback(async (page: number) => {
    setLoading(true);
    try {
      const reportsData = await getReports(page, column, search);
      setReports(reportsData);
    } catch (error) {
      console.error('Error fetching reports:', error);
      showError('Failed to fetch reports');
    } finally {
      setLoading(false);
    }
  }, [column, search, showError]);

  const handleSearchFilter = useCallback((newColumn: string, newFilter: string) => {
    setFilter(newFilter);
    setColumn(newColumn);
  }, []);

  const handleTriggerSearch = useCallback(async () => {
    if (filter !== 'Filter' && search !== '') {
      setLoading(true);
      try {
        const reportsData = await getReports(1, column, search);
        setReports(reportsData);
      } catch (error) {
        console.error('Error searching reports:', error);
        showError('Failed to search reports');
      } finally {
        setLoading(false);
      }
    }
  }, [filter, search, column, showError]);

  const handleReset = useCallback(async () => {
    if (filter !== 'Company Name' || search !== '') {
      setFilter('Company Name');
      setColumn('company_name');
      setSearch('');
      await fetchInitialData();
    }
  }, [filter, search, fetchInitialData]);

  const handleCreateReport = useCallback(async () => {
    try {
      const corporate_model_no = await CreateReport('external');
      navigate(`/corporate-model-bursa/compute`, { state: { corporate_model_no } });
      await fetchInitialData();
    } catch (error) {
      console.error('Error creating report:', error);
      showError('Failed to create report');
    }
  }, [navigate, fetchInitialData, showError]);

  const handleViewDetails = useCallback((corporate_model_no: string) => {
    navigate(`/corporate-model-bursa/compute`, { state: { corporate_model_no } });
  }, [navigate]);

  const isValidUser = useCallback((user: any): user is User => {
    if (user?.role === 'Super Admin' || user?.role === 'Data Analyst') {
      return true;
    }
    return user &&
      typeof user === 'object' &&
      'product_list' in user &&
      userState.userSubscriptionActive;
  }, [userState.userSubscriptionActive]);

  const validUser = useMemo(() => isValidUser(user), [isValidUser, user]);

  const corporateModelBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'CM.CORP_MODEL_BURSA_LIST' }),
      path: '/corporate-model-bursa',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={corporateModelBreadCrumbs}>{intl.formatMessage({ id: 'CM.CORP_MODEL_BURSA_LIST' })}</PageTitle>
      {
        user?.is_company_admin === true ? (<div className="d-flex align-items-center rounded py-5 px-5 bg-light-success"><span className="svg-icon svg-icon-3x svg-icon-danger me-5"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect></svg></span><div className="text-gray-700 fw-bold fs-6">{intl.formatMessage({ id: 'INFO.YOU_ARE_COMPANY_ADMIN' })}</div></div>) : null
      }
      <div className='card'>
        {/* Card header */}
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* Search */}
            <div className='d-flex justify-content-end'>
              <SearchFilterDropDown
                filter={filter}
                onclick={(column, filter) => handleSearchFilter(column, filter)}
              />
              <input
                type='text'
                value={search}
                className='form-control form-control-solid w-250px ms-2'
                placeholder={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className='btn btn-sm btn-light-primary ms-2' onClick={handleTriggerSearch}>
                <KTIcon iconName='search-list' className='fs-2' />
              </button>
              <button className='btn btn-sm btn-light-primary ms-2' onClick={handleReset}>
                <KTIcon iconName='arrows-circle' className='fs-2' />
              </button>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id='tooltip-right' className='fs-8'>
                    • {intl.formatMessage({ id: 'GENERAL.PICK_FILTER' })}
                    <br />
                    • {intl.formatMessage({ id: 'GENERAL.KEY_IN_YOUR_SEARCH' })}
                    <br />• {intl.formatMessage({ id: 'GENERAL.CLICK' })}
                    <span className='badge badge-light-danger'>
                      <KTIcon iconType='solid' iconName='magnifier' className='fs-8' />
                    </span>
                    {intl.formatMessage({ id: 'GENERAL.TO_SEARCH' })}
                    <br />• {intl.formatMessage({ id: 'GENERAL.CLICK' })}
                    <span className='badge badge-light-danger'>
                      <KTIcon iconType='solid' iconName='arrows-circle' className='fs-8' />
                    </span>
                    {intl.formatMessage({ id: 'GENERAL.TO_RESET' })}
                  </Tooltip>
                }
              >
                <span className=''>
                  <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                </span>
              </OverlayTrigger>
            </div>
          </div>
          {/* Card toolbar */}
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end'>
              <div className='btn-group me-3'>
                {user &&
                  (user?.role === 'Super Admin' || user?.role === 'Admin')
                  ? (
                    <button className="btn btn-success btn-sm" onClick={handleCreateReport}>
                      {intl.formatMessage({ id: 'GENERAL.CREATE' })} <i className="ki-duotone ki-plus fs-2"></i>
                    </button>
                  )
                  : (
                    <div className="card h-10 ">
                      <div className="card-header flex-nowrap border-0 pt-9">
                        <div className="card-title m-0">
                          {userState.createReportAccessible
                            && validUser
                            && userState.productAccessible
                            && productsFetched && (
                              <button className="btn btn-primary btn-sm" onClick={handleCreateReport}>
                                {intl.formatMessage({ id: 'GENERAL.CREATE' })} <i className="ki-duotone ki-plus fs-2"></i>
                              </button>
                            )}
                        </div>
                        <div className="card-toolbar m-0">
                        </div>
                      </div>
                      <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                        <div className="fs-2tx fw-bold mb-3">
                          {titleButton}
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
                          <div className="fw-semibold text-gray-500">
                            {intl.formatMessage({ id: 'CM.CORP_MODEL_BURSA' })} {intl.formatMessage({ id: 'GENERAL.CREATED' })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* Card body */}
        <div className='card-body'>
          {loading && <LoadingOverlay />}
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start fw-bolder fs-7 gs-0'>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.REPORT_NO' })}</th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' })}</th>
                  <th className='min-w-100px'>{intl.formatMessage({ id: 'GENERAL.SOURCE' })}</th>
                  <th className='min-w-200px'>
                    {intl.formatMessage({ id: 'GENERAL.STATUS' })}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-right' className='fs-8 text-left'>
                          <ul className='mb-0 py-0 px-3'>
                            <li>
                              <span className='badge badge-secondary'>CREATED</span> {intl.formatMessage({ id: 'GENERAL.CREATED_DESCRIPTION' })}
                            </li>
                            <li>
                              <span className='badge badge-success'>RESULT</span> {intl.formatMessage({ id: 'GENERAL.RESULT_DESCRIPTION' })}
                            </li>
                            <li>
                              <span className='badge badge-primary'>RETRIEVED</span> {intl.formatMessage({ id: 'GENERAL.RETRIEVED_DESCRIPTION' })}
                            </li>
                          </ul>
                        </Tooltip>
                      }
                    >
                      <span className=''>
                        <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.CREATED_BY' })}</th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.CREATE_DATE' })}</th>
                </tr>
              </thead>
              <tbody>
                {reports.data.length > 0 ? (
                  <>
                    {reports.data.map((report: ReportData, i) => (
                      <tr key={report.corporate_model_no}>
                        <td>
                          <span
                            style={{ cursor: 'pointer', fontFamily: 'monospace', fontSize: '1.1rem' }}
                            className='badge badge-light fw-bold'
                            onClick={() => handleViewDetails(report.corporate_model_no)}
                          >
                            {report.corporate_model_no}
                          </span>
                        </td>
                        <td>{report.company_name}</td>
                        <td>
                          {report.data_source === 'klci' ? 'Bursa Malaysia' : 'MARC Universe'}
                        </td>
                        <td>
                          <span
                            className={clsx('badge', {
                              'badge badge-secondary': report.status_c === 'created',
                              'badge badge-success': report.status_c === 'result',
                              'badge badge-primary': report.status_c === 'retrieved',
                            })}
                          >
                            {report.status_c.toUpperCase()}
                          </span>
                        </td>
                        <td>{report?.owners[0]?.user_name ? report?.owners[0].user_name : 'No Data'}</td>
                        <td>{moment(report.created_at).format('DD-MM-YYYY hh:mm A')}</td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'GENERAL.NO_RECORDS' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {loading && <ListLoading />}
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div id='kt_table_users_paginate'>
                <ul className='pagination'>
                  <li className={clsx('page-item', { disabled: reports.current_page === 1 })}>
                    <button
                      className='page-link'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.FIRST' })}
                    </button>
                  </li>
                  <li className={clsx('page-item', { disabled: reports.current_page === 1 })}>
                    <button
                      className='page-link previous'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(reports.current_page - 1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.PREVIOUS' })}
                    </button>
                  </li>
                  {Array(reports.last_page)
                    .fill(1)
                    .map((_, i) => (
                      <li
                        key={i}
                        className={clsx('page-item', { active: reports.current_page === i + 1 })}
                      >
                        <button
                          className='page-link'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handlePage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                  <li
                    className={clsx('page-item', {
                      disabled: reports.last_page === reports.current_page,
                    })}
                  >
                    <button
                      className='page-link next'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(reports.current_page + 1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.NEXT' })}
                    </button>
                  </li>
                  <li
                    className={clsx('page-item', {
                      disabled: reports.last_page === reports.current_page,
                    })}
                  >
                    <button
                      className='page-link'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(reports.last_page)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.LAST' })}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}