export const REPORT_TYPE_MAP = {
  "sme_model": "sme-report-1723004786",
  "CreditMapper_report_2": "creditmapper-report-2-1723004831",
  "corporate_model_report_bursa": "corporate-model-report-1723004859",
  "model_mid_tier": "mid-tier-report-1723872854",
  "corporate_model_marc_universe": "corporate-model-report-marc-universe-1723878243",
  "corporate_model": "corporate-model-report-11112024"
}

export const CORPORATE_MODEL_REPORT_BURSA = 'corporate_model_report_bursa'
export const SME_MODEL_REPORT_INTERNAL = 'sme_model_report_internal'
export const SME_MODEL_REPORT_EXTERNAL = 'sme_model'
export const MODEL_MID_TIER = 'model_mid_tier'
export const CORPORATE_MODEL_MARC_UNIVERSE = 'corporate_model_marc_universe'
export const CORPORATE_MODEL = 'corporate_model'