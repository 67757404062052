import React, { useEffect, useState } from 'react';

// Import images as modules so you can dynamically load them based on conditions
import riskImage1 from "../../../../assets/risks/SME-1.svg";
import riskImage2 from "../../../../assets/risks/SME-2.svg";
import riskImage3 from "../../../../assets/risks/SME-3.svg";
import riskImage4 from "../../../../assets/risks/SME-4.svg";
import riskImage5 from "../../../../assets/risks/SME-5.svg";
import riskImage6 from "../../../../assets/risks/SME-6.svg";
import riskImage7 from "../../../../assets/risks/SME-7.svg";
import riskImage8 from "../../../../assets/risks/SME-8.svg";
import riskImage9 from "../../../../assets/risks/SME-9.svg";
import riskImage10 from "../../../../assets/risks/SME-10.svg";

interface BubbleChartProps {
  cmBand: string;
  isDarkMode: boolean;
}

const ValidCMBand = [
  "SME-1",
  "SME-2",
  "SME-3",
  "SME-4",
  "SME-5",
  "SME-6",
  "SME-7",
  "SME-8",
  "SME-9",
  "SME-10",
];

const BubbleChart: React.FC<BubbleChartProps> = ({ cmBand, isDarkMode }) => {


  if (!cmBand) {
    cmBand = "Risk not found";
  }

  let validCMBand = ValidCMBand.includes(cmBand) ? cmBand : "Risk not found";

  // Use useState to dynamically set the image based on the risk prop
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    // Dynamically update the image source based on the risk prop
    switch (cmBand) {
      case "SME-1":
        setImageSrc(riskImage1);
        break;
      case "SME-2":
        setImageSrc(riskImage2);
        break;
      case "SME-3":
        setImageSrc(riskImage3);
        break;
      case "SME-4":
        setImageSrc(riskImage4);
        break;
      case "SME-5":
        setImageSrc(riskImage5);
        break;
      case "SME-6":
        setImageSrc(riskImage6);
        break;
      case "SME-7":
        setImageSrc(riskImage7);
        break;
      case "SME-8":
        setImageSrc(riskImage8);
        break;
      case "SME-9":
        setImageSrc(riskImage9);
        break;
      case "SME-10":
        setImageSrc(riskImage10);
        break;
      // Add cases for other risk types and corresponding images
      // Example:
      // case "Some other risk":
      //     setImageSrc(riskImage9);
      //     break;
      default:
        cmBand = "Risk not found";
        // Handle case where risk does not match any predefined category
        setImageSrc(''); // Set to a default image or leave empty
        break;
    }
  }, [cmBand]);
  let risk = "General Risk Calculated by the Model"
  return (
    <div style={{
      backgroundColor: isDarkMode ? 'var(--kt-gray-900)' : 'var(--kt-gray-100)',
      color: isDarkMode ? 'var(--kt-text-white)' : 'var(--kt-text-dark)',
    }}>
      {imageSrc && <img src={imageSrc} alt={risk}
        style=
        {{
          position: 'relative',
          width: '88%',
          height: 'auto',
        }} />}
    </div>
  );
};

export default BubbleChart;