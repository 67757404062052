import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useAuth } from '../../../hooks/auth';
import { Company, Companies } from './Companies';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useIntl, FormattedMessage } from 'react-intl';

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'COMPANY.ADMIN.ALL_COMPANIES', // Translation Key
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  }
];

const UserAllCompanies: React.FC = () => {
  const intl = useIntl();
  const { loading, user } = useAuth('auth');
  const { showError } = useToast();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [search, setSearch] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

  const navigate = useNavigate();

  const getCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    try {
      const response = await axios.get<Companies>('/api/companies', {
        params: { search }
      });
      setCompanies(response.data.companies);
      setFilteredCompanies(response.data.companies);
    } catch (error) {
      showError(intl.formatMessage({ id: 'ERROR.FAILED_FETCH_COMPANIES', defaultMessage: 'Failed to fetch companies' }));
      // Ensure "ERROR.FAILED_FETCH_COMPANIES": "Failed to fetch companies" is added to en.json
    } finally {
      setLoadingCompanies(false);
    }
  }, [showError, search, intl]);

  const toggleRow = (id: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleViewCompany = (companyId: number) => {
    if (companyId) {
      navigate(`/companies/user-view-company`, {
        state: { companyId: companyId }
      });
    } else {
      showError(intl.formatMessage({ id: 'ERROR.COMPANY_NOT_FOUND', defaultMessage: 'Company not found' }));
      // Ensure "ERROR.COMPANY_NOT_FOUND": "Company not found" is added to en.json
    }
  };

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleEditClick = (id: number) => {
    navigate(`/companies/edit/${id}`);
  };

  const handleRefresh = () => {
    getCompanies();
  };

  const handleSearchChange = (value: any) => {
    setSearch(value?.value || '');
    getCompanies();
  };

  const searchOptions = companies.map(company => ({
    value: company.name,
    label: company.name
  }));

  const TableHeaderCompany = () => (
    <thead>
      <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
        <th>
          <FormattedMessage id="TABLE.ID" defaultMessage="ID" />
        </th>
        <th></th>
        <th>
          <FormattedMessage id="TABLE.NAME" defaultMessage="Name" />
        </th>
        <th>
          <FormattedMessage id="TABLE.ADDRESS" defaultMessage="Address" />
        </th>
        <th>
          <FormattedMessage id="TABLE.EMAIL" defaultMessage="Email" />
        </th>
        <th>
          <FormattedMessage id="TABLE.PHONE" defaultMessage="Phone" />
        </th>
        <th>
          <FormattedMessage id="TABLE.WEBSITE" defaultMessage="Website" />
        </th>
        <th>
          <FormattedMessage id="TABLE.ACTIONS" defaultMessage="Actions" />
        </th>
      </tr>
    </thead>
  );

  const TableBodyCompany = () => (
    <tbody>
      {filteredCompanies.map((company: Company) => (
        <React.Fragment key={company.id}>
          <tr>
            <td>{company.id}</td>
            <td>
              <Button className="btn btn-sm btn-success" onClick={() => toggleRow(company.id)}>
                <i className="fa-solid fa-circle-info"></i>
              </Button>
            </td>
            <td>{company.name}</td>
            <td>{company.address}</td>
            <td>{company.email}</td>
            <td>{company.phone}</td>
            <td>{company.website}</td>
            <td>
              <Button variant="info" onClick={() => handleEditClick(company.id)}>
                <FormattedMessage id="BUTTON.EDIT" defaultMessage="Edit" />
              </Button>
            </td>
          </tr>
          {expandedRows.has(company.id) && (
            <tr>
              <td colSpan={8}>
                <div style={{ padding: '10px' }}>
                  <p><strong><FormattedMessage id="FIELD.ID" defaultMessage="ID:" /></strong> {company.id}</p>
                  <p><strong><FormattedMessage id="FIELD.NAME" defaultMessage="Name:" /></strong> {company.name}</p>
                  <p><strong><FormattedMessage id="FIELD.ADDRESS" defaultMessage="Address:" /></strong> {company.address}</p>
                  <p><strong><FormattedMessage id="FIELD.EMAIL" defaultMessage="Email:" /></strong> {company.email}</p>
                  <p><strong><FormattedMessage id="FIELD.PHONE" defaultMessage="Phone:" /></strong> {company.phone}</p>
                  <p><strong><FormattedMessage id="FIELD.WEBSITE" defaultMessage="Website:" /></strong> {company.website}</p>
                </div>
                <div className='card'>
                  <div className='card-body'>
                    <Button className='btn btn-md btn-system' onClick={() => handleViewCompany(company.id)}>
                      <i className='fa fa-eye'></i>
                      <FormattedMessage id="BUTTON.VIEW_COMPANY" defaultMessage="View Company" />
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
  );

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>
        <FormattedMessage id="COMPANY.ADMIN.ALL_COMPANIES_TITLE" defaultMessage="All Companies" />
      </PageTitle>
      <div className="card card-flush h-xl-100">
        <div className="card-header pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">
              <FormattedMessage id="COMPANY.ADMIN.ALL_COMPANIES_LABEL" defaultMessage="All Companies" />
            </span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">
              <FormattedMessage id="COMPANY.ADMIN.ALL_COMPANIES_DESCRIPTION" defaultMessage="List of all companies" />
            </span>
          </h3>
          <div className="card-toolbar">
            <div className='d-flex flex-row-fluid'>
              <Button className="btn btn-sm btn-light-system" onClick={handleRefresh}>
                <i className="fa fa-refresh"></i>
                <FormattedMessage id="BUTTON.REFRESH" defaultMessage="Refresh" />
              </Button>
              <div className="d-flex align-items-center ms-auto">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  name="companySearch"
                  options={searchOptions}
                  onChange={handleSearchChange}
                  placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SEARCH_COMPANY', defaultMessage: 'Search company...' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loadingCompanies ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="container" id="kt_docs_content_container">
              <div className="card mb-2">
                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15">
                  <div className="table-responsive">
                    <table className="table table-striped gy-7 gs-7">
                      <TableHeaderCompany />
                      <TableBodyCompany />
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserAllCompanies;
