import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button, Table } from 'react-bootstrap';
import { Company, Member } from './Companies';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';
import AdminSubscriptionFormComponent from '../subscriptions/components/AdminSubscriptionFormComponent';
import { IUserSubscription } from '../subscriptions/Subscription';
import ButtonSubscriptionView from './components/ButtonSubscriptionView';
import { useIntl, FormattedMessage } from 'react-intl';

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: IUserSubscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: boolean;
  subscription_active?: boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'COMPANY.ADMIN.ALL_COMPANIES', // Translation Key
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'COMPANY.DETAILS', // Translation Key
    path: '/companies/admin-view-company',
    isSeparator: true,
    isActive: false,
  },
];

const AdminViewCompany: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();
  const { showError, showSuccess, showWarning } = useToast();

  const [company, setCompany] = useState<Company | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [showModal, setShowModal] = useState(false);

  const fetchData = useCallback(() => {
    setDataLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        setCompany(response.data);
        setMembers(response.data.members || []);
      })
      .catch(() => showError(intl.formatMessage({
        id: 'ERROR.FAILED_FETCH_COMPANY_DETAILS',
        defaultMessage: 'Failed to fetch company details'
      })))
      .finally(() => setDataLoading(false));
  }, [companyId, showError, intl]);

  const fetchUsers = useCallback(() => {
    axios
      .get('/api/user/get-user-list')
      .then((response) => setAllUsers(response.data.data || []))
      .catch(() => showError(intl.formatMessage({ id: 'ERROR.FAILED_FETCH_USERS', defaultMessage: 'Failed to fetch users' })));
  }, [showError, intl]);

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, [fetchData, fetchUsers]);

  const handleAddMembers = () => {
    setShowModal(true);
    fetchUsers();
  };

  const setSubscriptionAsCompanyDefault = async (subscriptionId: string, companyId: string) => {
    const result = await confirm(intl.formatMessage({
      id: 'CONFIRM.SET_SUBSCRIPTION_DEFAULT',
      defaultMessage: 'Are you sure you want to make the subscription as default for this company?',
    }));
    if (result) {
      if (!subscriptionId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_SUBSCRIPTION_ID', defaultMessage: 'No subscription ID given' }));
        return;
      }
      if (!companyId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_COMPANY_ID', defaultMessage: 'No company ID given' }));
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId, company_id: companyId };
        const response = await axios.post('/api/subscription/set-company-default-subscription', payload);

        if (response.status === 200) {
          showSuccess(intl.formatMessage(
            { id: 'SUCCESS.SUBSCRIPTION_SET_DEFAULT', defaultMessage: 'Subscription {subscriptionId} set as company default' },
            { subscriptionId }
          ));
        } else {
          showError(intl.formatMessage({ id: 'ERROR.SUBSCRIPTION_NOT_SET_DEFAULT', defaultMessage: 'Subscription not set as default' }));
        }
      } catch (error) {
        console.error('Error setting subscription as default:', error);
        showError(intl.formatMessage({ id: 'ERROR.FAILED_SET_SUBSCRIPTION_DEFAULT', defaultMessage: 'Failed to set subscription as default' }));
      }
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const setSubscriptionAsNotCompanyDefault = async (subscriptionId: string, companyId: string) => {
    const result = await confirm(intl.formatMessage({
      id: 'CONFIRM.UNSET_SUBSCRIPTION_DEFAULT',
      defaultMessage: 'Are you sure you want to make the subscription as not default for this company?',
    }));
    if (result) {
      if (!subscriptionId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_SUBSCRIPTION_ID', defaultMessage: 'No subscription ID given' }));
        return;
      }
      if (!companyId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_COMPANY_ID', defaultMessage: 'No company ID given' }));
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId, company_id: companyId };
        const response = await axios.post('/api/subscription/unset-company-default-subscription', payload);

        if (response.status === 200) {
          showSuccess(intl.formatMessage(
            { id: 'SUCCESS.SUBSCRIPTION_UNSET_DEFAULT', defaultMessage: 'Subscription {subscriptionId} unset as company default' },
            { subscriptionId }
          ));
        } else {
          showError(intl.formatMessage({ id: 'ERROR.SUBSCRIPTION_NOT_UNSET_DEFAULT', defaultMessage: 'Subscription not unset as default' }));
        }
      } catch (error) {
        console.error('Error unsetting subscription as default:', error);
        showError(intl.formatMessage({ id: 'ERROR.FAILED_UNSET_SUBSCRIPTION_DEFAULT', defaultMessage: 'Failed to unset subscription as default' }));
      }
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const markSubscriptionForAdmin = async (subscriptionId: string) => {
    const result = await confirm(intl.formatMessage({
      id: 'CONFIRM.MARK_SUBSCRIPTION_ADMIN',
      defaultMessage: 'Are you sure you want to make the subscription as default for the admin?',
    }));
    if (result) {
      if (!subscriptionId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_SUBSCRIPTION_ID', defaultMessage: 'No subscription ID given' }));
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId };
        const response = await axios.post('/api/subscription/mark-subscription-admin', payload);

        if (response.status === 200) {
          showSuccess(intl.formatMessage(
            { id: 'SUCCESS.SUBSCRIPTION_MARKED_ADMIN', defaultMessage: 'Subscription {subscriptionId} set as admin default' },
            { subscriptionId }
          ));
        } else {
          showError(intl.formatMessage({ id: 'ERROR.SUBSCRIPTION_NOT_MARKED_ADMIN', defaultMessage: 'Subscription not set as admin default' }));
        }
      } catch (error) {
        console.error('Error setting subscription as admin default:', error);
        showError(intl.formatMessage({ id: 'ERROR.FAILED_MARK_SUBSCRIPTION_ADMIN', defaultMessage: 'Failed to set subscription as admin default' }));
      }
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const unmarkSubscriptionForAdmin = async (subscriptionId: string) => {
    const result = await confirm(intl.formatMessage({
      id: 'CONFIRM.UNMARK_SUBSCRIPTION_ADMIN',
      defaultMessage: 'Are you sure you want to make the subscription as not default for the admin?',
    }));
    if (result) {
      if (!subscriptionId) {
        showError(intl.formatMessage({ id: 'ERROR.NO_SUBSCRIPTION_ID', defaultMessage: 'No subscription ID given' }));
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId };
        const response = await axios.post('/api/subscription/unmark-subscription-admin', payload);

        if (response.status === 200) {
          showSuccess(intl.formatMessage(
            { id: 'SUCCESS.SUBSCRIPTION_UNMARKED_ADMIN', defaultMessage: 'Subscription {subscriptionId} unset as admin default' },
            { subscriptionId }
          ));
        } else {
          showError(intl.formatMessage({ id: 'ERROR.SUBSCRIPTION_NOT_UNMARKED_ADMIN', defaultMessage: 'Subscription not unset as admin default subscription' }));
        }
      } catch (error) {
        console.error('Error unmarking subscription as admin default:', error);
        showError(intl.formatMessage({ id: 'ERROR.FAILED_UNMARK_SUBSCRIPTION_ADMIN', defaultMessage: 'Failed to unset subscription as admin default' }));
      }
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const deleteCompany = async (companyName: string, companyId: string) => {
    const result = await confirm(intl.formatMessage(
      { id: 'CONFIRM.DELETE_COMPANY', defaultMessage: 'Are you sure you want to delete the company {companyName}?' },
      { companyName }
    ));
    if (result) {
      try {
        await axios.post('/api/companies/delete-company', { company_id: companyId });
        showSuccess(intl.formatMessage({ id: 'SUCCESS.COMPANY_DELETED', defaultMessage: 'Company deleted successfully' }));
        navigate('/companies/admin-all-companies');
      } catch {
        showError(intl.formatMessage({ id: 'ERROR.FAILED_DELETE_COMPANY', defaultMessage: 'Failed to delete company' }));
      }
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
      fetchData();
    }
  };


  const handleDeleteCompany = () => {
    if (company) deleteCompany(company.name, company.id.toString());
  };

  const Title = () => {
    return company ? company.name : intl.formatMessage({ id: 'COMPANY.DETAILS_TITLE', defaultMessage: 'Company Details' });
    // Add "COMPANY.DETAILS_TITLE": "Company Details" to en.json
  };

  const deleteTitle = () => {
    return company
      ? intl.formatMessage(
        { id: 'COMPANY.DELETE_COMPANY_TITLE' }
      ) + ' ' + (company?.name ? company.name : '')
      : intl.formatMessage({ id: 'COMPANY.DELETE_COMPANY', defaultMessage: 'Delete Company' });
    // Add "COMPANY.DELETE_COMPANY_TITLE": "Delete {companyName}", "COMPANY.DELETE_COMPANY": "Delete Company" to en.json
  };

  const handleEditNavigation = (id: number) => {
    navigate('/companies/admin-edit-company', { state: { companyId: id } });
  };

  const handleDeleteUserFromCompany = async (id: number, company_id: number) => {
    const result = await confirm(intl.formatMessage(
      { id: 'CONFIRM.DELETE_MEMBER', defaultMessage: 'Are you sure you want to delete this user with ID {userId}?' },
      { userId: id }
    ));
    if (result) {
      axios
        .post('/api/companies/remove-member', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess(intl.formatMessage({ id: 'SUCCESS.MEMBER_REMOVED', defaultMessage: 'Member removed successfully' }));
          fetchData();
        })
        .catch(() => showError(intl.formatMessage({ id: 'ERROR.FAILED_REMOVE_MEMBER', defaultMessage: 'Failed to remove member' })));
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const handleMakeUserAsAdminCompany = async (id: number, email: string, company_id: number) => {
    const result = await confirm(intl.formatMessage(
      { id: 'CONFIRM.MAKE_USER_ADMIN', defaultMessage: 'Are you sure you want to make user {email} an admin?' },
      { email }
    ));
    if (result) {
      axios
        .post('/api/companies/make-admin-group', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess(intl.formatMessage({ id: 'SUCCESS.USER_MADE_ADMIN', defaultMessage: 'Member made as admin successfully' }));
          fetchData();
        })
        .catch(() => showError(intl.formatMessage({ id: 'ERROR.FAILED_MAKE_ADMIN', defaultMessage: 'Failed to make member admin' })));
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const demoteAdmin = async (id: number, email: string, company_id: number) => {
    const result = await confirm(intl.formatMessage(
      { id: 'CONFIRM.DEMOTE_ADMIN', defaultMessage: 'Are you sure you want to demote user {email}?' },
      { email }
    ));
    if (result) {
      axios
        .post('/api/companies/demote-member', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess(intl.formatMessage({ id: 'SUCCESS.ADMIN_DEMOTED', defaultMessage: 'Member demoted successfully' }));
          fetchData();
        })
        .catch(() => showError(intl.formatMessage({ id: 'ERROR.FAILED_DEMOTE_ADMIN', defaultMessage: 'Failed to demote member' })));
    } else {
      showWarning(intl.formatMessage({ id: 'WARNING.ACTION_CANCELLED', defaultMessage: 'You have cancelled the action' }));
    }
  };

  const LimitValue = company?.subscriptions && company.subscriptions[0]?.subscription_usage[0]?.limit;

  const checkLimit = (limit: number) => {
    if (limit === 0) {
      return intl.formatMessage({ id: 'STATUS.UNLIMITED', defaultMessage: 'unlimited' });
      // Add "STATUS.UNLIMITED": "unlimited" to en.json
    } else {
      return String(limit);
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>
        {Title()}
      </PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar mb-5">
            <a href="/companies/admin-all-companies" className="btn btn-sm btn-light-primary">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              <FormattedMessage id="GENERAL.BACK_TO_LIST" defaultMessage="Back to List" />
            </a>
            <Button variant="light-system" className="btn btn-sm" onClick={fetchData}>
              <i className="fa fa-refresh"></i>
              <FormattedMessage id="BUTTON.REFRESH" defaultMessage="Refresh" />
            </Button>
            <Button variant="danger" className="btn btn-sm ms-auto" onClick={handleDeleteCompany}>
              <i className="fa fa-trash"></i>
              {deleteTitle()}
            </Button>
            <Button className="btn btn-sm btn-warning ms-2" onClick={() => handleEditNavigation(Number(companyId))}>
              <i className="fa fa-edit"></i>
              <FormattedMessage id="BUTTON.EDIT_COMPANY" defaultMessage="Edit Company" />
            </Button>
          </div>
          {dataLoading ? <LoadingSpinner /> : (
            <>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-4 mb-xl-10">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa-solid fa-shop bg-light-info"></i>&nbsp;&nbsp;&nbsp;
                        <FormattedMessage id="COMPANY.DETAILS_TITLE" defaultMessage="Company Details" />
                      </h3>
                    </div>
                    <div className="card-body">
                      {company ? (
                        <div>
                          <p><strong><FormattedMessage id="FIELD.ID" defaultMessage="ID:" /></strong> {company.id}</p>
                          <p><strong><FormattedMessage id="FIELD.NAME" defaultMessage="Name:" /></strong> {company.name}</p>
                          <p><strong><FormattedMessage id="FIELD.ADDRESS" defaultMessage="Address:" /></strong> {company.address}</p>
                          <p><strong><FormattedMessage id="FIELD.EMAIL" defaultMessage="Email:" /></strong> {company.email}</p>
                          <p><strong><FormattedMessage id="FIELD.PHONE" defaultMessage="Phone:" /></strong> {company.phone}</p>
                          <p><strong><FormattedMessage id="FIELD.WEBSITE" defaultMessage="Website:" /></strong> {company.website}</p>
                          <p><strong><FormattedMessage id="FIELD.NOTE" defaultMessage="Note:" /></strong> <span dangerouslySetInnerHTML={{ __html: company?.note?.toString() ?? "" }} /></p>
                          <p><strong><FormattedMessage id="FIELD.STATUS" defaultMessage="Status:" /></strong> {company.status}</p>
                        </div>
                      ) : (
                        <p><FormattedMessage id="MESSAGE.NO_COMPANY_DETAILS" defaultMessage="No company details available." /></p>
                        // Add "MESSAGE.NO_COMPANY_DETAILS": "No company details available." to en.json
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h4 className="card-title">
                        <FormattedMessage id="SECTION.COMPANY_SUBSCRIPTIONS" />
                      </h4>
                    </div>
                    <div className="card-body">
                      {company?.subscriptions?.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th><FormattedMessage id="TABLE.SUBSCRIPTION_ID" defaultMessage="Subscription ID" /></th>
                              <th><FormattedMessage id="TABLE.STATUS" defaultMessage="Status" /></th>
                              <th><FormattedMessage id="TABLE.USAGE" defaultMessage="Usage" /></th>
                              <th><FormattedMessage id="TABLE.ACTIONS" defaultMessage="Actions" /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {company.subscriptions.map((subscription) => (
                              <tr key={subscription.id}>
                                <td>{subscription.id}</td>
                                <td>{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.' + subscription.status?.toString() })}</td>
                                <td>
                                  {subscription.subscription_usage?.map((usage) => (
                                    <div key={usage.id}>
                                      <p><strong><FormattedMessage id="FIELD.DEFAULT_SUBSCRIPTION" defaultMessage="Default Subscription:" /></strong> {intl.formatMessage({ id: subscription.is_subscription_admin ? 'STATUS.YES' : 'STATUS.NO', defaultMessage: subscription.is_subscription_admin ? 'Yes' : 'No' })}</p>
                                      <p><strong><FormattedMessage id="FIELD.IS_CHILD_SUBSCRIPTION" defaultMessage="Is Child Subscription:" /></strong> {subscription.parent_subscription_id !== null ? intl.formatMessage({ id: 'STATUS.YES', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'STATUS.NO', defaultMessage: 'No' })}</p>
                                      <p><strong><FormattedMessage id="FIELD.USAGE" defaultMessage="Usage:" /></strong> {usage.usage}</p>
                                      <p><strong><FormattedMessage id="FIELD.LIMIT_EXCEEDED" defaultMessage="Limit Exceeded:" /></strong> {intl.formatMessage({ id: usage.is_limit_exceeded ? 'STATUS.YES' : 'STATUS.NO', defaultMessage: usage.is_limit_exceeded ? 'Yes' : 'No' })}</p>
                                      <p><strong><FormattedMessage id="FIELD.IS_DEFAULT" defaultMessage="Is Default?:" /></strong> {intl.formatMessage({ id: subscription.company_subscription?.[0]?.is_default ? 'STATUS.YES' : 'STATUS.NO', defaultMessage: subscription.company_subscription?.[0]?.is_default ? 'Yes' : 'No' })}</p>
                                      <p><strong><FormattedMessage id="FIELD.LIMIT_VALUE" defaultMessage="Limit Value:" /></strong> {checkLimit(subscription.subscription_usage?.[0].limit)}</p>
                                      <p><strong><FormattedMessage id="FIELD.ASSIGNED_TO" defaultMessage="Assigned To:" /></strong> {subscription.user.email}</p>
                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <ButtonSubscriptionView
                                    flagParentSubscription={subscription.parent_subscription_id !== null}
                                    flagDefaultAdmin={Boolean(subscription.company_subscription?.[0]?.is_default)}
                                    flagDefaultOwnerSubscription={Boolean(subscription.is_subscription_admin)}
                                    subscriptionId={String(subscription.id)}
                                    companyId={companyId}
                                    setSubscriptionAsCompanyDefault={setSubscriptionAsCompanyDefault}
                                    setSubscriptionAsNotCompanyDefault={setSubscriptionAsNotCompanyDefault}
                                    markSubscriptionForAdmin={markSubscriptionForAdmin}
                                    unmarkSubscriptionForAdmin={unmarkSubscriptionForAdmin}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p><FormattedMessage id="MESSAGE.NO_SUBSCRIPTIONS" defaultMessage="No subscriptions available." /></p>
                        // Add "MESSAGE.NO_SUBSCRIPTIONS": "No subscriptions available." to en.json
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  {showModal ? (
                    <div className="card card-flush h-xl-100">
                      <div className="row">
                        <div className="card-header">
                          <h3 className="card-title">
                            <FormattedMessage id="SECTION.ADD_MEMBER" />
                          </h3>
                          <div className="card-toolbar">
                            <Button className="btn btn-sm btn-info" onClick={() => setShowModal(false)}>
                              <FormattedMessage id="BUTTON.CLOSE" defaultMessage="Close" />
                            </Button>
                          </div>
                        </div>
                        <div className="card-body">
                          <AdminSubscriptionFormComponent
                            subscription_id={Number(company?.subscriptions?.[0]?.id) ?? 0}
                            company_id={Number(companyId)}
                            users={allUsers}
                            subscription_limit={Number(LimitValue) ?? 0}
                            initial_share_subscription_amount={0}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="card card-flush h-xl-100">
                      <div className="card-header">
                        <h3 className="card-title">
                          <FormattedMessage id="SECTION.MEMBERS" />
                        </h3>
                        <div className="card-toolbar">
                          <Button variant="primary" onClick={handleAddMembers}>
                            <i className="fa fa-plus"></i>
                            <FormattedMessage id="BUTTON.ADD_MEMBERS" />
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        {members.length ? (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th><FormattedMessage id="TABLE.ID" defaultMessage="ID" /></th>
                                <th><FormattedMessage id="TABLE.NAME" defaultMessage="Name" /></th>
                                <th><FormattedMessage id="TABLE.EMAIL" defaultMessage="Email" /></th>
                                <th><FormattedMessage id="TABLE.ROLE" defaultMessage="Role" /></th>
                                <th><FormattedMessage id="TABLE.ACTIONS" defaultMessage="Actions" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {members.map((member) => (
                                <tr key={member.id}>
                                  <td>{member.id}</td>
                                  <td>{member.user_name}</td>
                                  <td>{member.user_email}</td>
                                  <td>{intl.formatMessage({ id: 'ROLE.' + member.role })}</td>
                                  <td>
                                    {member.role !== 'admin' ? (
                                      <>
                                        <Button variant="danger" size="sm" onClick={() => handleDeleteUserFromCompany(member.id, member.company_id)}>
                                          <i className="fa fa-trash"></i>
                                          <FormattedMessage id="BUTTON.REMOVE" defaultMessage="Remove" />
                                        </Button>
                                        <Button variant="info" size="sm" onClick={() => handleMakeUserAsAdminCompany(member.id, member.user_email, member.company_id)}>
                                          <i className="fa fa-user"></i>
                                          <FormattedMessage id="BUTTON.MAKE_ADMIN" defaultMessage="Make Admin" />
                                        </Button>
                                      </>
                                    ) : null}

                                    {member.role === 'admin' && !member.user_is_admin_subscription ? (
                                      <Button variant="success" size="sm" onClick={() => demoteAdmin(member.id, member.user_email, member.company_id)}>
                                        <i className="fa fa-user"></i>
                                        <FormattedMessage id="BUTTON.DEMOTE_ADMIN" defaultMessage="Demote Admin" />
                                      </Button>
                                    ) : null}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <p><FormattedMessage id="MESSAGE.NO_MEMBERS" defaultMessage="No members available." /></p>
                          // Add "MESSAGE.NO_MEMBERS": "No members available." to en.json
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminViewCompany;
