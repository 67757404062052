import React, { useEffect } from 'react';
import { useForm, SubmitHandler, useFieldArray, Controller } from 'react-hook-form';
import { Form, Button, Col, Row } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useToast } from '../../components/ToastProvider';

interface Feature {
  name: string;
  value: string;
  active: boolean;
}

interface Price {
  amount: number;
  currency: string;
  active: boolean;
  is_default: boolean;
}

interface ProductFormData {
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
  subscription_period: string;
  prices: Price[];
  features: Feature[];
  quantity: number; // Added quantity field
}

const PageBreadCrumbs: Array<PageLink> = [
  {
    title: 'All Product',
    path: '/product/admin-all-product',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Add Product',
    path: '/product/admin-add-product',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const AdminAddProduct: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<ProductFormData>();
  const { fields: featureFields, append: appendFeature, remove: removeFeature } = useFieldArray({ control, name: 'features' });
  const { fields: priceFields, append: appendPrice, remove: removePrice } = useFieldArray({ control, name: 'prices' });
  const { showError, showSuccess, showWarning } = useToast();

  const onSubmit: SubmitHandler<ProductFormData> = async (data) => {
    try {
      await axios.post('/api/products/store', data).then((response)=> {
        if(response.status === 200) {
         showSuccess(response.data.message);
          window.location.href = '/product/admin-all-product';
        } else {
          showError(response.data.message);
        }

      }).catch((error)=> {
        console.log(error)
      });
      // Handle successful submission, e.g., navigate to product list
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  // Watch the prices array to enforce only one default price
  const prices = watch('prices');

  const handleDefaultPriceChange = (index: number) => {
    const updatedPrices = prices.map((price, idx) => ({
      ...price,
      is_default: idx === index,
    }));
    setValue('prices', updatedPrices);
  };

  // Watch the is_subscription field and set quantity to 0 if checked
  const isSubscription = watch('is_subscription');
  useEffect(() => {
    if (isSubscription) {
      setValue('quantity', 0);
    }
  }, [isSubscription, setValue]);

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{'Add Product'}</PageTitle>
      <div className="container">
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Product Information</h3>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="productName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  {...register('name', { required: 'Product name is required' })}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="productType">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" {...register('type', { required: 'Product type is required' })}>
                  <option value="">Select type</option>
                  <option value="Business Report Service">Business Report Service</option>
                  <option value="Business Analytics">Business Analytics</option>
                </Form.Control>
                {errors.type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.type.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="productSettings">
                <Form.Check
                  type="checkbox"
                  label="Product is active"
                  {...register('active')}
                />
                <Form.Check
                  type="checkbox"
                  label="Product is a subscription service"
                  {...register('is_subscription')}
                />
              </Form.Group>

              <Form.Group controlId="subscription_period">
                <Form.Label>Subscription Period</Form.Label>
                <Form.Control as="select" {...register('subscription_period')}>
                  <option value="">Select period</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="annually">Annually</option>
                  <option value="biannually">Biannually</option>
                  <option value="manual">Manual</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="productQuantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  {...register('quantity', { required: 'Quantity is required' })}
                  isInvalid={!!errors.quantity}
                  disabled={isSubscription}
                />
                {errors.quantity && (
                  <Form.Control.Feedback type="invalid">
                    {errors.quantity.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <h4>Prices</h4>
              {priceFields.map((field, index) => (
                <div key={field.id}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`priceAmount-${index}`}>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          {...register(`prices.${index}.amount`, { required: 'Price amount is required' })}
                          isInvalid={!!errors.prices?.[index]?.amount}
                        />
                        {errors.prices?.[index]?.amount && (
                          <Form.Control.Feedback type="invalid">
                            {errors.prices?.[index]?.amount?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`priceCurrency-${index}`}>
                        <Form.Label>Currency</Form.Label>
                        <Form.Control
                          type="text"
                          {...register(`prices.${index}.currency`, { required: 'Currency is required' })}
                          isInvalid={!!errors.prices?.[index]?.currency}
                        />
                        {errors.prices?.[index]?.currency && (
                          <Form.Control.Feedback type="invalid">
                            {errors.prices?.[index]?.currency?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId={`priceActive-${index}`}>
                    <Form.Check
                      type="checkbox"
                      label="Active"
                      {...register(`prices.${index}.active`)}
                    />
                    <Controller
                      control={control}
                      name={`prices.${index}.is_default`}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Default Price"
                          checked={field.value}
                          onChange={() => handleDefaultPriceChange(index)}
                        />
                      )}
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => removePrice(index)} className="mt-2">
                    Remove Price <i className='fa fa-minus'></i>
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={() => appendPrice({ amount: 0, currency: '', active: true, is_default: false })} className="mt-3 btn btn-success">
                Add price +
              </Button>

              <h4>Features</h4>
              {featureFields.map((field, index) => (
                <Row key={field.id}>
                  <Col md={6}>
                    <Form.Group controlId={`featureName-${index}`}>
                      <Form.Label>Feature Name</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.name`, { required: 'Feature name is required' })}
                        isInvalid={!!errors.features?.[index]?.name}
                      />
                      {errors.features?.[index]?.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.features?.[index]?.name?.message || ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId={`featureValue-${index}`}>
                      <Form.Label>Feature Value</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.value`, { required: 'Feature value is required' })}
                        isInvalid={!!errors.features?.[index]?.value}
                      />
                      {errors.features?.[index]?.value && (
                        <Form.Control.Feedback type="invalid">
                          {errors.features?.[index]?.value?.message || ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Form.Group controlId={`featureActive-${index}`}>
                    <Form.Check
                      type="checkbox"
                      label="Active"
                      {...register(`features.${index}.active`)}
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => removeFeature(index)} className="mt-2">
                    Remove Feature <i className='fa fa-minus'></i>
                  </Button>
                </Row>
              ))}
              <Button type="button" onClick={() => appendFeature({ name: '', value: '', active: true })} className="mt-3 btn btn-primary">
                Add feature +
              </Button>

              <div className="mt-3">
                <Button variant="primary" type="submit">
                  Save
                </Button>
                <Button variant="secondary" className="ms-2">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAddProduct;
