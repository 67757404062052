import { IRequestComputeForm, IReceivedComputeForm } from './SmeModelModel'
export type { IRequestComputeForm, IReceivedComputeForm }
export const RequestComputeFormInit: IRequestComputeForm = {
  sme_model_no: '',
  companyInformation: {
    report_type: '',
    financial_year: '',
    three_financial_years: false,
    company_name: '',
    register_number: '',
    incorporate_date: '',
    incorporate_year: '',
    bumiputera_status: '',
    business_type: '',
    company_classification: '',
    company_classification_c: '',
    status_c: '',
    autosave: 0,
    updated_at: '',
  },
  companyNonFinancialAnalysis: {
    litigation_legal: '',
    litigation_legal_c: 0,
    management_experience: '',
    management_experience_c: 0,
    years_in_current_business: '',
    years_in_current_business_c: 0,
    accounting_firm: '',
    accounting_firm_c: 0,
  },
  companyFinancialAnalysis: [
    {
      financial_year_c: '0',
      fy_start_date: '0',
      fy_end_date: '0',
      number_of_months_c: 0,
      incomeStatement: {
        revenue: 0,
        cost_of_sales: -0,
        is_gross_profit_c: 0,
        sga_expenses: -0,
        is_depreciation_amortization: -0,
        other_income: 0,
        other_expenses: -0,
        ebit_c: 0,
        interest_expense: -0,
        ebt_c: 0,
        tax_expense: -0,
        net_profit_c: 0,
        other_comprehensive_income: 0,
        total_comprehensive_income_c: 0,
      },
      cashFlowAnalysis: {
        profit_before_taxation_c: 0,
        adjustments: {
          cf_depreciation_amortization: 0,
          interest_expense_income: 0,
          dividend_income: 0,
          other_adjustments: -0,
        },
        operating_profit_before_wc_changes_c: 0,
        working_capital_changes: {
          cf_inventories: -0,
          cf_trade_receivables: -0,
          cf_other_receivables_prepayment: 0,
          cf_trade_payables: 0,
          cf_amount_due_to_directors_related_parties: -0,
          cf_trade_payables_accruals: 0,
          other_changes_working_capital: 0,
        },
        cash_generated_used_operating_activities_c: 0,
        other_operating_activities: 0,
        tax_paid_refund: -0,
        net_cash_generated_used_operating_activities_c: 0,
        investingActivities: {
          disposal_acquisition_ppe: -0,
          disposal_acquisition_equity_debt: 0,
          dividends_received: 0,
          interest_received: 0,
          other_investing_activities: 0,
          net_cash_generated_used_investing_activities_c: -0,
        },
        financingActivities: {
          issuance_repurchase_shares: 0,
          issuance_repayment_debt: 0,
          dividends_paid: 0,
          interest_paid: 0,
          other_financing_activities: 0,
          net_cash_generated_used_financing_activities_c: 0,
        },
        net_cce_change_c: 0,
        other_adjustments_to_ending_cash: 0,
        cce_start_fin_year: 0,
        cce_end_fin_year_c: 0,
      },
      consolidatedBalanceSheet: {
        assets: {
          ast_inventories: 0,
          ast_trade_receivables: 0,
          ast_other_receivables_prepayments: 0,
          ast_cash_and_cash_equivalents: 0,
          other_current_assets: 0,
          total_current_assets_c: 0,
          property_plant_equipment_net: 0,
          intangible_assets_net: 0,
          deferred_tax_assets: 0,
          other_non_current_assets: 0,
          ast_total_asset_c: 0,
        },
        liabilitiesAndEquity: {
          lieq_trade_payables: 0,
          short_term_borrowings: 0,
          lieq_amount_due_to_directors_related_parties: 0,
          lieq_other_payables_accruals: 0,
          lieq_other_current_liabilities: 0,
          total_current_liabilities_c: 0,
          long_term_borrowings: 0,
          deferred_tax_liabilities: 0,
          other_non_current_liabilities: 0,
          lieq_total_liabilities_c: 0,
          share_capital: 0,
          retained_earnings_losses: 0,
          other_equity: 0,
          accumulated_oci: 0,
          lieq_total_equity_c: 0,
          total_liabilities_equity_c: 0,
        },
      },
      profitabilityEfficiencyRatio: {
        revenue_c: 0,
        per_gross_profit_c: 0,
        gross_profit_margin_c: 0,
        profit_before_tax_c: 0,
        ebitda_c: 0,
        ebitda_margin_c: 0,
        net_profit_margin_c: 0,
        return_on_asset_c: 0,
        return_on_equity_c: 0,
        sales_over_total_assets_c: 0,
        asset_turnover_ratio_c: 0,
      },
      capitalStructure: {
        total_asset_c: 0,
        cs_total_liabilities_c: 0,
        cs_total_equity_c: 0,
        gearing_ratio_c: 0,
        debt_service_ratio_c: 0,
        debt_to_equity_ratio_c: 0,
      },
      cashFlowManagement: {
        cash_flow_from_operation_c: 0,
        cash_flow_from_investing_c: -0,
        cash_flow_from_financing_c: 0,
        net_cash_flow_c: 0,
        cash_and_cash_equivalents_c: 0,
        cash_ratio_c: 0,
        quick_ratio_c: 0,
        current_ratio_c: 0,
        day_receivables_c: 0,
        day_payables_c: 0,
        inventory_turnover_c: 0,
        cash_conversion_cycle_c: 0,
      },
    },
  ],
  companyResult: {
    score_debt_service_ratio_c: 0,
    score_gross_profit_margin_c: 0,
    score_cash_conversion_cycle_c: 0,
    score_gearing_ratio_c: 0,
    score_sales_over_total_assets_c: 0,
    total_score_c: 0,
    probability_default_c: 0,
    cm_band_c: '',
    risk_profile_c: '',
    proposed_credit_limit_c: 0,
    created_at: '',
    updated_at: '',
  },
}
export const ReceivedComputeFormInit: IReceivedComputeForm = {}
