import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button, Table } from 'react-bootstrap';
import { Company, Subscription, Member } from './Companies';
import { useIntl, FormattedMessage } from 'react-intl';

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: Subscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: Boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'COMPANY.ADMIN.ALL_COMPANIES', // Translation Key
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'COMPANY.DETAILS', // Translation Key
    path: '/companies/user-view-company',
    isSeparator: true,
    isActive: false,
  },
];

const UserViewCompany: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();
  const { loading } = useAuth('auth');
  const { showError } = useToast();

  const [company, setCompany] = useState<Company | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const fetchData = useCallback(() => {
    setDataLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        setCompany(response.data);
        setMembers(response.data.members || []);
      })
      .catch(() => showError(intl.formatMessage({ id: 'ERROR.FAILED_FETCH_COMPANY_DETAILS', defaultMessage: 'Failed to fetch company details' })))
      .finally(() => setDataLoading(false));
  }, [companyId, showError, intl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditNavigation = (companyId: number) => {
    navigate('/companies/user-edit-company', { state: { companyId: companyId } });
  };

  const Title = () => (
    company?.name
      ? intl.formatMessage({ id: 'COMPANY.TITLE_WITH_NAME', defaultMessage: 'Company Details for {companyName}' }, { companyName: company.name })
      : intl.formatMessage({ id: 'COMPANY.TITLE', defaultMessage: 'Company Details' })
  );

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>
        <FormattedMessage id="COMPANY.TITLE" defaultMessage="Company Details" />
      </PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar mb-5">
            <a href="/companies/user-all-companies" className="btn btn-sm btn-light-primary me-2">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              <FormattedMessage id="BUTTON.BACK_TO_LIST" defaultMessage="Back to List" />
            </a>
            <Button variant="light-system" className="btn btn-sm" onClick={fetchData}>
              <i className="fa fa-refresh"></i>
              <FormattedMessage id="BUTTON.REFRESH" defaultMessage="Refresh" />
            </Button>
            <Button className="btn btn-sm btn-warning ms-2" onClick={() => handleEditNavigation(Number(companyId))}>
              <i className="fa fa-edit"></i>
              <FormattedMessage id="BUTTON.EDIT_COMPANY" defaultMessage="Edit Company" />
            </Button>
          </div>
          {(loading || dataLoading) ? <LoadingSpinner /> : (
            <>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-4 mb-xl-10">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa-solid fa-shop bg-light-info"></i>&nbsp;&nbsp;&nbsp;
                        <FormattedMessage id="COMPANY.TITLE" defaultMessage="Company Details" />
                      </h3>
                    </div>
                    <div className="card-body">
                      {company ? (
                        <div>
                          <p><strong><FormattedMessage id="FIELD.ID" defaultMessage="ID:" /></strong> {company.id}</p>
                          <p><strong><FormattedMessage id="FIELD.NAME" defaultMessage="Name:" /></strong> {company.name}</p>
                          <p><strong><FormattedMessage id="FIELD.ADDRESS" defaultMessage="Address:" /></strong> {company.address}</p>
                          <p><strong><FormattedMessage id="FIELD.EMAIL" defaultMessage="Email:" /></strong> {company.email}</p>
                          <p><strong><FormattedMessage id="FIELD.PHONE" defaultMessage="Phone:" /></strong> {company.phone}</p>
                          <p><strong><FormattedMessage id="FIELD.WEBSITE" defaultMessage="Website:" /></strong> {company.website}</p>
                          <p><strong><FormattedMessage id="FIELD.NOTE" defaultMessage="Note:" /></strong> <span dangerouslySetInnerHTML={{ __html: company?.note?.toString() ?? "" }} /></p>
                          <p><strong><FormattedMessage id="FIELD.STATUS" defaultMessage="Status:" /></strong> {company.status}</p>
                        </div>
                      ) : (
                        <p><FormattedMessage id="MESSAGE.NO_COMPANY_DETAILS" defaultMessage="No company details available." /></p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h4 className="card-title">
                        <FormattedMessage id="SECTION.SUBSCRIPTIONS" defaultMessage="Subscriptions" />
                      </h4>
                    </div>
                    <div className="card-body">
                      {company?.subscriptions?.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="TABLE.SUBSCRIPTION_ID" defaultMessage="Subscription ID" />
                              </th>
                              <th>
                                <FormattedMessage id="TABLE.STATUS" defaultMessage="Status" />
                              </th>
                              <th>
                                <FormattedMessage id="TABLE.USAGE" defaultMessage="Usage" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {company.subscriptions.map((subscription) => (
                              <tr key={subscription.id}>
                                <td>{subscription.id}</td>
                                <td>{subscription.status.toString()}</td>
                                <td>
                                  {subscription.subscription_usage?.map((usage) => (
                                    <div key={usage.id}>
                                      <p><strong><FormattedMessage id="FIELD.USAGE" defaultMessage="Usage:" /></strong> {usage.usage}</p>
                                      <p><strong><FormattedMessage id="FIELD.LIMIT_EXCEEDED" defaultMessage="Limit Exceeded:" /></strong> {intl.formatMessage({ id: usage.is_limit_exceeded ? 'STATUS.YES' : 'STATUS.NO', defaultMessage: usage.is_limit_exceeded ? 'Yes' : 'No' })}</p>
                                      <p><strong><FormattedMessage id="FIELD.IS_DEFAULT" defaultMessage="Is Default?:" /></strong> {intl.formatMessage({ id: subscription.company_subscription?.[0]?.is_default ? 'STATUS.YES' : 'STATUS.NO', defaultMessage: subscription.company_subscription?.[0]?.is_default ? 'Yes' : 'No' })}</p>
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p><FormattedMessage id="MESSAGE.NO_SUBSCRIPTIONS" defaultMessage="No subscriptions available." /></p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">
                        <FormattedMessage id="SECTION.MEMBERS" defaultMessage="Members" />
                      </h3>
                    </div>
                    <div className="card-body">
                      {members.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="TABLE.ID" defaultMessage="ID" />
                              </th>
                              <th>
                                <FormattedMessage id="TABLE.NAME" defaultMessage="Name" />
                              </th>
                              <th>
                                <FormattedMessage id="TABLE.EMAIL" defaultMessage="Email" />
                              </th>
                              <th>
                                <FormattedMessage id="TABLE.ROLE" defaultMessage="Role" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {members.map((member) => (
                              <tr key={member.id}>
                                <td>{member.id}</td>
                                <td>{member.user_name}</td>
                                <td>{member.user_email}</td>
                                <td>{member.role}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p><FormattedMessage id="MESSAGE.NO_MEMBERS" defaultMessage="No members available." /></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div >
    </>
  );
};

export default UserViewCompany;
