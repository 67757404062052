import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../../../hooks/auth'
import clsx from 'clsx'
import { useNavigate } from 'react-router'

function VerifyEmail() {
  const navigate = useNavigate()
  const {resendEmailVerification} = useAuth('auth')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [waiting, setWaiting] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<null | number>(null)

  useEffect(() => {
    if (status === 'email-already-verified') {
      setLoading(false)
      setWaiting(false)
    }
    if (status === 'verification-link-sent') {
      if (timeLeft === null) {
        setLoading(false)
        setTimeLeft(60)
      }
    }

    if (timeLeft === 0) {
      setTimeLeft(null)
      setWaiting(false)
      setStatus(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [status, timeLeft])

  const handleResendEmailVerification = () => {
    if (status === 'email-already-verified') {
        navigate('/dashboard')
    }
    resendEmailVerification({setStatus})
    setLoading(true)
    setWaiting(true)
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <>
                {/* begin::Title */}
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Please Verify Your Email!</h1>
                {/* end::Title */}

                {/* begin::Text */}
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                  If you haven't receive the email please click resend email button.
                </div>
                {/* end::Text */}

                {/* begin::Illustration */}
                <div className='mb-3'>
                  <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                  <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                  />
                </div>
                {/* end::Illustration */}

                <div className='mb-0'>
                  {status === 'email-already-verified' && <h3>Email already verified.</h3>}
                  {status === 'verification-link-sent' && (
                    <h3>Email verification has been send successfully.</h3>
                  )}
                  {timeLeft !== null && (
                    <h4>If you didn't received the email, you can retry in {timeLeft} seconds.</h4>
                  )}
                </div>
                {/* begin::Link */}
                <div className='mb-0'>
                  <button
                    disabled={waiting}
                    onClick={() => handleResendEmailVerification()}
                    className='btn btn-sm btn-primary'
                  >
                    <span>
                      {status === 'email-already-verified'
                        ? 'Go to Dashboard '
                        : 'Resend Email Verification '}
                    </span>
                    <span
                      className={clsx('spinner-border', 'spinner-border-sm', {
                        'visually-hidden': !loading,
                      })}
                    ></span>
                  </button>
                </div>
                {/* end::Link */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
