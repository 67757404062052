import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';
import Notes from './components/Notes';
import Button from 'react-bootstrap/Button';
import { useIntl } from 'react-intl';

const URL_ENDPOINT = '/api/subscription-requests/get-request';
const URL_DELETE = '/api/subscription-requests/delete-request';
const NAVIGATION_EDIT_PAGE = '/subscriptions/admin-edit-subscription-request';
const NAVIGATION_LIST_PAGE = '/subscriptions/admin-subscription-request';


interface LocationState {
  subscriptionRequestId: string;
}

interface SubscriptionRequestData {
  request: {
    id: string;
    name: string;
    email: string;
    telephone: string;
    organization_name: string;
    organization_telephone: string;
    organization_email: string;
    organization_address: string;
    organization_website: string;
    notes: Note[];
    company: any[]; // Use appropriate typing
    user: {
      id: string;
      name: string;
      email: string;
      telephone: string;
      is_organization: string;
      is_personal: string;
      company_id: string;
    };
  };
}

interface Note {
  id: string;
  user_id: string;
  subscription_request_id: string;
  note: string;
  created_at: string;
  updated_at: string;
}

const fetcher = async ([url, id]: [string, string]) => {
  try {
    const response = await axios.post(url, { id });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const AdminViewSubscriptionRequest: React.FC = () => {
  const intl = useIntl();
  const { showError, showSuccess, showWarning } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth('auth');
  const subscriptionRequestId = (location.state as LocationState)?.subscriptionRequestId;
  const successShownRef = useRef(false);

  const { data, error, isValidating } = useSWR(
    subscriptionRequestId ? [URL_ENDPOINT, subscriptionRequestId] : null,
    fetcher
  );

  useEffect(() => {
    if (error) {
      showError(`Error fetching data: ${error.message}`);
    }
    if (data && !successShownRef.current) {
      showSuccess('Data fetched successfully');
      successShownRef.current = true;
    }
  }, [data, error, showError, showSuccess]);

  const reject = () => {
    showWarning('You have rejected');
  };


  const requestBreadCrumb: PageLink[] = [
    {
      title: intl.formatMessage({ id: 'ALL_SUBSCRIPTION_REQUESTS' }),
      path: '/subscriptions/admin-subscription-request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'VIEW_SUBSCRIPTION_REQUEST' }),
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  const deleteRequest = async (creatorName: string, requestId: string) => {
    const result = await confirm(`${intl.formatMessage({ id: 'CONFIRM.DELETE_SUBSCRIPTION_REQUEST' })} ${creatorName}?`);
    if (result) {
      try {
        const response = await axios.post(URL_DELETE, { id: requestId });
        if (response.status === 200) {
          showSuccess(intl.formatMessage({ id: 'FLASH.SUCCESS_DELETE_SUBSCRIPTION_REQUEST' }));
          navigate(NAVIGATION_LIST_PAGE);
        } else {
          showError('Error deleting request');
        }
      } catch (error: any) {
        showError(`${intl.formatMessage({ id: 'FLASH.ERROR_DELETE_SUBSCRIPTION_REQUEST' })}: ${error.message}`);
      }
    } else {
      reject();
    }
  };

  const handleDeleteRequest = (request: SubscriptionRequestData['request']) => {
    if (request) deleteRequest(request.user.name, request.id.toString());
  };
  const SubscriptionRequestDataDisplay = ({ data }: { data: SubscriptionRequestData }) => (
    <div className="card">
      <div className="card-body">
        {Object.entries(data.request).map(([key, value]) => (
          <div key={key} className="details-item">
            <span className="font-weight-bold">{key.replace(/_/g, ' ')}:</span>
            <span>
              {Array.isArray(value) ? (
                value.length > 0 ? (
                  <ul>
                    {value.map((item, index) => (
                      <li key={index}>{typeof item === 'object' ? JSON.stringify(item) : item}</li>
                    ))}
                  </ul>
                ) : (
                  'No data'
                )
              ) : typeof value === 'object' ? (
                JSON.stringify(value)
              ) : (
                value
              )}
            </span>
          </div>
        ))}
      </div>
      <div className="card-footer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => navigate(NAVIGATION_EDIT_PAGE, { state: { subscriptionRequestId: data.request.id } })}
        >
          {intl.formatMessage({ id: 'EDIT_BTN' })}
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => handleDeleteRequest(data.request)}
        >
          {intl.formatMessage({ id: 'DELETE_BTN' })}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate(NAVIGATION_LIST_PAGE)}
        >
          {intl.formatMessage({ id: 'BACK_BTN' })}
        </Button>
      </div>
    </div>
  );

  const ViewDashboard = () => (
    <>
      <PageTitle breadcrumbs={requestBreadCrumb}>View Subscription Request</PageTitle>
      <div className="container py-4">
        {isValidating ? (
          <LoadingSpinner />
        ) : error ? (
          <p>Error fetching data: {error.message}</p>
        ) : data ? (
          <div className="card card-custom shadow">
            <div className="card-header card-header-stretch">
              <h3 className="card-title">View Subscription Request</h3>
              <div className="card-toolbar">
                <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_7">
                      Subscription Request Data
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8">
                      Notes
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
                  <SubscriptionRequestDataDisplay data={data} />
                </div>
                <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                  <Notes userId={user?.id} subscriptionRequestId={subscriptionRequestId} Notes={data.request?.notes} showSuccess={showSuccess} showError={showError} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>No data found.</p>
        )}
      </div>
    </>
  );

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <ViewDashboard />
    </React.Suspense>
  );
};

export default AdminViewSubscriptionRequest;
