import React, {useEffect, useRef, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import {IRequestComputeForm, RequestComputeFormInit} from './model'
import axios from '../../../lib/axios'
import {numericFormatter} from 'react-number-format'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'
import {IRequestMatrix} from './model/SmeModelModel'
import {Speedometer} from '../../components/Speedometer'
import ReportTitle from '../../components/ReportTitle'
import generatePdfFromUrl from '../../../util/generatePdf'
import '../../../_metronic/assets/css/animateProgressBar.css'
import BubbleChart from './components/BubbleChart'
import '../../../_metronic/assets/css/printPage.css'
import {useIntl} from 'react-intl'
import {
  Watermark,
  StyledCompanyName,
  StyledTableCell,
  StyledValue,
} from '../../components/Watermark'

type LocationState = {
  sme_model_no: string
}
type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
}

async function getReports(sme_model_no: string): Promise<IRequestComputeForm> {
  const url = '/api/model/sme-ccris/view'
  const response = await axios.post(url, {sme_model_no})
  return response.data.data
}

async function getMatrix(name: string = ''): Promise<IRequestMatrix> {
  const url = '/api/model/sme-ccris/matrix'
  const response = await axios.post(url, {name})
  return response.data.data
}

const PrintableArea = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;

  @media print {
    background: white;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`

export default function SmeModelCcrisResult() {
  const location = useLocation()
  const [pageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
  })
  const [computeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const [matrix, setMatrix] = useState<IRequestMatrix>({
    Result: [
      {
        score_factor_min: 0,
        score_factor_max: 0,
        probability_of_default: 0,
        cm_band: '',
        risk_profile: '',
      },
    ],
  })
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  const handleGeneratePdf = async ({reportName}: {reportName: string}) => {
    const urlCapture = window.location.href
    reportName = reportName.replace(/ /g, '_') + '_SME_Model_Result.pdf'

    setPageState({...pageState, loading: true, alert: false, alertTitle: '', alertMessage: ''})

    try {
      await generatePdfFromUrl(urlCapture, reportName)
    } catch (error) {
      console.error('Error:', error)
      setPageState({
        ...pageState,
        loading: false,
        alert: true,
        alertTitle: 'Error generating Pdf',
        alertMessage: 'Error generating pdf',
      })
    } finally {
      setPageState({...pageState, loading: false, alert: false, alertTitle: '', alertMessage: ''})
    }
  }

  useEffect(() => {
    ;(async () => {
      const sme_model_no = (location.state as LocationState)?.sme_model_no || ''
      const report = await getReports(sme_model_no)
      setComputeForm(report)
      const matrixData = await getMatrix('')
      setMatrix(matrixData)
    })()
  }, [location.state])

  const PrintButton = styled.button`
    display: block;

    @media print {
      display: none;
    }
  `

  const PrintButtonContainer = styled.div`
    position: relative;
    z-index: 1000;
    margin: 1rem;

    @media print {
      display: none;
    }
  `

  const addCommaToThousands = (value: string | number): string => {
    if (typeof value === 'number') {
      value = value.toString()
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const smemodelResultBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'SME_M.SME_MODEL'}),
      path: '/sme-model-main',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'SME_M.COMPUTE'}),
      path: '#',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={smemodelResultBreadCrumbs}>
        {intl.formatMessage({id: 'GENERAL.RESULT'})}
      </PageTitle>
      <div ref={ref} className='card mb-5 mb-xl-10'>
        <div className='card-body pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-3'>
                <div className='d-flex flex-column'></div>
                <PrintButtonContainer>
                  <ReactToPrint
                    documentTitle='SME Model Result'
                    bodyClass='text-gray-400 text-primary'
                    content={() => ref.current}
                    trigger={() => (
                      <PrintButton type='button' className='btn btn-light-primary btn-sm'>
                        <KTIcon
                          iconName='printer'
                          iconType='outline'
                          className='fs-3 text-default me-2'
                        />
                        {intl.formatMessage({id: 'GENERAL.PRINT'})}
                      </PrintButton>
                    )}
                  />
                </PrintButtonContainer>
              </div>
            </div>
            <PrintableArea id='for-print'>
              <Watermark>
                <div>
                  <div className='row'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <ReportTitle />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row row-cols-1'>
                        <div className='col'>
                          <div className='d-flex flex-column'>
                            <div>
                              <h2 className='h2ClassTop'>
                                {intl.formatMessage({id: 'GENERAL.COMPANY_NAME'})}
                              </h2>
                            </div>
                            <span>
                              <StyledCompanyName>
                                {computeForm.companyInformation.company_name}
                              </StyledCompanyName>
                            </span>
                            <div className='d-flex flex-column' style={{marginTop: '20px'}}>
                              <div>
                                <h2 className='h2ClassTop'>
                                  {intl.formatMessage({id: 'AFS.BUSINESS_INFORMATION'})}
                                </h2>
                              </div>
                              <table
                                style={{fontSize: '16px', margin: '0', borderCollapse: 'collapse'}}
                              >
                                <tbody>
                                  <tr>
                                    <StyledTableCell style={{width: '35%'}}>
                                      {intl.formatMessage({id: 'COMPANY.REGISTER_NUMBER'})}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      :
                                      <StyledValue>
                                        {computeForm.companyInformation.register_number}
                                      </StyledValue>
                                    </StyledTableCell>
                                  </tr>
                                  <tr>
                                    <StyledTableCell>
                                      {intl.formatMessage({id: 'COMPANY.REFERENCE_NO'})}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      : <StyledValue>{computeForm.sme_model_no}</StyledValue>
                                    </StyledTableCell>
                                  </tr>
                                  <tr>
                                    <StyledTableCell>
                                      {intl.formatMessage({id: 'COMPANY.ISSUE_DATE_TIME'})}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      :{' '}
                                      <StyledValue>
                                        {new Date(
                                          computeForm.companyResult.updated_at
                                        ).toLocaleString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          second: '2-digit',
                                        })}
                                      </StyledValue>
                                    </StyledTableCell>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className='col'>
                          <div className='row'>
                            <div style={{height: '30px'}}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='flex flex-column'>
                        <Speedometer
                          mainTitle={intl.formatMessage({id: 'RESULT.CREDIT_SCORE'})}
                          usage={Number(computeForm.companyResult.total_score_c?.toString())}
                          customSegmentStops={[
                            0,
                            Number(computeForm.companyResult.total_score_c?.toString()),
                            100,
                          ]}
                          segmentColors={['#36486b', '#9fa9a3']}
                          currentValueText={computeForm.companyResult.total_score_c?.toString()}
                          textColor={'#000000'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{transform: 'translateY(-120px)'}}>
                    <div className='col-12'>
                      <div className='d-flex flex-wrap'>
                        <div className='flex flex-column'>
                          <div>
                            <h2 className='h2ClassTop'>
                              {intl.formatMessage({id: 'RESULT.BUSINESS_SUMMARY'})}
                            </h2>
                          </div>
                          <table style={{fontSize: '18px', margin: '0rem'}}>
                            <tr>
                              <td>{intl.formatMessage({id: 'AFS.FINANCIAL_YEAR'})}</td>
                              <td>
                                :{' '}
                                <StyledValue>
                                  {computeForm.companyInformation.financial_year}
                                </StyledValue>
                              </td>
                            </tr>
                            <tr>
                              <td>{intl.formatMessage({id: 'RESULT.COMPANY_TYPE'})}</td>
                              <td>
                                :{' '}
                                <StyledValue>
                                  {computeForm.companyInformation.company_classification_c}
                                </StyledValue>
                              </td>
                            </tr>
                            <tr>
                              <td>{intl.formatMessage({id: 'COMPANY.BUMIPUTERA_STATUS'})}</td>
                              <td>
                                :{' '}
                                <StyledValue>
                                  {computeForm.companyInformation.bumiputera_status}
                                </StyledValue>
                              </td>
                            </tr>
                            <tr>
                              <td>{intl.formatMessage({id: 'COMPANY.BUSINESS_TYPE'})}</td>
                              <td>
                                :{' '}
                                <StyledValue>
                                  {computeForm.companyInformation.business_type}
                                </StyledValue>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{transform: 'translateY(-110px)'}}>
                    <div className='col'>
                      <div className='p-3'>
                        <div className='card-title'>
                          <div
                            className='card card-flush bgi-no-repeat bgi-size-contain'
                            style={{
                              backgroundColor: '#36486b',
                              backgroundImage: "url('/media/patterns/patterns-3.png')",
                              backgroundPosition: 'right',
                            }}
                          >
                            <div className='card-header pt-5' style={{height: '80px'}}>
                              <div className='card-title d-flex flex-column'>
                                <div className='white-text-on-blue-bg'>
                                  <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                    <p style={{fontSize: '24px'}}>
                                      {computeForm.companyResult.cm_band_c ?? ''}
                                    </p>
                                  </span>
                                </div>
                                <div
                                  className='white-text-on-blue-bg'
                                  style={{transform: 'translateY(-45px)'}}
                                >
                                  <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                    {intl.formatMessage({id: 'RESULT.SME_GRADE'})} <sup>1</sup>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='p-3'>
                        <div className='card-title'>
                          <div
                            className='card card-flush bgi-no-repeat bgi-size-contain'
                            style={{
                              backgroundColor: '#36486b',
                              backgroundImage: "url('/media/patterns/vector-1.png')",
                              backgroundSize: '50%  100%',
                              backgroundPosition: 'right',
                            }}
                          >
                            <div className='card-header pt-5' style={{height: '80px'}}>
                              <div className='card-title d-flex flex-column'>
                                <div className='white-text-on-blue-bg'>
                                  <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                    <p style={{fontSize: '24px'}}>
                                      {Number(
                                        computeForm.companyResult.probability_default_c?.toString()
                                      ).toFixed(2) + '%'}
                                    </p>
                                  </span>
                                </div>
                                <div
                                  className='white-text-on-blue-bg'
                                  style={{transform: 'translateY(-45px)'}}
                                >
                                  <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                    {intl.formatMessage({id: 'RESULT.PROBABILITY_OF_DEFAULT'})}{' '}
                                    <sup>2</sup>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='p-3'>
                        <div className='card-title'>
                          <div
                            className='card card-flush bgi-no-repeat bgi-size-contain'
                            style={{
                              backgroundColor: '#36486b',
                              backgroundImage: "url('/media/patterns/vector-1.png')",
                              backgroundSize: '50%  100%',
                              backgroundPosition: 'right',
                            }}
                          >
                            <div className='card-header pt-5' style={{height: '80px'}}>
                              <div className='card-title d-flex flex-column'>
                                <div className='white-text-on-blue-bg'>
                                  <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                    <p style={{fontSize: '24px'}}>
                                      {addCommaToThousands(
                                        computeForm.companyResult.proposed_credit_limit_c?.toString() ??
                                          ''
                                      )}
                                    </p>
                                  </span>
                                </div>
                                <div
                                  className='white-text-on-blue-bg'
                                  style={{transform: 'translateY(-45px)'}}
                                >
                                  <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                    {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT'})}{' '}
                                    <sup>3</sup>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card-body p-9'>
                        <BubbleChart
                          cmBand={computeForm.companyResult.cm_band_c ?? ''}
                          isDarkMode={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='card-body p-9'>
                      <div style={{pageBreakBefore: 'always'}}>
                        <div className='row'>
                          <div style={{height: '10px'}}></div>
                        </div>
                        <div className='row mb-2'>
                          <span className='fw-bold fs-4'>
                            <sup>1</sup>&nbsp;
                            {intl.formatMessage({id: 'RESULT.MARC_SCORE_DEFINITIONS'})}
                          </span>
                        </div>
                      </div>
                      <table className='table border table-row-bordered table-row-gray-300 gy-2'>
                        <thead className='fw-bolder fs-6 text-gray-800'>
                          <tr>
                            <th
                              style={{
                                textAlign: 'justify',
                                padding: '3px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                maxWidth: '150px',
                              }}
                            >
                              {intl.formatMessage({id: 'RESULT.SME_GRADE'})}
                            </th>
                            <th
                              style={{
                                textAlign: 'justify',
                                padding: '3px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                maxWidth: '150px',
                                transform: 'translateX(-120px)',
                              }}
                            >
                              {intl.formatMessage({id: 'RESULT.CREDIT_SCORE'})}
                            </th>
                            <th
                              style={{
                                textAlign: 'justify',
                                padding: '3px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                maxWidth: '150px',
                                transform: 'translateX(-120px)',
                              }}
                            >
                              {intl.formatMessage({id: 'RESULT.PD_PERCENT'})}
                            </th>
                            <th
                              style={{
                                textAlign: 'justify',
                                padding: '3px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                maxWidth: '250px',
                                transform: 'translateX(-120px)',
                              }}
                            >
                              {intl.formatMessage({id: 'RESULT.RISK_PROFILE'})}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {matrix.Result?.map((creditGrade, index) => (
                            <tr
                              key={index}
                              className={clsx('', {
                                'table-active':
                                  computeForm.companyResult.cm_band_c === creditGrade.cm_band,
                              })}
                              style={{textAlign: 'justify', padding: '3px'}}
                            >
                              <td
                                style={{
                                  textAlign: 'justify',
                                  padding: '3px',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  maxWidth: '150px',
                                }}
                              >
                                {creditGrade.cm_band}
                              </td>
                              <td
                                style={{
                                  textAlign: 'justify',
                                  padding: '3px',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  maxWidth: '150px',
                                  transform: 'translateX(-120px)',
                                }}
                              >
                                {creditGrade.score_factor_min}-{creditGrade.score_factor_max}
                              </td>
                              <td
                                style={{
                                  textAlign: 'justify',
                                  padding: '3px',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  maxWidth: '150px',
                                  transform: 'translateX(-120px)',
                                }}
                              >
                                {numericFormatter(creditGrade.probability_of_default!.toString(), {
                                  thousandSeparator: ',',
                                  decimalScale: 2,
                                  fixedDecimalScale: true,
                                })}
                              </td>
                              <td
                                style={{
                                  textAlign: 'justify',
                                  padding: '3px',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  maxWidth: '250px',
                                  transform: 'translateX(-120px)',
                                }}
                              >
                                {intl.formatMessage({id: 'RESULT.' + creditGrade.risk_profile})}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='card-body p-9'>
                      <span className='fw-bold fs-4'>
                        <sup>2</sup>&nbsp;
                        {intl.formatMessage({id: 'RESULT.CREDIT_SCORING_METHODOLOGIES'})}
                      </span>
                      <div
                        className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                        style={{textAlign: 'justify', padding: '3px'}}
                      >
                        {intl.formatMessage({id: 'RESULT.METHODOLOGY_ONE'})}
                      </div>
                      <div
                        className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                        style={{textAlign: 'justify', padding: '3px'}}
                      >
                        {intl.formatMessage({id: 'RESULT.METHODOLOGY_TWO'})}
                      </div>
                      <div className='d-flex flex-wrap' style={{pageBreakBefore: 'always'}}>
                        <table className='table table-striped border table-row-bordered table-row-gray-300 gy-2 text-wrap'>
                          <thead>
                            <tr>
                              <th>{intl.formatMessage({id: 'RESULT.FINANCIAL'})} (50%)</th>
                              <th>{intl.formatMessage({id: 'RESULT.NON_FINANCIAL'})} (50%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>{intl.formatMessage({id: 'RESULT.DEBT_SERVICING'})}</strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.DEBT_SERVICING_DESC'})}
                              </td>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>
                                  {intl.formatMessage({id: 'RESULT.LAWSUIT_LEGAL_DISPUTES'})}
                                </strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.LAWSUIT_LEGAL_DISPUTES_DESC'})}
                              </td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>{intl.formatMessage({id: 'RESULT.PROFITABILITY'})}</strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.PROFITABILITY_DESC'})}
                              </td>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>
                                  {intl.formatMessage({id: 'RESULT.CCRIS'})}
                                </strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.CCRIS_DESC'})}
                              </td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>{intl.formatMessage({id: 'RESULT.EFFICIENCY'})}</strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.EFFICIENCY_DESC'})}
                              </td>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>
                                  {intl.formatMessage({id: 'RESULT.KEY_MANAGEMENT_EXPERIENCE'})}
                                </strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.KEY_MANAGEMENT_EXPERIENCE_DESC'})}
                              </td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>{intl.formatMessage({id: 'RESULT.GEARING'})}</strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.GEARING_DESC'})}
                              </td>
                              <td style={{textAlign: 'justify', padding: '3px 10px'}}>
                                <strong>
                                  {intl.formatMessage({id: 'RESULT.YEARS_IN_OPERATION'})}
                                </strong>
                                <br />
                                {intl.formatMessage({id: 'RESULT.YEARS_IN_OPERATION_DESC'})}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                        style={{textAlign: 'justify', padding: '3px'}}
                      >
                        {intl.formatMessage({id: 'RESULT.FACTOR_DESC'})}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div style={{height: '10px'}}></div>
                  </div>
                  <div className='row'>
                    <div className='card card-custom'>
                      <div className='card-body p-9'>
                        <div className='d-flex flex-column'>
                          <span className='fw-bold fs-4'>
                            <sup>3</sup>&nbsp;
                            {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_TITLE'})}
                          </span>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_DESC_ONE'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_DESC_TWO'})}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='card card-custom'>
                      <div className='card-body p-9'>
                        <div className='d-flex flex-column'>
                          <span className='fw-bold fs-4'>
                            &nbsp;{intl.formatMessage({id: 'RESULT.SME_DISCLAIMER'})}
                          </span>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_ONE'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_TWO'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_THREE'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_FOUR'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_FIVE'})}
                          </div>
                          <div
                            className='d-flex flex-wrap text-gray-600 fs-6 mb-2'
                            style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                          >
                            {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_SIX'})}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Watermark>
            </PrintableArea>
          </div>
        </div>
      </div>
    </>
  )
}
