import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import { useNavigate } from 'react-router-dom';
import { KTCard } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useIntl, FormattedMessage } from 'react-intl';

interface User {
  id: string;
  name: string;
  status: string;
  email: string;
  role: string;
  company: { name: string } | null;
}

const AdminUserList: React.FC = () => {
  const navigate = useNavigate();
  const { loading, user } = useAuth('auth');
  const { showError, showSuccess } = useToast();
  const [users, setUsers] = useState<User[]>([]);
  const selectedId = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const intl = useIntl();
  const titlePage = intl.formatMessage({ id: 'ADMIN_USER_LIST_PAGE_TITLE' });
  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: titlePage,
      path: '/user/admin-all-user',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  useEffect(() => {
    const fetchDataUser = async () => {
      try {
        const response = await axios.get('/api/user/user-list-dashboard');
        setUsers(response.data.data);
        if (initialLoad) {
          showSuccess(intl.formatMessage({ id: 'ADMIN_USER_LIST_SUCCESS_LOAD' }));
          setInitialLoad(false);
        }
      } catch (error) {
        showError(intl.formatMessage({ id: 'ADMIN_USER_LIST_ERROR_LOAD' }));
      }
    };

    fetchDataUser();
  }, [showSuccess, showError, initialLoad, intl]);

  useEffect(() => {
    if (!loading && !['Super Admin', 'Data Analyst'].includes(user?.role ?? '')) {
      showError(intl.formatMessage({ id: 'ADMIN_USER_LIST_UNAUTHORIZED' }));
      navigate('/unauthorized'); // Redirect to an unauthorized page
    }
  }, [loading, user, showError, navigate, intl]);

  const refreshData = async () => {
    try {
      const response = await axios.get('/api/user/user-list-dashboard');
      setUsers(response.data.data);
    } catch (error) {
      showError(intl.formatMessage({ id: 'ADMIN_USER_LIST_ERROR_LOAD' }));
    }
  };

  const navigateAdminAddUser = () => {
    navigate('/user/admin-add-user', {
      state: { userId: selectedId }
    });
  };

  const navigateAdminViewUser = (userId) => {
    navigate('/user/admin-view-user', {
      state: { userId: userId }
    });
  };

  const TableHeader = () => (
    <thead>
      <tr>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_ID" /></th>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_NAME" /></th>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_EMAIL" /></th>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_STATUS" /></th>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_ROLE" /></th>
        <th><FormattedMessage id="ADMIN_USER_LIST_TABLE_HEADER_COMPANY" /></th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  const TableBody = ({ users }: { users: User[] }) => {
    if (users.length > 0) {
      return (
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.status}</td>
              <td>{user.role}</td>
              <td>{user.company?.name ?? 'N/A'}</td>
              <td>
                <Button className="btn btn-success" onClick={() => navigateAdminViewUser(user.id)}>
                  <i className="fa-regular fa-hand"></i> <FormattedMessage id="ADMIN_USER_LIST_BUTTON_SELECT" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6}><FormattedMessage id="ADMIN_USER_LIST_TABLE_NO_USERS" /></td>
          </tr>
        </tbody>
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>
        {titlePage}
      </PageTitle>

      <KTCard className="card-header">
        <div className="card">
          <div className='card-header'>
            <div className="card-toolbar">
              <Button className="btn btn-sm btn-primary" onClick={navigateAdminAddUser}>
                <i className="bi bi-plus"></i>
                <span className="ms-1"><FormattedMessage id="ADMIN_USER_LIST_BUTTON_ADD_USER" /></span>
              </Button>
              <Button className="btn btn-sm btn-warning" onClick={refreshData}>
                <i className="fa fa-refresh"></i>
                <span className="ms-1"><FormattedMessage id="ADMIN_USER_LIST_BUTTON_REFRESH" /></span>
              </Button>
            </div>
          </div>
          <div className='card-body'>
            <Table className='table table-bordered'>
              <TableHeader />
              <TableBody users={users} />
            </Table>
          </div>
        </div>
      </KTCard>
    </>
  );
};

export default AdminUserList;
