import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../../lib/axios';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useToast } from '../../components/ToastProvider';
import { Product } from './Product';
import { SubscribedProduct } from './ProductUsage';
import ProductAttachCardComponent from './components/ProductAttachCardComponent';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';


type LocationState = {
  subscription_id: string;
};


const AdminAttachProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showError, showSuccess } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const subscription_id = (location.state as LocationState)?.subscription_id;
  const [productData, setProductData] = useState<Product[]>([]);
  const [subscribedProductData, setSubscribedProductData] = useState<SubscribedProduct[]>([]);
  const [deactivatedProductData, setDeactivatedProductData] = useState<SubscribedProduct[]>([]);

  const URL_PRODUCT_LIST = '/api/subscription/list-of-available-product';
  const URL_SUBSCRIBED_PRODUCT = '/api/subscription/list-of-subscribed-product';
  const URL_PRODUCT_ATTACH = '/api/subscription/add-product-to-subscription';
  const URL_PRODUCT_REMOVE = '/api/subscription/remove-subscription-product';
  const URL_SUBSCRIPTION_MAKE_UNLIMITED = '/api/subscription/make-unlimited';
  const URL_DEACTIVATED_PRODUCT = '/api/subscription/list-of-deactivated-product';
  const URL_REACTIVATE_PRODUCT = '/api/subscription/reactivate-product-link';


  const formatDescription = (description: string): React.ReactNode => {
    if (!description) return null;

    const formattedDescription = description
      .replace(/\n/g, '<br>')
      .replace(/\\n/g, '')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');

    return (
      <div style={{ fontSize: '0.8rem' }}
        dangerouslySetInnerHTML={{ __html: formattedDescription }} />
    );
  };

  const navigateAllSubscription = () => {
    navigate('/subscriptions/admin-all-subscriptions');
  };

  const navigateAdminSetSubscriptionLimit = () => {
    navigate('/subscriptions/admin-set-subscription-limit', { state: { subscription_id: subscription_id } });
  };


  const makeUsageUnlimited = async (subscription_id: string) => {
    setLoading(true);

    await axios.post(
      URL_SUBSCRIPTION_MAKE_UNLIMITED, {
      subscription_id: subscription_id
    }).then((response) => {
      if (response.status === 200) {
        showSuccess('Usage set to unlimited');
      } else {
        showError('Failed to set usage unlimited');
      }
      setLoading(false);
      loadPage();

    }).catch((error) => {
      setLoading(false);
      loadPage();

      console.log(error);
    });


  }

  const addProduct = async (product) => {
    setLoading(true);

    await axios.post(
      URL_PRODUCT_ATTACH, {
      subscription_id: subscription_id,
      product_id: product.id
    }).then((response) => {
      if (response.status === 200) {
        showSuccess('Product attached successfully');
      } else {
        showError('Failed to attach product');
      }
      setLoading(false);
      loadPage();

    }).catch((error) => {
      setLoading(false);
      loadPage();

      console.log(error);
    });

  };


  const reactivateProductLink = async (product) => {
    setLoading(true);

    await axios.post(
      URL_REACTIVATE_PRODUCT,
      {
        subscription_product_id: product.id,
        status: product.status
      },

    ).then((response) => {
      if (response.status === 200) {
        showSuccess('Product reattached successfully');
      } else {
        showError('Failed to reattached product');
      }
      setLoading(false);
      loadPage();
    }).catch((error) => {
      setLoading(false);
      loadPage();

      console.log(error);
    });
  };


  const removeProduct = async (product) => {
    setLoading(true);
    await axios.post(
      URL_PRODUCT_REMOVE, {
      subscription_id: subscription_id,
      subscription_product_id: product.id
    }).then((response) => {
      if (response.status === 200) {
        showSuccess('Product removed successfully');
      } else {
        showError('Failed to remove product');
      }
      setLoading(false);
      loadPage();
    }).catch((error) => {
      setLoading(false);
      loadPage();

      console.log(error);
    });


  };

  useEffect(() => {
    loadPage();
  }, [subscription_id]);


  const loadPage = async () => {
    setLoading(true);
    // Fetch products from API
    const fetchProduct = async () => {
      try {
        const response = await axios.post(
          URL_PRODUCT_LIST, {
          subscription_id: subscription_id
        });

        setProductData(response.data.data);
        console.log('--- product data --');

        console.log(productData);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchSubscribedProduct = async () => {
      try {
        const response = await axios.post(
          URL_SUBSCRIBED_PRODUCT, {
          subscription_id: subscription_id
        });

        setSubscribedProductData(response.data.data);
        console.log('--- subscribed product data --');

        console.log(productData);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };


    const fetchDeactivatedProduct = async () => {
      try {
        const response = await axios.post(
          URL_DEACTIVATED_PRODUCT, {
          subscription_id: subscription_id
        });

        setDeactivatedProductData(response.data.data);
        console.log('--- deactivated product data --');

        console.log(productData);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    setLoading(true);

    fetchDeactivatedProduct();
    fetchSubscribedProduct();
    fetchProduct();

    setLoading(false);
  }

  return (
    <>
      <div id="kt_app_content" className="app-content  px-0 ">

        {loading === true ? (<LoadingSpinner />) : (
          <>
            {/** ## start content */}


            <div className="mb-15">

              <h1 className="fs-2x text-gray-900 mb-6">{`Product available for attachment to subscription`}</h1>
              <div className="fs-5 text-gray-600 fw-semibold">
                Select one product and click "attach" to  attach it to the subscription

              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                <div className="card card-flush pt-3 mb-5 mb-xl-10">
                  <div className="card-header">
                    {/*--begin::Card title--*/}
                    <div className="card-title">
                      <h2 className="fw-bold">Products Available</h2>
                    </div>
                    {/*--begin::Card title--*/}
                    {/*--begin::Card toolbar--*/}
                    <div className="card-toolbar">
                      <button className='btn btn-primary btn-md' onClick={navigateAllSubscription}>
                        {`View All Subscription`}
                      </button>
                    </div>
                    {/*--end::Card toolbar--*/}
                  </div>
                  {/*--begin::Card body--*/}
                  <div className="card-body pt-3">
                    {/*--begin::Section--*/}
                    <div className="mb-10">
                      {/*--begin::Product table--*/}
                      <div className="table-responsive">
                        {/*--begin::Table--*/}
                        <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                          {/*--begin::Table head--*/}
                          <thead>
                            {/*--begin::Table row--*/}
                            <tr className="border-bottom border-gray-200 text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-150px">{`Product and description`}</th>
                              <th className="min-w-125px">{`Product Id`}</th>
                              <th className="min-w-125px">{`Price`}</th>
                              <th className="min-w-125px">{`Active`}</th>
                              <th className="text-end min-w-70px">Actions</th>
                            </tr>
                            {/*--end::Table row--*/}
                          </thead>
                          {/*--end::Table head--*/}

                          {/*--begin::Table body--*/}
                          <tbody className="fw-semibold text-gray-800">
                            {
                              productData.map((product) => (
                                <tr>
                                  <td>
                                    <label className="w-150px">{product.name}</label>
                                    <span className="text-muted fw-bold d-block">{formatDescription(product.description)}</span>
                                  </td>
                                  <td>{product.id}</td>
                                  <td>${product.price}</td>
                                  <td>{product.active ? 'Yes' : 'No'}</td>
                                  <td className="text-end">
                                    <button className="btn btn-sm btn-light btn-active-light-primary"
                                      onClick={() => addProduct(product)}>Attach Product</button>
                                  </td>
                                </tr>
                              )
                              )}
                          </tbody>
                          {/*--end::Table body--*/}
                        </table>
                        {/*--end::Table--*/}
                      </div>
                      {/*--end::Product table--*/}
                    </div>
                  </div>
                </div>
                {/*--begin::Section--*/}

                {/** second  part  */}

                <div className="mb-15">

                  <h1 className="fs-2x text-gray-900 mb-6">{`Current product linked to subscription`}</h1>

                  <div className="fs-5 text-gray-600 fw-semibold">
                    {`List of product currently linked to subscription, you can remove it for current subscription but user cannot create reports after that.`}
                  </div>
                </div>
                {subscribedProductData.length > 0 ?
                  (

                    subscribedProductData.map((product) => (
                      <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                        <div className="card card-flush pt-3 mb-5 mb-xl-10">
                          <div className="card-header">
                            {/*--begin::Card title--*/}
                            <div className="card-title">
                              <h2 className="fw-bold">{product.product_name}</h2>
                            </div>
                            {/*--begin::Card title--*/}
                          </div>
                          {/*--end::Card Header--*/}
                          {/*--begin::Card body--*/}
                          <div className="card-body pt-3">
                            {/*--begin::Section--*/}
                            <div className="mb-10">
                              <ProductAttachCardComponent
                                product={product}
                                subscription_id={subscription_id}
                                is_parent={product.is_parent_subscription}
                                makeUsageUnlimited={makeUsageUnlimited}
                                removeProduct={removeProduct}
                                reactivateProductLink={reactivateProductLink}
                                navigateAdminSetSubscriptionLimit={navigateAdminSetSubscriptionLimit}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    )

                  ) : (
                    <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                      <div className="card card-flush pt-3 mb-5 mb-xl-10">
                        <div className="card-header">
                          <div className="card-title">
                            {
                              `No product linked to subscription`
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {/*--end::Product table--*/}


                {
                  /** third section   */
                }

                {
                  <div className="mb-15">

                    <h1 className="fs-2x text-gray-900 mb-6">{ }{`Product linked to subscription but set to be deactivated`}</h1>


                    <div className="fs-5 text-gray-600 fw-semibold">
                      {`Click Reattach to subscription to reattach to subscription`}
                    </div>
                  </div>

                }

                {deactivatedProductData.length > 0 ?


                  deactivatedProductData.map((product) => (
                    <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                      <div className="card card-flush pt-3 mb-5 mb-xl-10">
                        <div className="card-header">
                          {/*--begin::Card title--*/}
                          <div className="card-title">
                            <h2 className="fw-bold">{product.product_name}</h2>
                          </div>
                          {/*--begin::Card title--*/}
                        </div>
                        {/*--end::Card Header--*/}
                        {/*--begin::Card body--*/}
                        <div className="card-body pt-3">
                          {/*--begin::Section--*/}
                          <div className="mb-10">
                            <ProductAttachCardComponent
                              product={product}
                              subscription_id={subscription_id}
                              is_parent={product.is_parent_subscription}
                              makeUsageUnlimited={makeUsageUnlimited}
                              removeProduct={removeProduct}
                              reactivateProductLink={reactivateProductLink}
                              navigateAdminSetSubscriptionLimit={navigateAdminSetSubscriptionLimit}

                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  ) :
                  <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                    <div className="card card-flush pt-3 mb-5 mb-xl-10">
                      <div className="card-header">
                        <div className="card-title">
                          {
                            `No product linked to subscription  but set to be deactivated`

                          }
                        </div>
                      </div>
                    </div>
                  </div>

                }
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AdminAttachProduct