import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, Button, Col, Row } from 'react-bootstrap';

interface AdminEditUserData {
  name: string;
  email: string;
  company: string;
  role: string;
}

const AdminEditUser: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<AdminEditUserData>();
  const [formData, setFormData] = useState<AdminEditUserData>({
    name: '',
    email: '',
    company: '',
    role: ''
  });

  const onSubmit: SubmitHandler<AdminEditUserData> = data => {
    setFormData(data);
    console.log('Form Data:', data);
    // Handle form submission, e.g., send data to the server
  };

  return (
    <div className="container">
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">User Information</h3>
        </div>
        <div className="card-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    isInvalid={!!errors.name}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: 'Invalid email address'
                      }
                    })}
                    isInvalid={!!errors.email}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="company">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('company', { required: 'Company name is required' })}
                    isInvalid={!!errors.company}
                  />
                  {errors.company && (
                    <Form.Control.Feedback type="invalid">
                      {errors.company.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    {...register('role', { required: 'Role is required' })}
                    isInvalid={!!errors.role}
                  >
                    <option value="">Select role</option>
                    <option value="User Regular">User Regular</option>
                    <option value="Data Analyst">Data Analyst</option>
                    <option value="Super Admin">Super Admin</option>
                  </Form.Control>
                  {errors.role && (
                    <Form.Control.Feedback type="invalid">
                      {errors.role.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminEditUser;
