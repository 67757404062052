import React, { useState, useEffect } from 'react';
import axios from '../../../../lib/axios';
import { Button, Card } from 'react-bootstrap';
import LoadingSpinner from '../../../../_metronic/helpers/components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { ISubscription } from '../../../pages/subscriptions/Subscription';
import { useIntl } from 'react-intl';

// TypeScript Interfaces
interface TabData {
  [key: string]: any; // Assuming your tab data could have varying structures
}

interface TabComponentProps {
  subscriptionId: number;
  Subscription: ISubscription | null;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
}

// Helper Function for Loading State
const useTabData = (subscriptionId: number, tabId: string) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<TabData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post('/api/subscription/details', {
          id: subscriptionId,
          tab_id: tabId
        });
        setData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching data for', tabId, error);
        setError(intl.formatMessage({ id: 'ERROR.FETCH_TAB_DATA' }));
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [subscriptionId, tabId, intl]);

  return { isLoading, data, error };
};

// Individual Tab Content Components
const Tab1Content = ({
  data,
  subscription,
  showSuccess,
  showError
}: {
  data: TabData | null;
  subscription: ISubscription | null;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const onClickViewAttachProduct = () => {
    if (data) {
      navigate('/subscriptions/admin-attach-product', {
        state: { subscription_id: data.tab1?.id }
      });
    }
  };

  const onClickView = () => {
    if (data) {
      navigate('/subscriptions/admin-view-subscription', {
        state: { subscriptionId: data.tab1?.id }
      });
    } else {
      showError(intl.formatMessage({ id: 'ERROR.NO_DATA_AVAILABLE' }));
    }
  };

  const onClickRefresh = async (subscription: ISubscription) => {
    const URL_REFRESH = '/api/subscription/refresh-subscription';
    try {
      const response = await axios.post(URL_REFRESH, {
        user_id: subscription.userId,
        company_id: subscription.company_data.id,
        subscription_id: subscription.id
      });
      if (response.status === 200) {
        showSuccess(intl.formatMessage({ id: 'SUCCESS.REFRESH_SUBSCRIPTION_USAGE' }));
      } else {
        showError(intl.formatMessage({ id: 'ERROR.FAILED_REFRESH_SUBSCRIPTION_USAGE' }));
      }
    } catch (error) {
      console.error('Error refreshing subscription:', error);
      showError(intl.formatMessage({ id: 'ERROR.FAILED_REFRESH_SUBSCRIPTION_USAGE' }));
    }
  };

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          {intl.formatMessage({ id: 'SUBSCRIPTION.NAME' })}: {data.tab1?.name}
        </Card.Title>
        <Card.Text>
          <strong>{intl.formatMessage({ id: 'SUBSCRIPTION.DESCRIPTION' })}:</strong> {data.tab1?.description} <br />
          <strong>{intl.formatMessage({ id: 'SUBSCRIPTION.STATUS' })}:</strong> {data.tab1?.status} <br />
          <strong>{intl.formatMessage({ id: 'SUBSCRIPTION.START_DATE' })}:</strong> {data.tab1?.start_date} <br />
          <strong>{intl.formatMessage({ id: 'SUBSCRIPTION.END_DATE' })}:</strong> {data.tab1?.end_date}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button className='btn btn-sm btn-info' onClick={onClickView}>
          <i className='fa fa-eye'></i> {intl.formatMessage({ id: 'BUTTON.VIEW' })}
        </Button>
        <Button className='btn btn-sm btn-SYSTEM' onClick={() => onClickRefresh(data.tab1)}>
          <i className='fa fa-refresh'></i> {intl.formatMessage({ id: 'BUTTON.REFRESH_USAGE' })}
        </Button>
        <Button className='btn btn-sm btn-default' onClick={onClickViewAttachProduct}>
          <i className='fa fa-plus'></i> {intl.formatMessage({ id: 'BUTTON.ATTACH_PRODUCT' })}
        </Button>
      </Card.Footer>
    </Card>
  );
};

// Main Tab Component
const TabComponent: React.FC<TabComponentProps> = ({
  subscriptionId,
  Subscription,
  showSuccess,
  showError
}) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('tab1'); // Default to tab1
  const tabs = ['tab1'];
  const tabsName = [intl.formatMessage({ id: 'TAB.SUBSCRIPTION_DETAILS' })];

  // Use the helper function to manage state for each tab
  const { isLoading: isLoadingTab1, data: tab1Data, error: tab1Error } = useTabData(subscriptionId, 'tab1');

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
        {tabs.map((tab, index) => (
          <li key={tab} className="nav-item">
            <a
              className={`nav-link ${activeTab === tab ? 'active' : ''}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(tab);
              }}
            >
              {tabsName[index]}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {activeTab === 'tab1' && (
          <div className="tab-pane fade show active">
            {isLoadingTab1 ? (
              <LoadingSpinner />
            ) : tab1Error ? (
              <div className="alert alert-danger" role="alert">
                {tab1Error}
              </div>
            ) : (
              <Tab1Content
                data={tab1Data}
                subscription={Subscription}
                showSuccess={showSuccess}
                showError={showError}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabComponent;