import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, InputGroup, FormControl, Alert, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../lib/axios';
import { useToast } from '../../../components/ToastProvider';
import { useIntl } from 'react-intl';

interface IUserSubscription {
  id: number;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: IUserSubscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: boolean;
  subscription_active?: boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

interface FormData {
  user_ids: string[];
  has_quota: boolean;
  spread_uniformly: boolean;
  company_id: number;
  shared_subscription_id: number;
  share_subscription_amount: number;
  original_limit: number;
  descriptions: Record<string, string>;
  user_limit: Record<string, number>;
}

interface AdminSubscriptionFormProps {
  subscription_id: number;
  company_id: number;
  users: User[];
  subscription_limit: number;
  initial_share_subscription_amount: number;
}

const AdminSubscriptionFormComponent: React.FC<AdminSubscriptionFormProps> = ({
  subscription_id,
  company_id,
  users,
  subscription_limit,
  initial_share_subscription_amount,
}) => {
  const intl = useIntl();
  const [currentLimit, setCurrentLimit] = useState(subscription_limit);
  const [numSelectedUsers, setNumSelectedUsers] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { register, handleSubmit, control, watch, setValue, reset, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      user_ids: [],
      has_quota: false,
      spread_uniformly: false,
      company_id,
      share_subscription_amount: initial_share_subscription_amount,
      descriptions: {},
      user_limit: {},
      original_limit: subscription_limit,
    },
  });

  const { showError, showSuccess } = useToast();
  const has_quota = watch('has_quota');
  const spread_uniformly = watch('spread_uniformly');
  const share_subscription_amount = watch('share_subscription_amount');
  const user_limit = watch('user_limit');

  useEffect(() => {
    recalculateRemainingLimit();
  }, [selectedUsers, has_quota, spread_uniformly, share_subscription_amount, user_limit]);

  const recalculateRemainingLimit = () => {
    if (spread_uniformly && has_quota) {
      const totalUsedLimit = selectedUsers.length * share_subscription_amount;
      const remainingLimit = Math.max(subscription_limit - totalUsedLimit, 0);
      setCurrentLimit(remainingLimit);
      selectedUsers.forEach(user => setValue(`user_limit.${user.id}`, share_subscription_amount));
    } else {
      const totalUsedLimit = selectedUsers.reduce((acc, user) => acc + (user_limit[user.id] || 0), 0);
      const remainingLimit = Math.max(subscription_limit - totalUsedLimit, 0);
      setCurrentLimit(remainingLimit);
    }
  };

  const onSubmit = async (data: FormData) => {
    if (!data.user_ids || data.user_ids.length === 0) {
      showError('Please select at least one user.');
      return;
    }
    if (data.has_quota && !data.share_subscription_amount) {
      showError('Please enter a quota value.');
      return;
    }
    data.original_limit = subscription_limit;
    setFormSubmitting(true);
    try {
      const response = await axios.post('/api/companies/add-member', data);
      console.log('Server Response:', response); // Log the response for debugging
      if (response.status === 200) {
        showSuccess('User(s) added successfully.');
        reset();
        setNumSelectedUsers(0);
        setSelectedUsers([]);
      } else {
        showError(response.data.message || 'An error occurred.');
      }
    } catch (error: any) {
      console.error('Error:', error); // Log the error for debugging
      if (error.response) {
        showError(error.response.message || 'Failed to add user(s).');
      } else if (error.request) {
        showError('No response received from server.');
      } else {
        showError('Failed to add user(s).');
      }
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleUserChange = (selectedOptions: any) => {
    const selectedUsers = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setNumSelectedUsers(selectedUsers.length);
    setSelectedUsers(selectedOptions.map((option: any) => users.find(user => user.id === option.value)!));
    setValue('user_ids', selectedUsers);
  };

  const handleLimitChange = (userId: string, newLimit: number) => {
    const newUserLimit = { ...user_limit, [userId]: newLimit };
    setValue('user_limit', newUserLimit);
    recalculateRemainingLimit();
  };

  const handleRemoveUser = (userId: string) => {
    const updatedUsers = selectedUsers.filter(user => user.id !== userId);
    setSelectedUsers(updatedUsers);
    setNumSelectedUsers(updatedUsers.length);
    setValue('user_ids', updatedUsers.map(user => user.id));

    // Create a new user_limit object without the removed user
    const newUserLimit: Record<string, number> = { ...user_limit };
    delete newUserLimit[userId];
    setValue('user_limit', newUserLimit);
    if (currentLimit !== 0) {
      recalculateRemainingLimit();
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="user_ids" className="form-label">{intl.formatMessage({ id: 'ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.SELECT_USER' })}</label>
          <Select
            id="user_ids"
            isMulti
            options={users.map(user => ({
              value: user.id,
              label: `${user.name} - ${user.email}`,
            }))}
            placeholder="Select users"
            isSearchable
            onChange={handleUserChange}
            value={selectedUsers.map(user => ({
              value: user.id,
              label: `${user.name} - ${user.email}`
            }))}
          />
          {errors.user_ids && <Alert variant="danger">{errors.user_ids.message}</Alert>}
        </div>

        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Has Quota"
            {...register('has_quota')}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Spread Uniformly"
            {...register('spread_uniformly')}
          />
        </Form.Group>

        {selectedUsers.map(user => (
          <div key={user.id} className="user-fields">
            <Row className="align-items-center mb-3">
              <Col md={3}>
                <Form.Label>{intl.formatMessage({ id: 'USER' })}:</Form.Label>
                <FormControl
                  type="text"
                  value={user.name}
                  readOnly
                />
              </Col>
              <Col md={3}>
                <Form.Label>{intl.formatMessage({ id: 'DESCRIPTION' })}:</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Description"
                  {...register(`descriptions.${user.id}`, {
                    required: 'Description is required',
                  })}
                  isInvalid={!!errors.descriptions?.[user.id]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.descriptions?.[user.id]?.message}
                </Form.Control.Feedback>
              </Col>
              <Col md={3}>
                <Form.Label>{intl.formatMessage({ id: 'ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.LIMIT' })}</Form.Label>
                <Controller
                  name={`user_limit.${user.id}`}
                  control={control}
                  defaultValue={user_limit[user.id] || 0}
                  rules={{ required: 'Limit is required', min: { value: 0, message: 'Limit must be greater than 0' } }}
                  render={({ field }) => (
                    <FormControl
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handleLimitChange(user.id, parseInt(e.target.value));
                      }}
                      isInvalid={!!errors.user_limit?.[user.id]}
                      disabled={spread_uniformly} // Disable if spread_uniformly is checked
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.user_limit?.[user.id]?.message}
                </Form.Control.Feedback>
              </Col>
              <Col md={3} className="text-right">
                <Button variant="danger" onClick={() => handleRemoveUser(user.id)}>
                  {intl.formatMessage({ id: 'DELETE_BTN' })}
                </Button>
              </Col>
            </Row>
          </div>
        ))}

        {has_quota && (
          <Form.Group>
            <Form.Label>{intl.formatMessage({ id: 'QUOTA_VALUE' })}</Form.Label>
            <InputGroup>
              <FormControl
                type="number"
                {...register('share_subscription_amount', {
                  required: has_quota ? 'Quota value is required' : false,
                  min: { value: 0, message: 'Must be at least 0' },
                })}
                isInvalid={!!errors.share_subscription_amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.share_subscription_amount && errors.share_subscription_amount.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        )}

        <input type="hidden" {...register('shared_subscription_id')} value={subscription_id} />
        <Alert variant="info">{intl.formatMessage({ id: 'ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.SUBSCRIPTION_LIMIT' })}: {currentLimit}</Alert>

        <Button type="submit" disabled={formSubmitting}>{intl.formatMessage({ id: 'SUBMIT_BTN' })}</Button>
        <Button type="button" variant="secondary" onClick={() => reset()} disabled={formSubmitting}>
          {intl.formatMessage({ id: 'RESET_BTN' })}
        </Button>
      </Form>
    </div>
  );
};

export default AdminSubscriptionFormComponent;
