import React from 'react';

import { useAuth } from '../../../hooks/auth';
const AdminCreateSubscriptionRequest: React.FC = () => {
  // Add your component logic here

  return (
    <div>
      {/* Add your component UI here */}
    </div>
  );
};

export default AdminCreateSubscriptionRequest;