import { AxiosResponse } from 'axios';
import axios from '../../lib/axios';

class DataHandler {
    private baseUrl: string;

    constructor(baseUrl: string) {
        //use url from env REACT_APP_BACKEND_URL
        
        this.baseUrl = process.env.REACT_APP_BACKEND_URL || baseUrl;
    }

    async post<T>(url: string, data: any): Promise<AxiosResponse<T>> {
        const fullUrl = `${this.baseUrl}${url}`;
        try {
            const response = await axios.post<T>(fullUrl, data);
            return response;
        } catch (error: any) {
            throw new Error(`Failed to make POST request to ${fullUrl}: ${error.message}`);
        }
    }

    async get<T>(url: string): Promise<AxiosResponse<T>> {
        const fullUrl = `${this.baseUrl}${url}`;
        try {
            const response = await axios.get<T>(fullUrl);
            return response;
        } catch (error: any) {
            throw new Error(`Failed to make GET request to ${fullUrl}: ${error.message}`);
        }
    }

    async delete<T>(url: string): Promise<AxiosResponse<T>> {
        const fullUrl = `${this.baseUrl}${url}`;
        try {
            const response = await axios.delete<T>(fullUrl);
            return response;
        } catch (error: any) {
            throw new Error(`Failed to make DELETE request to ${fullUrl}: ${error.message}`);
        }
    }
}

export default DataHandler;
/**
 * how to use it
 * 
 * import DataHandler from './util/dataHandler';
 * 
 * - guide get request
 * 
 * const dataHandler = new DataHandler(baseUrl);
 * - perform request
 * const response = await dataHandler.get(url);
 */