import React, { useRef, useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import { ISubscriptionRequest, ISubscriptionsRequestList } from '../subscriptions/Subscription';
import { useIntl } from 'react-intl';

const SubscriptionRequestList: React.FC = () => {
  const intl = useIntl();
  const [subscriptionsRequestList, setSubscriptionsRequestList] = useState<ISubscriptionsRequestList | null>(null);
  const subscriptionRequestRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchSubscriptionRequests();
  }, []);

  const fetchSubscriptionRequests = async () => {
    try {
      const response = await axios.get<ISubscriptionsRequestList>('/api/subscription-requests');
      setSubscriptionsRequestList(response.data);
    } catch (error) {
      console.error(intl.formatMessage({ id: 'ALERT.FAILED_FETCH_SUBSCRIPTION_REQUEST' }), error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const subscriptionRequest = subscriptionRequestRef.current?.value;
    if (subscriptionRequest) {
      try {
        const response = await axios.post('/api/subscription-requests', { subscriptionRequest });
        if (response.status === 200) {
          fetchSubscriptionRequests();
          subscriptionRequestRef.current!.value = '';
        } else {
          console.error(intl.formatMessage({ id: 'ALERT.FAILED_CREATE_SUBSCRIPTION_REQUEST' }));
        }
      } catch (error) {
        console.error(intl.formatMessage({ id: 'ALERT.ERROR_CREATING_SUBSCRIPTION_REQUEST' }), error);
      }
    }
  };

  return (
    <div>
      <h1>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.TITLE' })}</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" ref={subscriptionRequestRef} placeholder={intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.INPUT_PLACEHOLDER' })} />
        <button type="submit">{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.SUBMIT' })}</button>
      </form>
      {subscriptionsRequestList ? (
        <table className="table">
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ID' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.USER_ID' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.NAME' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.EMAIL' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ORGANIZATION_NAME' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ORGANIZATION_PHONE' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ORGANIZATION_ADDRESS' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ORGANIZATION_WEBSITE' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.ORGANIZATION_NOTE' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.STATUS' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.CREATED_AT' })}</th>
              <th>{intl.formatMessage({ id: 'SUBSCRIPTION_REQUEST_LIST.UPDATED_AT' })}</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionsRequestList.subscriptions.map((subscriptionRequest: ISubscriptionRequest) => (
              <tr key={subscriptionRequest.id}>
                <td>{subscriptionRequest.id}</td>
                <td>{subscriptionRequest.userId}</td>
                <td>{subscriptionRequest.name}</td>
                <td>{subscriptionRequest.email}</td>
                <td>{subscriptionRequest.organization_name}</td>
                <td>{subscriptionRequest.organization_phone}</td>
                <td>{subscriptionRequest.organization_address}</td>
                <td>{subscriptionRequest.organization_website}</td>
                <td>{subscriptionRequest.organization_note}</td>
                <td>{subscriptionRequest.status}</td>
                <td>{subscriptionRequest.created_at}</td>
                <td>{subscriptionRequest.updated_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>{intl.formatMessage({ id: 'LOADING' })}</p>
      )}
    </div>
  );
};

export default SubscriptionRequestList;
