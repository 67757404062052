import React, { useCallback, useEffect, useState } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { ISubscriptionDataPageUser, History } from './Subscription';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useAuth } from '../../../hooks/auth';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { useIntl } from 'react-intl';
type LocationState = {
  companyName: string;
  companyId: string;
};

const UserViewCompanySubscription: React.FC = () => {
  const { showError, showWarning, showInfo } = useToast();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const { loading, setLoading, user, accountState, setSubscriptionAsDefault, setSubscriptionAsNotDefault } = useAuth('auth');
  const location = useLocation();
  const navigate = useNavigate();
  const [subscriptionsData, setSubscriptionsData] = useState<ISubscriptionDataPageUser[] | null>(null);
  const [historyData, setHistoryData] = useState<History[] | null>(null);
  const companyId = (location.state as LocationState)?.companyId;
  const companyName = (location.state as LocationState)?.companyName;

  useEffect(() => {
    if (!companyId || !companyName) {
      showWarning('Company name or ID is missing.');
      navigate('/');
    } else {
      fetchData();
    }
  }, [location, navigate, showWarning, companyId, companyName]);

  const refreshData = () => {
    setLoading(true);
    setPageLoading(true);
    fetchData();
    setLoading(false);
    setPageLoading(false);
  };

  const fetchHistory = useCallback(async (subscriptionId: number) => {
    const response = await axios.get(`/api/subscription/history/${subscriptionId}`);
    setHistoryData(response.data); // Extract the data from the Axios response
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post('/api/subscription/get-all-company-subscription', {
        company_id: companyId,
      });

      if (response.data.subscription !== null && response.data.subscription.length > 0) {
        setSubscriptionsData(response.data.subscription);
        fetchHistory(response.data.subscription[0].subscription_id);
      } else {
        setSubscriptionsData([]);
      }
    } catch (error) {
      showError('Failed to fetch subscription data.');
    }
  }, [companyId, showError, fetchHistory]);



  const companyPageBreadCrumb: Array<PageLink> = [
    {
      title: 'Company Subscription',
      path: '/subscription/company',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  const StatusWrapper = ({ statusSub }) => {
    const statusClass = {
      active: 'badge-success',
      pending: 'badge-warning',
      cancelled: 'badge-danger',
      unknown: 'badge-secondary',
    }[statusSub] || 'badge-secondary';
    return <span className={`badge ${statusClass}`}>{statusSub}</span>;
  };

  const StatusYesOrNo = ({ statusString }) => {
    return (
      <span className={`badge ${statusString ? 'badge-success' : 'badge-danger'}`}>
        {statusString ? 'Yes' : 'No'}
      </span>
    );
  };

  const TableHeaderSubscription = () => {
    return (
      <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
        <tr>
          <th>Id</th>
          <th>Linked To User </th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Belonged To Admin</th>
          <th>Shared</th>
          <th>Set As Default</th>

          <th>Usage</th>
        </tr>
      </thead>
    );
  };

  const TableBodySubscription = ({ subscriptions }: { subscriptions: ISubscriptionDataPageUser[] }) => {
    return (
      <tbody>
        {subscriptions.map((subscription, index) => (
          <tr key={index}>
            <td>{subscription.subscription_id}</td>
            <td>{subscription.user.name}</td>

            <td>{subscription.start_date}</td>
            <td>{subscription.end_date}</td>
            <td><StatusWrapper statusSub={subscription.status} /></td>
            <td><StatusYesOrNo statusString={subscription.is_subscription_admin} /></td>
            <td><StatusYesOrNo statusString={subscription.is_shared} /></td>
            <td><StatusYesOrNo statusString={subscription.is_default} /></td>

            <td>{subscription.usage}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  const TableHeaderHistory = () => {
    return (
      <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
        <tr>
          <th>ID</th>
          <th>Subscription ID</th>
          <th>Event Type</th>
          <th>Description</th>
          <th>Event Date</th>
          <th>Product IDs</th>
        </tr>
      </thead>
    );
  };

  const TableBodyHistory = ({ history }: { history: History[] }) => {
    return (
      <tbody>
        {history.map((event, index) => (
          <tr key={index}>
            <td>{event.id}</td>
            <td>{event.subscription_id}</td>
            <td>{event.event_type}</td>
            <td>{event.description}</td>
            <td>{event.event_date}</td>
            <td>{event.product_ids}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  if (loading === true || pageLoading === true) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ConfirmDialog />
      <PageTitle breadcrumbs={companyPageBreadCrumb}>
        Company Subscription For {companyName}
      </PageTitle>
      <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
        <div className="row w-100">
          <div className="col-md-12 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title">Company Subscription Details</h3>
                <button type="button" className="btn btn-primary btn-sm" onClick={refreshData}>
                  <i className="fa fa-refresh"></i> Refresh
                </button>
              </div>
              <div className="card-body">
                {loading && !subscriptionsData ? (
                  <p>Loading subscription data...</p>
                ) : subscriptionsData && subscriptionsData.length > 0 ? (
                  <table className="table table-striped table-bordered">
                    <TableHeaderSubscription />
                    <TableBodySubscription subscriptions={subscriptionsData} />
                  </table>
                ) : (
                  <p>No subscriptions data available.</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Subscription History</h3>
              </div>
              <div className="card-body">
                {historyData && historyData.length > 0 ? (
                  <table className="table table-striped table-bordered">
                    <TableHeaderHistory />
                    <TableBodyHistory history={historyData} />
                  </table>
                ) : (
                  <p>No history data available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserViewCompanySubscription;
