import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Quill from 'quill';

// Define the maximum length for the text (TEXT type in MySQL is 65,535 characters)
const MAX_TEXT_LENGTH = 65535;

interface EditorProps {
  readOnly: boolean;
  defaultValue?: any;
  onTextChange?: (delta: any, oldDelta: any, source: string) => void;
  onSelectionChange?: (range: any, oldRange: any, source: string) => void;
}

const Editor = forwardRef<Quill | null, EditorProps>(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const [textLength, setTextLength] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      if (ref && typeof ref === 'object' && ref.current) {
        ref.current.enable(!readOnly);
      }
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
      const quill = new Quill(editorContainer, { theme: 'snow' });

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        const text = quill.getText();
        setTextLength(text.length);

        if (text.length > MAX_TEXT_LENGTH) {
          quill.disable();
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
          onTextChangeRef.current?.(...args);
        }
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      if (ref && typeof ref === 'object') {
        ref.current = quill;
      }

      return () => {
        container.innerHTML = '';
        if (ref && typeof ref === 'object') {
          ref.current = null;
        }
      };
    }, [ref]);

    return (
      <div>
        <div ref={containerRef}></div>
        <div>
          Text length: {textLength} / {MAX_TEXT_LENGTH}
          {isDisabled && <span style={{ color: 'red' }}> - Maximum text length reached</span>}
        </div>
      </div>
    );
  },
);

Editor.displayName = 'Editor';

export default Editor;
