import React, { useEffect, useState, useCallback } from 'react';
import { Card, Col, Row, Spinner, Button } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { confirm } from '../../modules/confirmation/BasicConfirmation';
import { useIntl, FormattedMessage } from 'react-intl';

type LocationState = {
  product_id: string;
};

interface Price {
  amount: number;
  currency: string;
  active: boolean;
  is_default: boolean;
}

interface Feature {
  feature_name: string;
  feature_value: string;
  active: boolean;
}

interface ProductDetail {
  id: string;
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
  subscription_period: string;
  prices: Price[];
  features: Feature[];
  has_subscription: string;
}

const AdminViewProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl(); // React-Intl hook
  const product_id = (location.state as LocationState)?.product_id;

  const [product, setProduct] = useState<ProductDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchProductDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/products/show/${product_id}`);
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setLoading(false);
    }
  }, [product_id]);

  const handleDeleteProduct = async (product_id: string) => {
    const result = await confirm(
      intl.formatMessage({ id: 'VIEW_PRODUCT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PRODUCT?' })
    );
    if (!result) return;

    setLoading(true);
    try {
      await axios.post(`/api/products/delete-product`, { id: product_id });
      navigate('/product/admin-all-product');
    } catch (error) {
      console.error('Error deleting product:', error);
    } finally {
      setLoading(false);
    }
  };

  const navigateEdit = (product_id: string) => {
    navigate('/product/admin-edit-product', {
      state: { product_id },
    });
  };

  useEffect(() => {
    fetchProductDetails();
  }, [fetchProductDetails]);

  if (loading) return <Spinner animation="border" />;

  if (!product)
    return (
      <div>
        <FormattedMessage id="VIEW_PRODUCT_PRODUCT_NOT_FOUND" />
      </div>
    );

  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'VIEW_PRODUCT_PRODUCT LIST' }),
      path: '/product/admin-all-product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'VIEW_PRODUCT_PRODUCT_DETAILS' }) + ' ' + product.name,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>
        <FormattedMessage id="VIEW_PRODUCT_PRODUCT LIST" />
      </PageTitle>
      <div className="container">
        <KTCard>
          <div className="card-header">
            <span className="title">
              <FormattedMessage id="VIEW_PRODUCT_PRODUCT_DETAILS" />
            </span>
            <div className="card-toolbar">
              {product.has_subscription === 'No' && (
                <Button
                  onClick={() => handleDeleteProduct(product.id)}
                  className="btn btn-danger btn-sm"
                >
                  <i className="fa fa-trash"></i>
                  <FormattedMessage id="VIEW_PRODUCT_DELETE" />
                </Button>
              )}
              <Button onClick={() => navigateEdit(product.id)} className="btn btn-warning btn-sm">
                <i className="fa fa-pen"></i>
                <FormattedMessage id="VIEW_PRODUCT_EDIT" />
              </Button>
              <Button onClick={fetchProductDetails} className="btn btn-primary btn-sm">
                <i className="fa fa-refresh"></i>
                <FormattedMessage id="VIEW_PRODUCT_REFRESH" />
              </Button>
            </div>
          </div>
          <KTCardBody>
            <Row>
              <Col md={6}>
                <Card.Text>
                  <strong>
                    <FormattedMessage id="VIEW_PRODUCT_NAME:" />
                  </strong>{' '}
                  {product.name}
                </Card.Text>
                <Card.Text>
                  <strong>
                    <FormattedMessage id="VIEW_PRODUCT_TYPE:" />
                  </strong>{' '}
                  {product.type}
                </Card.Text>
                <Card.Text>
                  <strong>
                    <FormattedMessage id="VIEW_PRODUCT_ACTIVE:" />
                  </strong>{' '}
                  {product.active ? 'Yes' : 'No'}
                </Card.Text>
                <Card.Text>
                  <strong>
                    <FormattedMessage id="VIEW_PRODUCT_SUBSCRIPTION_SERVICE:" />
                  </strong>{' '}
                  {product.is_subscription ? 'Yes' : 'No'}
                </Card.Text>
                <Card.Text>
                  <strong>
                    <FormattedMessage id="VIEW_PRODUCT_SUBSCRIPTION_PERIOD:" />
                  </strong>{' '}
                  {product.subscription_period || 'N/A'}
                </Card.Text>
              </Col>
              <Col md={6}>
                <h4>
                  <FormattedMessage id="VIEW_PRODUCT_PRICES" />
                </h4>
                {product.prices.map((price, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_AMOUNT:" />
                        </strong>{' '}
                        {price.amount}
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_CURRENCY:" />
                        </strong>{' '}
                        {price.currency}
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_ACTIVE:" />
                        </strong>{' '}
                        {price.active ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_DEFAULT_PRICE:" />
                        </strong>{' '}
                        {price.is_default ? 'Yes' : 'No'}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
                <h4>
                  <FormattedMessage id="VIEW_PRODUCT_FEATURES" />
                </h4>
                {product.features.map((feature, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_NAME:" />
                        </strong>{' '}
                        {feature.feature_name}
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_VALUE:" />
                        </strong>{' '}
                        {feature.feature_value}
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          <FormattedMessage id="VIEW_PRODUCT_ACTIVE:" />
                        </strong>{' '}
                        {feature.active ? 'Yes' : 'No'}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </KTCardBody>
        </KTCard>
      </div>
    </>
  );
};

export default AdminViewProduct;
