import { useIntl } from 'react-intl';
import styled from 'styled-components';

const WatermarkContainer = styled.div`
  position: relative;
  width: 100%;
  
  // Improved text content wrapping
  h1, h2, p, td, span {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
    margin: 0; // Remove default margins
    padding: 0; // Remove default padding
  }
  
  // Enhanced table styling
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
  }
  
  td {
    vertical-align: top;
    padding: 2px 3px;
    line-height: 1.2;
    
    &:first-child {
      width: 35%;
    }
    
    &:last-child {
      width: 65%;
    }
  }

  // Remove extra spacing from table rows
  tr {
    margin: 0;
    padding: 0;
  }

  // Compact spacing for definition lists and similar content
  .d-flex {
    gap: 0.25rem;
  }

  // Ensure consistent text sizing
  .fs-6 {
    font-size: 0.9rem !important;
    line-height: 1.3;
  }
  
  @media print {
    &::before, &::after {
      position: fixed;
      left: 50%;
      transform-origin: center;
      color: #FF0000;
      opacity: 0.15;
      pointer-events: none;
      z-index: 1000;
      text-align: center;
      text-transform: uppercase;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      mix-blend-mode: multiply;
      text-shadow: 0px 0px 1px rgba(255, 0, 0, 0.3);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0.1em;
    }

    &::before {
      content: attr(data-watermark-main);
      top: calc(50% - 1.5rem);
      transform: translate(-50%, -50%) rotate(-45deg);
      font-size: 4rem;
    }

    &::after {
      content: attr(data-watermark-sub);
      top: calc(50% + 3.5rem);
      transform: translate(-50%, -50%) rotate(-45deg);
      font-size: 1.5rem;
    }
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledCompanyName = styled.h1`
  font-style: italic;
  word-break: break-word;
  white-space: pre-wrap;
  margin: 0.25rem 0;
  line-height: 1.2;
`;

const StyledTableCell = styled.td`
  word-break: break-word;
  white-space: pre-wrap;
  padding: 2px 3px;
  line-height: 1.2;
  vertical-align: top;

  // Handle long content
  > span, > strong {
    display: inline-block;
    max-width: 100%;
  }
`;

const StyledValue = styled.span`
  word-break: break-word;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  padding-left: 4px;
`;

export const Watermark = ({ children }: { children?: React.ReactNode }) => {
  const intl = useIntl();

  return (
    <WatermarkContainer
      data-watermark-main={intl.formatMessage({ id: 'WATERMARK.CONFIDENTIAL' })}
      data-watermark-sub={intl.formatMessage({ id: 'WATERMARK.INTERNAL_USE_ONLY' })}
    >
      <ContentContainer>
        {children}
      </ContentContainer>
    </WatermarkContainer>
  );
};

// Export styled components to be used in SmeModelResult
export { StyledCompanyName, StyledTableCell, StyledValue }; 