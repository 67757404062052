import React, { useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useIntl } from 'react-intl';

interface SpeedometerProps {
  mainTitle: string;
  usage: number;
  customSegmentStops: number[];
  segmentColors: string[];
  currentValueText?: string;
  textColor: string;

}



export const Speedometer: React.FC<SpeedometerProps> = (
  { mainTitle, usage, customSegmentStops, segmentColors, currentValueText, textColor }: SpeedometerProps
) => {

  // ... rest of the component code
  const intl = useIntl()
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="card card-flush h-xl-100">
        <div className='card-header pt-5'><h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-900" style={{ fontSize: '18px' }}>{mainTitle}&nbsp;<sup>1</sup></span>
          <span className="text-gray-500 mt-1 fw-semibold fs-6">{intl.formatMessage({ id: 'RESULT.CREDIT_SCORE_DESCRIPTION' })}</span>
        </h3>
        </div>
        <div className="card-body pt-6">
          <div style={{ margin: '0px' }}>
            <ReactSpeedometer
              forceRender={true}
              maxSegmentLabels={1}
              maxValue={100}
              value={usage}
              valueFormat={'d'}
              customSegmentStops={customSegmentStops}
              segmentColors={segmentColors}
              currentValueText={`${intl.formatMessage({ id: 'RESULT.YOUR_SCORE_IS' })} \r\n ${currentValueText} \r\n ${intl.formatMessage({ id: 'RESULT.OUT_OFF_100' })}`}
              textColor={textColor}
              minValue={0}
              width={300}
              height={300}
              needleTransitionDuration={0}

            />
          </div>
        </div>
      </div >
    </div >

  );
};