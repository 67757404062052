import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { processProductUsage } from '../../util/checkAccessUtil';
import { useIntl } from 'react-intl';

ChartJS.register(ArcElement, Tooltip, Legend);

const UsageBreakdownChartComponent = ({ productList, limit, unlimited }) => {
  const intl = useIntl()
  const processedData = processProductUsage(productList);

  if (!processedData || processedData.data.length === 0) {
    return (
      <div className="w-full max-w-md mx-auto text-center">
        <h2 className="text-xl font-bold mb-4">{'Your Product Usage Chart'}</h2>
        <p className="text-gray-600">No data provided</p>
      </div>
    );
  }

  const chartData = {
    labels: processedData.data.map(item => item.name),
    datasets: [
      {
        data: processedData.data.map(item => item.value),
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed;
            const percentage = processedData.data[context.dataIndex].percentage;
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },

  };
  const title = (unlimited === true) ? ` ${processedData.total_usage}` : ` ${processedData.total_usage} out of ${limit}`;


  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4 text-center">{intl.formatMessage({ id: 'DASHBOARD.YOUR_USAGE_BREAKDOWN' })}</h2>
      <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
        <Doughnut data={chartData} options={options} />
      </div>
      <p className="mt-4 text-center">{intl.formatMessage({ id: 'DASHBOARD.TOTAL_USAGE' }) + title}</p>
    </div>
  );
};

export default UsageBreakdownChartComponent;