import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from '../../../lib/axios';
import { useNavigate } from 'react-router-dom';
import { KTCard } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useToast } from '../../components/ToastProvider';
import { confirm } from '../../../app/modules/confirmation/BasicConfirmation';


type LocationState = {
  userId: string
}
interface UserDetail {
  id: string;
  user_id: string;
  role: string;
  user: {
    id: number;
    name: string;
    email: string;

  };
}

const AdminViewInternalUser: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = (location.state as LocationState)?.userId;

  const [userData, setUserData] = useState<UserDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    showSuccess,
    showWarning,
    showError
  } = useToast();


  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: 'Internal User List',
      path: '/user/admin-all-internal-user',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'User detail for ' + userData?.user.name,
      path: '',
      isSeparator: true,
      isActive: false,
    },

  ];


  const NAVIGATION_LIST_PAGE = `/user/admin-all-internal-user`;
  const URL_DELETE = `/api/internal-users/delete-record`;
  const reject = () => {
    showWarning('You have rejected');
  };


  const HandleDeleteUser = async (userId: string, userName: string) => {
    const result = await confirm(`Are you sure you want to delete the user ${userName}?`);
    if (result) {
      try {
        const response = await axios.post(URL_DELETE, { id: userId });
        if (response.status === 200) {
          showSuccess('You have deleted the user successfully');
          navigate(NAVIGATION_LIST_PAGE);
        } else {
          showError('Error deleting internal user');
        }
      } catch (error: any) {
        showError(`Error deleting data: ${error.message}`);
      }
    } else {
      reject();
    };
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`/api/internal-users/show/${userId}`);

        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!userData) {
    return <div>User not found</div>;
  }
  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{'User Detail'}</PageTitle>
      <div className="container">
        <KTCard className="card-custom">
          <Card.Header>
            <h3 className="card-title">User Information</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card.Text><strong>Name:</strong> {userData.user.name}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text><strong>Email:</strong> {userData.user.email}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card.Text><strong>Role:</strong> {userData.role}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Button variant="danger" onClick={() => HandleDeleteUser(userData.id, userData.user.name)}><i className="fa fa-trash"></i> Delete User</Button>
          </Card.Footer>
        </KTCard>
      </div>
    </>
  );
};

export default AdminViewInternalUser;
