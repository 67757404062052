import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from '../../../lib/axios';
import { useNavigate } from 'react-router-dom';
import { KTCard } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

type LocationState = {
  userId: string
}

interface UserDetail {
  id: string;
  name: string;
  email: string;
  company: string;
  role: string;
}

const AdminViewUser: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const userId = (location.state as LocationState)?.userId;

  const [user, setUser] = useState<UserDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);


  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'ADMIN_VIEW_USER_LIST' }),
      path: '/user/admin-all-user',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage(
        { id: 'ADMIN_VIEW_USER_DETAIL_FOR' },
        { name: user?.name }
      ),
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`/api/user/admin-view`, {
          id: userId
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error(intl.formatMessage({ id: 'ADMIN_USER_LIST_ERROR_LOAD' }), error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId, intl]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!user) {
    return <div>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_NOT_FOUND' })}</div>;
  }

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_PAGE_TITLE' })}</PageTitle>
      <div className="container">
        <KTCard className="card-custom">
          <Card.Header>
            <h3 className="card-title">{intl.formatMessage({ id: 'ADMIN_VIEW_USER_INFORMATION' })}</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card.Text><strong>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_NAME' })}</strong> {user.name}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text><strong>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_EMAIL' })}</strong> {user.email}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card.Text><strong>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_COMPANY' })}</strong> {user.company}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text><strong>{intl.formatMessage({ id: 'ADMIN_VIEW_USER_ROLE' })}</strong> {user.role}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </KTCard>
      </div>
    </>
  );
};

export default AdminViewUser;