import axios from '../../../../lib/axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'

interface TotalSubscriptionRequest {
  // Define the shape of your data here
  totalCount: number | null;
}

const BackOfficeSubscriptionRequestWidget: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/subscriptions/admin-subscription-request')
  }

  async function getTotalSubscriptionRequestData(): Promise<TotalSubscriptionRequest> {
    const url = '/api/subscription-requests/get-count';
    try {
      const response = await axios.get(url);
      const data = response.data; // Fix: Use response.data instead of response.json()
      // Assuming the API returns an object with a totalCount property
      return { totalCount: data.count };
    } catch (error) {
      console.error('Failed to fetch total subscription request count:', error);
      return { totalCount: null }; // Return null in case of an error
    }
  }

  useEffect(() => {
    getTotalSubscriptionRequestData().then(data => {
      setTotalCount(data.totalCount);
    });
  }, []); // Dependencies array is empty, so this runs only once on mount
  return (
    <div className={`card bg-light-warning card-xl-stretch mb-xl-8`}>
      {/* begin::Body */}
      <div className='card-body my-3'>
        <span className={`card-title fw-bold text-warning fs-5 mb-3 d-block`}>
          Subscription Requests
        </span>

        <div className='py-1'>
          <span className='text-dark fs-1 fw-bold me-2'> {totalCount !== null ? totalCount : 'Loading...'}</span>

          <span className='fw-semibold text-muted fs-7'>Request(s)</span>
        </div>
        {
          /** button link to view all subscriptions */

        }
        <button className="btn btn-info" onClick={() => handleClick()}>
          View All Subscriptions Request
        </button>
      </div>
      {/* end:: Body */}
    </div>

  );
};

export default BackOfficeSubscriptionRequestWidget;