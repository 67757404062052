
import React from 'react';
import { IUser } from '../DataDashboard';
import SubscriptionButtonHelper from '../../subscriptions/components/SubscriptionButtonHelper';
import ViewSubscriptionButton from '../../subscriptions/components/ViewSubscriptionButton';

// user_subscription prop is of type IUserSubscription

interface UserSubscriptionWidgetProps {
  user_subscription: any;
};
const UserSubscriptionWidget: React.FC<UserSubscriptionWidgetProps> = ({
  user_subscription
}) => {


  console.log('--- data user subscription UserSubscriptionWidget ---');

  console.log(user_subscription);




  if (user_subscription !== null && user_subscription?.message !== "User has no subscription") {

    const ToLink = `/subscriptions/user-subscription`;
    // Code to handle when user_subscription is not empty
    return (
      <>
        {
          JSON.stringify(user_subscription)
        }
        {
          <div className="card" >
            <div className="card-body">
              <h5 className="card-title">User Subscription </h5>
              <SubscriptionButtonHelper subscription_status={user_subscription?.user_subscription?.status} />
              <ViewSubscriptionButton subscription_id={user_subscription?.user_subscription?.id} />


            </div>
          </div>
        }
      </>
    );
  } else {
    // Code to handle when user_subscription is empty
    //return empty object 
    return (
      <>
        {
          JSON.stringify(user_subscription)
        }
        <div className="card" >
          <div className="card-body">
            <h5 className="card-title">User Subscription</h5>
            <p className="card-text">No Subscription available, What about subscribing ?</p>
            <SubscriptionButtonHelper subscription_status={null} />
          </div>
        </div>
      </>
    );
  }

};

export default UserSubscriptionWidget;
