import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { HeaderSwapMenu } from '../../../partials/layout/user-menu/HeaderSwapMenu';
import { useAuth } from '../../../../hooks/auth';
import { Product, ProductData, ProductList} from '../../../../app/shared_models/SharedModel'


export function AsideMenuMain() {
  const intl = useIntl();
  const { user, loading, companies } = useAuth('auth');

  const [localLoading, setLocalLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLocalLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

// function to find if product key is present in the product list
const isProductKeyPresent = (productKey: string): boolean => {
  
  if (!user || !user.product_list) {
    return false;
  }

  if (Array.isArray(user.product_list)) {
    return user.product_list.some((product) => product.product_key === productKey);
  }

  if (typeof user.product_list === 'object') {
    return Object.values(user.product_list).some((product) => {
      // Type guard to ensure we're dealing with a Product
      if (typeof product === 'object' && product !== null && 'product_key' in product) {
        return (product as Product).product_key === productKey;
      }
      return false;
    });
  }

  return false;

};

  const UserMenuAdmin = () => (
    <>
      <AsideMenuItem
        to='/user/admin-all-user'
        icon='ki-duotone ki-security-user'
        title={intl.formatMessage({ id: 'USER_ADMIN_ALL_USERS' })}
      />
      <AsideMenuItem
        to='/user/admin-all-internal-user'
        icon='ki-duotone ki-user'
        title={intl.formatMessage({ id: 'USER_ADMIN_ALL_INTERNAL_USERS' })}
      />
    </>
  );

  const ProductMenuAdmin = () => (
    <>
      <AsideMenuItem
        to='/product/admin-all-product'
        icon='ki-duotone ki-handcart'
        title={intl.formatMessage({ id: 'SIDEBAR.ALL_PRODUCTS' })}
      />
    </>
  );

  const SubscriptionMenuAdmin = () => (
    <>
      <AsideMenuItem
        to='/subscriptions/admin-subscription-request'
        icon='element-10'
        title={intl.formatMessage({ id: 'SUBSCRIPTION_ADMIN_VIEW_SUBSCRIPTION_REQUESTS' })}
      />
      <AsideMenuItem
        to='/subscriptions/admin-all-subscriptions'
        icon='element-02'
        title={intl.formatMessage({ id: 'SUBSCRIPTION_ADMIN_ALL_SUBSCRIPTIONS' })}
      />
    </>
  );

  const SubscriptionMenuUser = () => (
    <>
      <AsideMenuItem
        to='/subscriptions/user-subscription-request'
        title='Subscription Request Page'
        hasBullet={true}
      />
      <AsideMenuItem
        to='/subscriptions/subscribe'
        icon='element-09'
        title='Create Subscription Requests'
      />
    </>
  );

  const CompaniesMenuAdmin = () => (
    <div>
      <AsideMenuItem
        to='/companies/admin-all-companies'
        title={intl.formatMessage({ id: 'MENU.COMPANY_ADMIN_ALL_COMPANIES' })}
        hasBullet={true}
      />
    </div>
  );

  const CompaniesMenuUser = () => (
    <div>
      <AsideMenuItem
        to='/companies/user-all-companies'
        title='User List All Companies'
        hasBullet={true}
      />
    </div>
  );

  // if (loading || localLoading) {
  //   return (
  //     <button className='btn btn-primary btn-block' type='button' disabled>
  //       <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
  //       {intl.formatMessage({ id: 'EDIT_SUBSCRIPTION_REQUEST_LOADING' })}
  //     </button>
  //   );
  // }

  return (
    <>
      {(companies?.length > 0 || user?.role === 'Data Analyst') && (
        <HeaderSwapMenu UserId={user?.id} />
      )}

      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      {(isProductKeyPresent('sme-report-1723004786') || user?.role !== 'User Regular') && (
        <AsideMenuItem to='/sme-model-main'                icon='element-10' title={intl.formatMessage({ id: 'MENU.SME_MODEL' })} />
      )}
      {(isProductKeyPresent('sme-ccris-report-22112024') || user?.role !== 'User Regular') && (
        <AsideMenuItem to='/sme-model-ccris'                icon='element-10' title={intl.formatMessage({ id: 'MENU.SME_MODEL_CCRIS' })} />
      )}
      {isProductKeyPresent('corporate-model-report-11112024') && (
        <AsideMenuItem to='/corporate-model-main'     icon='element-10' title={intl.formatMessage({ id: 'MENU.CM' })} />
      )}
      {isProductKeyPresent('corporate-model-report-1723004859') && (
        <AsideMenuItem to='/corporate-model-bursa'    icon='element-10' title={intl.formatMessage({ id: 'MENU.CM_BURSA' })} />
      )}
      {isProductKeyPresent('corporate-model-report-marc-universe-1723878243') && (
        <AsideMenuItem to='/corporate-model-mu'       icon='element-10' title={intl.formatMessage({ id: 'MENU.CM_MARC_UNIVERSE' })} />
      )}
      {isProductKeyPresent('mid-tier-report-1723872854') && (
        <AsideMenuItem to='/corporate-model-mid-tier' icon='element-10' title={intl.formatMessage({ id: 'MENU.CM_BURSA_MID_TIER' })} />
      )}

      {user?.role === 'Super Admin' && (
        <AsideMenuItemWithSub to='/subscriptions' title={intl.formatMessage({ id: 'SUBSCRIPTION_SIDEBAR_MENU_LABEL' })}>
          <SubscriptionMenuAdmin />
        </AsideMenuItemWithSub>
      )}

      {user?.role === 'Super Admin' && (
        <AsideMenuItemWithSub to='/companies' title={intl.formatMessage({ id: 'COMPANY_SIDEBAR_MENU_LABEL' })}>
          <CompaniesMenuAdmin />
        </AsideMenuItemWithSub>
      )}

      {user?.role === 'Super Admin' && (
        <>
          <AsideMenuItemWithSub to='/user' title={intl.formatMessage({ id: 'USER' })}>
            <UserMenuAdmin />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/product' title={intl.formatMessage({ id: 'PRODUCT' })}>
            <ProductMenuAdmin />
          </AsideMenuItemWithSub>
        </>
      )}


      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  );
}
