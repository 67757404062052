import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import Slider from 'react-slick';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

type FormData = {
  name: string;
  description: string;
  start_date: Date;
  end_date: Date;
  duration: 'quarterly' | 'weekly' | 'monthly' | 'annually' | 'biannually' | 'manual';
  is_subscription_admin: boolean;
  is_shared: boolean;
  status: 'pending' | 'active' | 'cancelled' | 'deactivated' | 'suspended' | 'expired';
  is_company: boolean;
  is_personal: boolean;
  companyOrUserId: string;
  is_linked_to_user: boolean;
  linked_user: string;
  product_list: { value: number; label: string; }[];
  request_limit: number;
};

type User = {
  id: string;
  name: string;
  email: string;
};

type Company = {
  id: string;
  name: string;
};

type Product = {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
};

type Price = {
  id: number;
  product_id: number;
  amount: number;
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type Feature = {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

const slider_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true,
};

const AdminCreateSubscription: React.FC = () => {
  const navigate = useNavigate();
  const { register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      description: '',
      start_date: new Date(),
      end_date: new Date(),
      duration: 'annually',
      is_subscription_admin: false,
      is_shared: false,
      status: 'pending',
      is_company: false,
      is_personal: false,
      companyOrUserId: '',
      is_linked_to_user: false,
      linked_user: '',
      product_list: [],
      request_limit: 0,
    },
  });

  const intl = useIntl();

  const URL_MANUAL_ADD = '/api/subscription/manual-subscription';
  const PAGE_VIEW = '/subscriptions/admin-view-subscription';
  const { showError, showSuccess } = useToast();
  const [pageLoading, setPageLoading] = useState(false);
  const [isPersonal, setIsPersonal] = useState<boolean>(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [isLinkedToUser, setIsLinkedToUser] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const start_date = watch("start_date");
  const end_date = watch("end_date");
  const duration = watch("duration");

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await axios.get('/api/companies/get-company-list/');
      setCompanies(response.data.data);
    };

    const fetchProducts = async () => {
      const response = await axios.get('/api/products');
      setProducts(response.data);
    };

    const fetchUsers = async () => {
      setPageLoading(true);
      try {
        const response = await axios.get('/api/user/get-user-list');
        setUsers(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
        setUsers([]);
      } finally {
        setPageLoading(false);
      }
    };

    if (isCompany) {
      fetchCompanies();
      fetchUsers();
    } else if (isPersonal) {
      fetchUsers();
    }

    fetchProducts();
  }, [isCompany, isPersonal]);

  const handleToggleCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCompany(e.target.checked);
    setIsPersonal(!e.target.checked);
  };

  const handleTogglePersonal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPersonal(e.target.checked);
    setIsCompany(!e.target.checked);
  };

  const handleToggleLinkedToUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLinkedToUser(e.target.checked);
    if (e.target.checked) {
      setIsCompany(true);
      setValue('is_company', true);
    } else {
      setValue('linked_user', '');
    }
  };

  const handleSelectProduct = (product: Product) => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    const updatedSelectedProducts = [...selectedProducts];
    if (index >= 0) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
    setValue('product_list', updatedSelectedProducts.map(p => ({ value: p.id, label: p.name })));
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setPageLoading(true);
    try {
      const response = await axios.post(URL_MANUAL_ADD, data);
      if (response.status === 201) {
        showSuccess(`Subscription created successfully! ${response.data.message}`);
        navigate(PAGE_VIEW, { state: { subscriptionId: response.data.subscription.id } });
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to create subscription:", error);
      showError("Failed to create subscription");
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (start_date && duration !== 'manual') {
      const start = new Date(start_date);
      switch (duration) {
        case 'quarterly':
          start.setMonth(start.getMonth() + 3);
          break;
        case 'weekly':
          start.setDate(start.getDate() + 7);
          break;
        case 'monthly':
          start.setMonth(start.getMonth() + 1);
          break;
        case 'annually':
          start.setFullYear(start.getFullYear() + 1);
          break;
        case 'biannually':
          start.setFullYear(start.getFullYear() + 2);
          break;
        default:
          break;
      }
      setValue('end_date', start);
    } else if (start_date && end_date && duration === 'manual') {
      setValue('end_date', end_date);
    }
  }, [start_date, end_date, duration, setValue]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setValue('start_date', date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setValue('end_date', date);
    }
  };

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className='card mb-2'>
        <div className="container my-5">
          <div className="py-10">
            <h1 className="anchor fw-bolder mb-5">{intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.CREATE_SUBSCRIPTION' })}</h1>
            <Slider {...slider_settings}>
              {products.map(product => (
                <div key={product.id} className="card card-custom gutter-b">
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">{product.name}</h3>
                    </div>
                    <div className="card-toolbar">
                      <button
                        type="button"
                        className="btn btn-sm btn-info font-weight-bold"
                        onClick={() => handleSelectProduct(product)}
                      >
                        <i className="fa-solid fa-bolt"></i>
                        {intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.SELECT_PRODUCT' })}
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    Price: {product.price} {product.price?.[0]?.currency || 'MYR'}
                    <table>
                      <thead>
                        <tr>
                          <th>{intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.NAME' })}</th>
                          <th>{intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.FEATURES_INCLUDED' })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.features?.map((feature: any) => (
                          <tr key={feature.id}>
                            <td>{feature.feature_name}</td>
                            <td>
                              {feature.feature_value === 'Yes' ? (
                                <span style={{ color: 'green' }}>
                                  <i className="fa-solid fa-circle-check"></i>
                                </span>
                              ) : (
                                <span style={{ color: 'red' }}>
                                  <i className="fa-solid fa-circle-xmark"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </Slider>
            <Form onSubmit={handleSubmit(onSubmit)} className="card p-4">
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Company"
                  {...register('is_company')}
                  onChange={(e) => handleToggleCompany(e)}
                  isInvalid={!!errors.is_company}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.is_company && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Personal"
                  {...register('is_personal')}
                  onChange={(e) => handleTogglePersonal(e)}
                  isInvalid={!!errors.is_personal}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.is_personal && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Linked User"
                  {...register('is_linked_to_user')}
                  onChange={(e) => handleToggleLinkedToUser(e)}
                  isInvalid={!!errors.is_linked_to_user}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.is_linked_to_user && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              {isCompany && (
                <Form.Group className="mb-3">
                  <Form.Label>

                    {intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.SELECT_COMPANY' })}
                  </Form.Label>
                  <Form.Select {...register('companyOrUserId', { required: isCompany })} isInvalid={!!errors.companyOrUserId}>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.companyOrUserId && 'Please select a company'}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {isPersonal && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    {intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.SELECT_COMPANY' })}
                  </Form.Label>
                  <Form.Select {...register('companyOrUserId', {
                    required: isPersonal
                  })} isInvalid={!!errors.companyOrUserId}>
                    {users.map(user => (
                      <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.companyOrUserId && 'Please select a user'}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {isLinkedToUser && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    {intl.formatMessage({ id: 'SELECT_LINKED_USER' })}
                  </Form.Label>
                  <Form.Select {...register('linked_user', { required: isCompany })} isInvalid={!!errors.linked_user}>
                    {users.map(user => (
                      <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.linked_user && 'Please select a linked user'}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'DESCRIPTION' })}
                </Form.Label>
                <Form.Control as="textarea" {...register('description', { required: true })}
                  isInvalid={!!errors.description} />
                <Form.Control.Feedback type="invalid">
                  {errors.description && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'START_DATE' })}
                </Form.Label>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => handleStartDateChange(date)}
                      className="form-control"
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.start_date && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'DURATION' })}
                </Form.Label>
                <Form.Select {...register('duration',
                  { required: true })} isInvalid={!!errors.duration}>
                  <option value="quarterly">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.quarterly' })}</option>
                  <option value="weekly">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.weekly' })}</option>
                  <option value="monthly">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.monthly' })}</option>
                  <option value="annually">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.annually' })}</option>
                  <option value="biannually">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.biannually' })}</option>
                  <option value="manual">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.TABLE.manual' })}</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.duration && 'Duration is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'END_DATE' })}
                </Form.Label>
                <Controller
                  control={control}
                  name="end_date"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => handleEndDateChange(date)}
                      className="form-control"
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.end_date && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'STATUS' })}
                </Form.Label>
                <Form.Select {...register('status', { required: true })} isInvalid={!!errors.status}>
                  <option value="pending">{
                    intl.formatMessage({
                      id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.pending'
                    })}</option>
                  <option value="active">
                    {
                      intl.formatMessage({
                        id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.active'
                      })
                    }
                  </option>
                  <option value="cancelled">
                    {
                      intl.formatMessage({
                        id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.cancelled'
                      })
                    }
                  </option>

                  <option value="deactivated">
                    {
                      intl.formatMessage({
                        id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.deactivated'
                      })
                    }
                  </option>
                  <option value="suspended">
                    {
                      intl.formatMessage({
                        id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.suspended'
                      })
                    }
                  </option>
                  <option value="expired">
                    {
                      intl.formatMessage({
                        id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.expired'
                      })
                    }
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.status && 'Please select a status'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Admin Subscription"
                  {...register('is_subscription_admin')}
                  isInvalid={!!errors.is_subscription_admin}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.is_subscription_admin && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Shared"
                  {...register('is_shared')}
                  isInvalid={!!errors.is_shared}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.is_shared && 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'PRODUCT_LIST' })}
                </Form.Label>
                <Controller
                  name="product_list"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={products.map(product => ({ value: product.id, label: product.name }))}
                      isMulti
                      isSearchable
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.product_list && 'Please select a product'}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.REQUEST_LIMIT_LABEL' })}
                </Form.Label>
                <Form.Control
                  type="number"
                  {...register('request_limit', {
                    valueAsNumber: true,
                    min: 0,
                    required: true,
                  })}
                  isInvalid={!!errors.request_limit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.request_limit && 'Please insert a limit, must be non-zero'}
                </Form.Control.Feedback>
              </Form.Group>
              <div className='btn-group'>
                <Button variant="primary" type="submit">
                  {pageLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
                <Button variant="secondary" type="button" onClick={() => reset()}>
                  {intl.formatMessage({ id: 'RESET_BTN' })}

                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreateSubscription;
