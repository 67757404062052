import React, { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button, Form, Table } from 'react-bootstrap';
import { showError, showInfo, showSuccess, showWarn } from '../../../primereact/alertsUtil';
import {useNavigate} from 'react-router-dom'



interface SubscriptionRequest {
    request_id: number;
    user_id: number | null;
    name: string;
    phone: string;
    email: string;
    organization_name: string | null;
    organization_phone: string | null;
    organization_address: string | null;
    organization_website: string | null;
    organization_note: string;
    status: string;
    created_at: string;
    updated_at: string;
}


// load the subscription requests from the mock data
const subscriptionRequests: SubscriptionRequest[] = require('../../../../src/mocks/subscription_requests.json');
const Subscriptions: React.FC = () => {
  let navigate = useNavigate()

  const toastRef = useRef<Toast>(null);

  const handleApprove = (request_id: number): void => {
    showSuccess(toastRef,'Success approve ' + request_id);
  }

  const handleReject = (request_id: number): void => {
    showSuccess(toastRef,'Success reject' + request_id);
  }

  const handleView = (request_id: number): void => {
      navigate(`/subscriptions/subscription-request-view`, { state: { request_id } })

      
  };

  return (
    <>
            <Toast ref={toastRef} />

    <div className="row g-5 g-xl-8">
            <div className='class="card card-xl-stretch mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title fw-bolder'>Subscription Requests</h3>
                </div>
                <div className='card-body py-3'>
            <div className='table-responsive'>
            <Table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                    <tr>
                        <th>Request ID</th>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Organization Name</th>
                        <th>Organization Phone</th>
                        <th>Organization Address</th>
                        <th>Organization Website</th>
                        <th>Organization Note</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptionRequests.map((request) => (
                        <tr key={request.request_id}>
                            <td>{request.request_id}</td>
                            <td>{request.user_id}</td>
                            <td>{request.name}</td>
                            <td>{request.phone}</td>
                            <td>{request.email}</td>
                            <td>{request.organization_name}</td>
                            <td>{request.organization_phone}</td>
                            <td>{request.organization_address}</td>
                            <td>{request.organization_website}</td>
                            <td>{request.organization_note}</td>
                            <td>{request.status}</td>
                            <td>{request.created_at}</td>
                            <td>{request.updated_at}</td>
                        <td>
                          <div className='btn btn-group'>
                                <Button variant="success" onClick={() => handleApprove(request.request_id)}>
                                    Approve
                                </Button>
                                <Button variant="danger" onClick={() => handleReject(request.request_id)}>
                                    Reject
                            </Button>
                            <Button variant="warning" onClick={() => handleView(request.request_id)}>
                                    View
                            </Button>
                            </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
            </div>
            </div>
      </div>
      </>
    );
};

export default Subscriptions;