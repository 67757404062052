import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { useToast } from '../../components/ToastProvider';
import DataHandler from '../../util/dataHandler';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import { useAuth } from '../../../hooks/auth';
import PeriodDropDown from './components/PeriodDropDown';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from '../../../lib/axios';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true,
};

interface Product {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
}

interface Price {
  id: number;
  product_id: number;
  amount: number;
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface Feature {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const Subscribe: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { showInfo, showSuccess, showError } = useToast();
  const { user } = useAuth('auth');
  const URL_POST = '/api/subscription-requests/store';

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataHandler = new DataHandler(process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000');
        const response = await dataHandler.get<Product[]>('/api/products');
        setProducts(response.data);
        showSuccess(intl.formatMessage({ id: 'TOAST.DASHBOARD_FETCH_SUCCESS' }));
      } catch (error: any) {
        console.error('Error fetching data:', error);
        showError(`${intl.formatMessage({ id: 'ALERT.FAILED_FETCH_SUBSCRIPTION' })} ${error.message}`);
      }
    };

    fetchData();
  }, [showSuccess, showError, intl]);

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
      setValue('email', user.email);
    }
  }, [user, setValue]);

  const productOptions = useMemo(() => products.map(product => ({
    value: product.id,
    label: product.name,
  })), [products]);

  const handleSelectProduct = (product: Product) => {
    setSelectedProducts((prevSelected) => {
      const isAlreadySelected = prevSelected.some(p => p.id === product.id);
      return isAlreadySelected
        ? prevSelected.filter(p => p.id !== product.id)
        : [...prevSelected, product];
    });
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const response = await axios.post(URL_POST, data);
      if (response.status === 201) {
        showSuccess(intl.formatMessage({ id: 'ALERT.SUCCESS_CREATE_SUBSCRIPTION_REQUEST' }));
        setValue('name', '');
        setValue('email', '');
        setValue('telephone', '');
        setValue('organization_name', '');
        setValue('organization_phone', '');
        setValue('organization_email', '');
        setValue('organization_address', '');
        setValue('organization_website', '');
        setSelectedProducts([]);
        setValue('product_list', []);
        setValue('subscription_period', '');

        navigate('/subscriptions/user-subscription-request');
      } else {
        showError(intl.formatMessage({ id: 'ALERT.FAILED_CREATE_SUBSCRIPTION_REQUEST' }));
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.isAxiosError && error.response) {
        showError(`${intl.formatMessage({ id: 'ALERT.FAILED_CREATE_SUBSCRIPTION_REQUEST' })}: ${error.response.status} ${error.response.statusText}`);
      } else {
        showError(intl.formatMessage({ id: 'ALERT.FAILED_CREATE_SUBSCRIPTION_REQUEST' }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container py-4">
        <h1>{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBSCRIPTIONS' })}</h1>

        {/* Slider component for product selection */}
        <Slider {...sliderSettings}>
          {products.map(product => (
            <div key={product.id} className="card card-custom gutter-b">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">{product.name}</h3>
                </div>
                <div className="card-toolbar">
                  <button
                    type="button"
                    className="btn btn-sm btn-info font-weight-bold"
                    onClick={() => handleSelectProduct(product)}
                  >
                    {selectedProducts.some(p => p.id === product.id)
                      ? intl.formatMessage({ id: 'GENERAL.UNSELECT' })
                      : intl.formatMessage({ id: 'GENERAL.SELECT' })}
                  </button>
                </div>
              </div>
              <div className="card-body">
                {intl.formatMessage({ id: 'GENERAL.PRICE' })}: {product.price} {product.prices?.[0]?.currency || 'MYR'}
                <table>
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: 'GENERAL.FEATURES_INCLUDED' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.features?.map((feature: Feature) => (
                      <tr key={feature.id}>
                        <td>{feature.feature_name}</td>
                        <td>
                          {feature.feature_value === 'Yes' ? (
                            <span style={{ color: 'green' }}>
                              <i className="fa-solid fa-circle-check"></i>
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              <i className="fa-solid fa-circle-xmark"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </Slider>

        {/* Form for subscription request */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>{intl.formatMessage({ id: 'PRODUCT_LIST' })}</Form.Label>
            <Controller
              name="product_list"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={productOptions}
                  isMulti
                />
              )}
            />
            {errors.product_list && <div className="invalid-feedback">{intl.formatMessage({ id: 'FORM_FEEDBACK.PRODUCT_LIST_REQUIRED' })}</div>}
          </Form.Group>

          <PeriodDropDown register={register} />

          {/* Other form fields */}
          <Form.Group className="mb-3">
            <Form.Label>{intl.formatMessage({ id: 'GENERAL.NAME' })}</Form.Label>
            <Form.Control
              type="text"
              {...register('name', { required: true })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {intl.formatMessage({ id: 'FORM_FEEDBACK.NAME_REQUIRED' })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{intl.formatMessage({ id: 'GENERAL.EMAIL' })}</Form.Label>
            <Form.Control
              type="email"
              {...register('email', {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {intl.formatMessage({ id: 'FORM_FEEDBACK.EMAIL_INVALID' })}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Rest of the form fields */}
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label={intl.formatMessage({ id: 'GENERAL.AGREE_TO_TERMS' })}
              {...register('agreeToTerms', { required: true })}
              isInvalid={!!errors.agreeToTerms}
              feedback={intl.formatMessage({ id: 'FORM_FEEDBACK.AGREE_TO_TERMS_REQUIRED' })}
              feedbackType="invalid"
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'SUBMIT_BTN' })}
          </Button>
          <Button variant="secondary" type="reset">
            {intl.formatMessage({ id: 'RESET_BTN' })}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Subscribe;
