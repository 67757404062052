import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ViewSubscriptionButton: React.FC<{ subscription_id: string }> = ({ subscription_id }) => {

  const navigate = useNavigate()
  const handleSubscriptionViewClick = (subscription_id: string) => {
    console.log('subscription_id:' + subscription_id)
    navigate(`/subscriptions/user-subscription`, { state: { subscription_id } })
  }



  return (
    <Button variant="primary" onClick={() => handleSubscriptionViewClick(subscription_id)}>View Subscription</Button>
  );
};

export default ViewSubscriptionButton;