import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';

type LocationState = {
  subscription_id: string;
};

export const AdminSetSubscriptionLimit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showError, showSuccess } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const subscription_id = (location.state as LocationState)?.subscription_id;
  const [valueLimit, setValueLimit] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the input
    if (!valueLimit) {
      showError('Please enter a valid limit');
      return;
    }

    if (!subscription_id) {
      showError('Subscription ID is missing');
      return;
    }

    try {
      setLoading(true);

      // Make the request to update the subscription limit
      await axios.post('/api/subscription/set-usage-limit-for-plan', {
        limit: valueLimit,
        subscription_id: subscription_id,
      });

      // Success feedback and navigate back
      showSuccess('Subscription limit updated successfully');
      navigate(-1); // Go back to the previous page
    } catch (error) {
      showError('Error updating subscription limit');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label>Limit:</label>
          <input
            title="limit"
            type="number"
            className="form-control"
            value={valueLimit}
            onChange={(event) => setValueLimit(event.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {loading ? <LoadingSpinner /> : 'Update Limit'}
        </button>
      </form>
    </div>
  );
};
