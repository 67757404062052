import React from 'react'
import {Chart} from 'chart.js'
import {Line} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAnnotation from 'chartjs-plugin-annotation'

Chart.register(ChartAnnotation)

function LineChart({companyName, grade, chartTitle, ratioName, data}) {
  const labels = ['CM-1', 'CM-2', 'CM-3', 'CM-4', 'CM-5', 'CM-6', 'CM-7', 'CM-8', 'CM-9', 'CM-10']
  let yAxisLabel = '(x)'
  if (
    ratioName === 'operating_margin' ||
    ratioName === 'ebitda_margin' ||
    ratioName === 'cfo_growth'
  ) {
    // using object keys to get the data for each grade
    Object.keys(data).map((key, index) => (data[key] = data[key] * 100))
    yAxisLabel = '%'
  }

  const chartingData = {
    labels,
    datasets: [
      {
        label: '',
        data: data,
        borderColor: 'rgb(54, 72, 107, 1)',
        backgroundColor: 'rgb(54, 72, 107, 1)',
        lineTension: 0.2,
        pointRadius: 0, // Add this line to remove default points
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
        align: 'start' as const,
      },
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 14,
          weight: 'bold' as const,
        },
      },
      decimation: {
        enabled: true,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          point1: {
            type: 'polygon' as const,
            sides: 4,
            rotation: 45,
            xValue: grade, // Use the last label in your x-axis
            yValue: data[grade], // Use the corresponding y value
            radius: 10,
            backgroundColor: 'rgb(192, 0, 0)',
          },
          label1: {
            type: 'label' as const,
            content: ['[' + grade + '] ' + companyName],
            font: {
              size: 14,
            },
            color: 'black' as const,
            position: {
              x: 'start',
              y: 'start',
            } as const,
            xValue: 'CM-1', // Set this to the first x-axis label
            yValue: (ctx) => ctx.chart.scales.y.max, // Use the maximum y-axis value
            xAdjust: 20,
            yAdjust: 0,
          },
          label2: {
            type: 'polygon' as const,
            sides: 4,
            rotation: 45,
            radius: 10,
            backgroundColor: 'rgb(192, 0, 0)',
            position: {
              x: 'start',
              y: 'start',
            } as const,
            xValue: 'CM-1', // Set this to the first x-axis label
            yValue: (ctx) => ctx.chart.scales.y.max, // Use the maximum y-axis value
            xAdjust: 15,
            yAdjust: 14,
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Grade',
          color: 'black',
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          color: 'black',
        },
        suggestedMax: (context) => {
          const dataValues = Object.values(context.chart.data.datasets[0].data);
          const numericValues = dataValues.filter((value): value is number => typeof value === 'number');
          if (numericValues.length === 0) return 1; // Default max if no numeric values
          const max = Math.max(...numericValues);
          return max >= 0 ? Math.ceil(max) * 2 : max * 0.9; // Increase positive max by 10%, decrease negative max by 10%
        },
        suggestedMin: (context) => {
          const dataValues = Object.values(context.chart.data.datasets[0].data);
          const numericValues = dataValues.filter((value): value is number => typeof value === 'number');
          if (numericValues.length === 0) return 0; // Default min if no numeric values
          const min = Math.min(...numericValues);
          return min >= 0 ? (min > 0 ? min * 0.9 : 0) : min * 1.1; // Decrease positive min by 10%, increase negative min by 10%
        },
        ticks: {
          callback: (value) => {
            if (yAxisLabel === '%') {
              return value + '%';
            }
            return value;
          }
        }
      },
    },
  }
  const backgroundColorPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart) => {
      const ctx = chart.ctx
      ctx.fillStyle = 'rgba(255, 255, 255, 255)'
      ctx.fillRect(0, 0, chart.width, chart.height)
    },
  }
  return (
    <>
      <Line
        options={options}
        plugins={[backgroundColorPlugin, ChartDataLabels]}
        data={chartingData}
      />
    </>
  )
}

export default LineChart
