import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';


//-----------------------------//
  // function for toast messages //
  //-----------------------------//


  export const showSuccess = (toastRef: React.RefObject<Toast>, message: string) => {
    (toastRef.current as Toast | null)?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  export  const showInfo= (toastRef: React.RefObject<Toast>, message: string) => {
    (toastRef.current as Toast | null)?.show({
      severity: "info",
      summary: "Info",
      detail: message,
      life: 3000,
    });
  };

  export const showWarn = (toastRef: React.RefObject<Toast>, message: string) => {
    (toastRef.current as Toast | null)?.show({
      severity: "warn",
      summary: "Warning",
      detail: message,
      life: 3000,
    });
  };

  export const showError = (toastRef: React.RefObject<Toast>, message: string) => {
    (toastRef.current as Toast | null)?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
};