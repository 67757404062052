import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {TOption} from '../../model'
import {LineInput} from './components/LineInput'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {alert} from '../../../../modules/confirmation/BasicAlert'
import {confirm} from '../../../../modules/confirmation/BasicConfirmation'
import FormAFS from './components/FormAFS'
import ErrorAlert from '../../../smemodel/components/ErrorAlert'
import {useIntl} from 'react-intl'

function Step2({
  corporate_model_no,
  companyInformation,
  companyAnalysis,
  ComputeForm,
  CompanyList,
  handleChange,
  handleYearlyDataChange,
  handleConfirm,
  handleSubmit,
  setPageState,
  PageState,
  handleRefresh,
  handleDefaultAFS,
}) {
  const intl = useIntl()
  const [showAFSModal, setShowAFSModal] = useState(false)
  const handleCloseAFSModal = async () => {
    if (
      await confirm(
        intl.formatMessage({id: 'CM.ARE_YOU_SURE'}),
        intl.formatMessage({id: 'CM.YES_PROCEED'}),
        intl.formatMessage({id: 'CM.CANCEL'}),
        {title: intl.formatMessage({id: 'CM.Alert'})}
      )
    ) {
      setShowAFSModal(false)
      handleRefresh()
    }
  }
  const handleShowAFSModal = () => setShowAFSModal(true)

  const [showRatioModal, setShowRatioModal] = useState(false)
  const handleCloseRatioModal = async () => {
    if (
      await confirm(
        intl.formatMessage({id: 'CM.ARE_YOU_SURE'}),
        intl.formatMessage({id: 'CM.YES_PROCEED'}),
        intl.formatMessage({id: 'CM.CANCEL'}),
        {title: intl.formatMessage({id: 'CM.Alert'})}
      )
    ) {
      setShowRatioModal(false)
      handleRefresh()
    }
  }
  const handleShowRatioModal = () => setShowRatioModal(true)

  const handleSelect = (item: TOption | unknown) => {
    // Cast the item to TOption type before accessing its properties
    const value = item as TOption
    console.log('handleSelect ::' + value.label)
    

    let label: any = ''
    const company_id = value.value
    for (let key in CompanyList) {
      if (CompanyList[key].id === company_id) {
        label = CompanyList[key].company_name
      }
    }
    const sectorList: string[] = []
    const sector = findSectorByCompanyName(label)
    if (sector) {
      sectorList.push(sector)
    }
    
    handleChange(sectorList, 'companyAnalysis', 'peer_sector')
    handleChange(value.value, 'companyInformation', 'company_id')
    handleChange(label, 'companyInformation', 'company_name')
  }

  const findSectorByCompanyName = (companyName: string) => {
    for (let key in CompanyList) {
      if (CompanyList[key].company_name === companyName) {
        return CompanyList[key].sector;
      }
    }
    return null;
  }

  const handleChangeSubjectFinancialInformationType = (v, p1, p2) => {
    handleChange(v, p1, p2)
    if (v === 'afs') {
      handleShowAFSModal()
      if (ComputeForm.companyFinancialAnalysis.length === 0) {
        handleDefaultAFS()
      }
    }
    if (v === 'ratio') {
      handleShowRatioModal()
    }
  }
  const getSelectedCompany = () => {
    const defaultOption: TOption = {value: '', label: intl.formatMessage({id: 'CM.PLEASE_SELECT'})}

    if (companyInformation.company_name === null || companyInformation.company_name === '') {
      return defaultOption
    }
    if (companyInformation.company_id === null || companyInformation.company_id === '') {
      return defaultOption
    }

    const selectedCompany: any = []
    const company_id = companyInformation.company_id
    for (let key in CompanyList) {
      if (CompanyList[key].id === company_id) {
        selectedCompany.push(CompanyList[key])
      }
    }
    return selectedCompany.map((item) => ({
      value: item.id,
      label: `${item.company_name} (${item.sector})`,
    }))
  }

  return (
    <>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <input
              type='radio'
              className='btn-check'
              name='subject_source_type'
              value='internal'
              id='subject_source_type_internal'
              checked={companyInformation.subject_source_type === 'internal'}
              onChange={(e) => {
                handleChange(e.currentTarget.value, 'companyInformation', 'subject_source_type')
              }}
            />
            <label
              className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
              htmlFor='subject_source_type_internal'
            >
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4'>
                  {companyInformation.data_source === 'mu'
                    ? intl.formatMessage({id: 'CM.RATED_COMPANY'})
                    : intl.formatMessage({id: 'CM.LISTED_COMPANY'})}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <input
              type='radio'
              className='btn-check btn-success'
              name='subject_source_type'
              value='external'
              id='subject_source_type_external'
              checked={companyInformation.subject_source_type === 'external'}
              onChange={(e) => {
                handleChange(e.currentTarget.value, 'companyInformation', 'subject_source_type')
              }}
            />
            <label
              className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
              htmlFor='subject_source_type_external'
            >
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4'>
                  {companyInformation.data_source === 'mu'
                    ? intl.formatMessage({ id: 'CM.UNRATED_COMPANY' })
                    : intl.formatMessage({ id: 'CM.UNLISTED_COMPANY' })}
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'></div>
        </div>
      </div>
      <div
        className={clsx('w-100 pb-10 pb-lg-15', {
          'visually-hidden': companyInformation.subject_source_type !== 'internal',
        })}
      >
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({ id: 'CM.PLEASE_SELECT_COMPANY' })}:
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
            {companyInformation.data_source === 'mu'
              ? intl.formatMessage({ id: 'CM.BURSA_DESC' })
              : intl.formatMessage({ id: 'CM.MU_DESC' })}
          </div>
        </div>

        <div className='fv-row'>
          <Select
            className='react-select-styled react-select-solid'
            classNames={{
              control: () => 'border-default border',
            }}
            classNamePrefix='react-select'
            defaultValue={getSelectedCompany()}
            options={CompanyList.map((item) => ({
              value: item.id,
              label: `${item.company_name} (${intl.formatMessage({ id: 'CM.' + item.sector})})`,
            }))}
            onChange={(option) => handleSelect(option)}
          />
        </div>
      </div>
      <div
        className={clsx('w-100', {
          'visually-hidden': companyInformation.subject_source_type !== 'external',
        })}
      >
        <div className='pb-10 pb-lg-5'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            {intl.formatMessage({ id: 'CM.PLEASE_ENTER_COMPANY_FINANCIAL_INFORMATION' })}
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
            {intl.formatMessage({ id: 'CM.YOUR_LATEST_FINANCIAL_INFORMATION' })}
          </div>
        </div>

        <div className='fv-row'>
          <div className='row'>
            <div className='col-lg-6'>
              <input
                type='radio'
                className='btn-check'
                name='subject_financial_information_type'
                value='ratio'
                id='subject_financial_information_type_ratio'
                checked={companyInformation.subject_financial_information_type === 'ratio'}
                onChange={(e) => {
                  handleChangeSubjectFinancialInformationType(
                    e.currentTarget.value,
                    'companyInformation',
                    'subject_financial_information_type'
                  )
                }}
                onClick={(e) => {
                  handleChangeSubjectFinancialInformationType(
                    e.currentTarget.value,
                    'companyInformation',
                    'subject_financial_information_type'
                  )
                }}
              />
              <label
                className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                htmlFor='subject_financial_information_type_ratio'
              >
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4'>{intl.formatMessage({ id: 'CM.RATIO' })}</span>
                </span>
              </label>
            </div>

            <div className='col-lg-6'>
              <input
                type='radio'
                className='btn-check btn-success'
                name='subject_financial_information_type'
                value='afs'
                id='subject_financial_information_type_afs'
                checked={companyInformation.subject_financial_information_type === 'afs'}
                onChange={(e) => {
                  handleChangeSubjectFinancialInformationType(
                    e.currentTarget.value,
                    'companyInformation',
                    'subject_financial_information_type'
                  )
                }}
                onClick={(e) => {
                  handleChangeSubjectFinancialInformationType(
                    e.currentTarget.value,
                    'companyInformation',
                    'subject_financial_information_type'
                  )
                }}
              />
              <label
                className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
                htmlFor='subject_financial_information_type_afs'
              >
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4'>
                    {intl.formatMessage({ id: 'CM.AFS' })}
                  </span>
                </span>
              </label>
            </div>

            <div className='text-danger mt-2'></div>
          </div>
        </div>
        <Modal
          show={showAFSModal}
          onHide={handleCloseAFSModal}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>{intl.formatMessage({ id: 'CM.COMPANY_AFS' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='fw-row ms-10 me-10'>
              <FormAFS
                ComputeForm={ComputeForm}
                handleYearlyDataChange={handleYearlyDataChange}
                handleConfirm={handleConfirm}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                setPageState={setPageState}
                PageState={PageState}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseAFSModal}>
              {intl.formatMessage({ id: 'CM.CLOSE' })}
            </Button>
            <Button variant='primary' onClick={() => handleSubmit()}>
              {intl.formatMessage({ id: 'CM.SAVE' })}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showRatioModal}
          onHide={handleCloseRatioModal}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>{intl.formatMessage({ id: 'CM.COMPANY_RATIO' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={clsx('fv-row', {
                'visually-hidden':
                  companyInformation.subject_financial_information_type !== 'ratio',
              })}
            >
              <ErrorAlert
                title={intl.formatMessage({ id: PageState.alertTitle !== ''? PageState.alertTitle : 'NA' })}
                message={intl.formatMessage({ id: PageState.alertMessage !== ''? PageState.alertMessage : 'NA'  })}
                enable={PageState.alert}
                dismiss={() => setPageState({...PageState, alert: false})}
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.COMPANY_NAME' })}
                type='text'
                param1='companyInformation'
                param2='company_name'
                value={companyInformation.company_name}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.OPERATING_MARGIN' }) + ' (%)'}
                type='number'
                param1='companyAnalysis'
                param2='operating_margin'
                value={companyAnalysis.operating_margin}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='operating'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/operating.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.EBITDA_MARGIN' }) + ' (%)'}
                type='number'
                param1='companyAnalysis'
                param2='ebitda_margin'
                value={companyAnalysis.ebitda_margin}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='ebitda'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/ebitda.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='debt_to_equity'
                value={companyAnalysis.debt_to_equity}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='debttoequity'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/debttoequity.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='debt_to_ebitda'
                value={companyAnalysis.debt_to_ebitda}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='debttoebitda'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/debttoebitda.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='ebitda_to_intexp'
                value={companyAnalysis.ebitda_to_intexp}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='ebitdatointexp'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/ebitdatointexp.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.CFO_TO_DEBT' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='cfo_to_debt'
                value={companyAnalysis.cfo_to_debt}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='cfo-to-debt'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/cfo-to-debt.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.FCF_TO_DEBT' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='fcf_to_debt'
                value={companyAnalysis.fcf_to_debt}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='fcf'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/fcf.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='cfo_to_intexp'
                value={companyAnalysis.cfo_to_intexp}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='cfotointexp'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/cfotointexp.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.CASH_RATIO' }) + ' (x)'}
                type='number'
                param1='companyAnalysis'
                param2='cash_ratio'
                value={companyAnalysis.cash_ratio}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='cashratio'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/cashratio.png')}
                  ></img>
                }
              />
              <LineInput
                label={intl.formatMessage({ id: 'CM.CFO_GROWTH' }) + ' (%)'}
                type='number'
                param1='companyAnalysis'
                param2='cfo_growth'
                value={companyAnalysis.cfo_growth}
                required={true}
                onchange={(e, p1, p2) => handleChange(e, p1, p2)}
                tooltip={
                  <img
                    alt='cfo'
                    src={toAbsoluteUrl('/media/corporate-model/tooltip/cfo.png')}
                  ></img>
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseRatioModal}>
              {intl.formatMessage({ id: 'CM.CLOSE' }) }
            </Button>
            <Button variant='primary' onClick={() => handleSubmit()}>
              {intl.formatMessage({ id: 'CM.SAVE' })  }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default Step2
