import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IUserSubscription } from '../../pages/subscriptions/Subscription'; // Make sure this path is correct and updated
import axios from '../../../lib/axios';
import { useIntl } from 'react-intl';

interface LocationState {
  subscription_id: string;
}

const UserSubscription: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();

  const [subscription, setSubscription] = useState<IUserSubscription | null>(null);


  const getSubscription = async (subscription_id: string): Promise<IUserSubscription> => {
    const url = `/api/subscription/show/${subscription_id}`; // Fixed URL construction
    const response = await axios.get<IUserSubscription>(url); // Ensure axios is typed correctly
    return response.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const subscription_id = (location.state as LocationState)?.subscription_id;
      if (subscription_id) {
        const subscriptionData = await getSubscription(subscription_id);
        setSubscription(subscriptionData);
      }
    };

    fetchData();
  }, [location.state]);

  return (
    <>
      {
        !subscription ? (
          <div className="alert alert-info" role="alert">
            {intl.formatMessage({ id: 'NO_SUBSCRIPTION_FOUND' })}
          </div>
        ) : (
          <>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{subscription.id}</h5>
                <p className="card-text">{subscription.description || 'No description available.'}</p>
                <p className="card-text">{intl.formatMessage({ id: 'SUBSCRIPTION_STATUS' })}: {subscription.status}</p>
              </div>
            </div>
            {subscription.histories && subscription.histories.length > 0 && (
              <div className="card mt-4">
                <div className="card-body">
                  <h5 className="card-title">{intl.formatMessage({ id: 'SUBSCRIPTION_HISTORY' })}</h5>
                  {subscription.histories.map((history) => (
                    <div key={history.id}>
                      <p className="card-text">{intl.formatMessage({ id: 'EVENT_TYPE' })}: {history.eventType}</p>
                      <p className="card-text">{intl.formatMessage({ id: 'DESCRIPTION' })}: {history.description || intl.formatMessage({ id: 'NO_DESCRIPTION_PROVIDED' })}</p>
                      <p className="card-text">{intl.formatMessage({ id: 'EVENT_DATE' })}: {history.eventDate}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )
      }
    </>
  );
};

export default UserSubscription;
