import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { ErrorMessage } from '../../components/messageAlertComponents';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';

import PeriodDropDownAutoPopulate from './components/PeriodDropDownAutoPopulate';
import { useAuth } from '../../../hooks/auth';
import { IntlContext, useIntl } from 'react-intl';

type LocationState = {
  subscriptionRequestId: string
}

interface Product {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
}

interface Price {
  id: number;
  product_id: number;
  amount: number; // Fix: Change the type from string to number
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface Feature {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}


const SubscriptionRequestApprovePage: React.FC = () => {
  const intl = useIntl();
  const URL_APPROVE_REQUEST = '/api/subscription-requests/approve';

  const slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true
  };


  const { loading, user } = useAuth('auth');
  const location = useLocation();
  const navigate = useNavigate();

  const [
    productLoaded, setProductLoaded
  ] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const [initialData, setInitialData] = useState<any>({});


  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [subscription_period, setSubscriptionPeriod] = useState<string>('');
  const [product_list, setProductList] = useState<{ value: number; label: string; }[]>([]);
  const [subscriptionRequestStatus, setSubscriptionRequestStatus] = useState<string>('');
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const subscriptionRequestId = (location.state as LocationState)?.subscriptionRequestId
  const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm(



  );














  const handleSelectProduct = product => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    const updatedSelectedProducts = [...selectedProducts];
    if (index >= 0) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
    setValue('product_list', updatedSelectedProducts.map(p => ({ value: p.id, label: p.name })));
  };
  const onSelectProduct = (product: Product) => {
    handleSelectProduct(product);
  };
  const {
    showInfo,
    showSuccess,
    showError } = useToast();



  // Custom validation for checking the length and non-empty content
  const validateQuillContent = (htmlString) => {
    if (!htmlString) {
      return true;
    }

    // First, check if the htmlString is effectively empty
    if (htmlString !== '' || htmlString !== '<p><br></p>' || htmlString !== '<p>&nbsp;</p>' || htmlString !== null) {
      const text = htmlString.replace(/<[^>]+>/g, '').trim(); // Remove HTML tags and trim
      // Check if text is within the required length constraints
      if (text.length === 0) {
        return 'Content cannot be empty'; // More specific for cases where content is only HTML whitespace
      } else if (text.length > 1000) {
        return 'Content must be between 1 and 1000 characters long without HTML tags'; // Error if content is too long
      }
    }
    return true; // If all checks are passed, return true indicating the content is valid
  };
  const fetchProducts = useCallback(async () => {
    try {
      setIsLoadingProducts(true);
      const response = await axios.get('/api/products');
      if (response.status === 200) {
        setProducts(response.data);
        return response.data;  // Return the products directly.
      } else {
        throw new Error('Failed to fetch products');
      }
    } catch (err: any) {
      setError(err?.message);
      throw err;
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const fetchData = useCallback(async (products) => {
    try {
      setIsLoadingRequest(true);
      const response = await axios.post(`/api/subscription-requests/get-request`, { id: subscriptionRequestId });
      if (response.status === 200 && response.data.status === 'Success') {
        const dataForm = response.data.request;
        setSubscriptionRequestStatus(dataForm.status);
        if (dataForm && products) {
          if (dataForm.product_list) {
            const productListArray = dataForm.product_list.split(',');
            const dataProductArray = productListArray.map(id => id.trim());
            const formattedProducts = products.filter(product => dataProductArray.includes(product.id.toString())).map(product => ({
              value: product.id,
              label: product.name
            }));
            setValue('product_list', formattedProducts);
            setInitialData({
              ...dataForm,
              product_list: formattedProducts,
            });
            reset({
              ...dataForm,
              product_list: formattedProducts,
            });
          }
        } else {
          throw new Error('Failed to fetch subscription request data');
        }
      }
    } catch (err: any) {
      setError(err?.message);
      throw err;
    } finally {
      setIsLoadingRequest(false);
    }
  }, [subscriptionRequestId, setValue, reset]);

  const initializeData = useCallback(async () => {
    if (isLoaded) return;
    setIsLoading(true);
    try {
      const fetchedProducts = await fetchProducts(); // Await the products to be fetched directly.
      await fetchData(fetchedProducts); // Pass the fetched products directly to fetchData.
      setIsLoaded(true);
    } catch (error) {
      showError('Initialization failed');
    } finally {
      setIsLoading(false);
    }
  }, [fetchProducts, fetchData, showError, isLoaded]);

  useEffect(() => {
    ; (
      async () => {
        if (subscriptionRequestId) {
          initializeData();
        } else {
          showError('Invalid subscription request ID');
          navigate('/subscriptions/admin-subscription-request');
        }
      })()
  },
    [
      subscriptionRequestId,
      initializeData,
      navigate,
      showError
    ]

  );

  if (isLoading) {
    return <div><div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">
          {intl.formatMessage({ id: 'LOADING' })}
        </span>
      </div>
    </div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleReset = () => {
    reset(initialData);  // Reset to initial fetched values
  };

  const handleClearForm = () => {
    reset({
      name: '',
      email: '',
      phone: '',
      product_list: [],
      agreeToTerms: false,
      organization_name: '',
      organization_phone: '',
      organization_email: '',
      organization_address: '',
      organization_website: '',
      subscription_period: '',
      request_limit: 0

    });
  };


  const onSubmit = async (formData) => {
    console.log('--- on submit ---');
    console.log(formData);

    //set status to approve
    formData.status = 'Approved';
    formData.id = subscriptionRequestId;
    formData.product_list = formData.product_list.map((product: any) => product.value).join(', ');

    try {
      setIsLoading(true);
      const response = await axios.post(URL_APPROVE_REQUEST, formData);
      if (response.status === 200 && response.data.message === 'Subscription request approved successfully') {
        showSuccess('Request approved successfully');
        navigate('/subscriptions/admin-subscription-request');
      } else {
        showError('Failed to approve request');
      }
    } catch (err: any) {
      showError(err?.message);
    } finally {
      setIsLoading(false);
    }

  };


  return (
    <div>
      <div className="card"></div>
      <div className="card-body">
        <h5 className="card-title">{intl.formatMessage({ id: 'ADMIN_DASHBOARD_APPROVE_SUBSCRIPTION_REQUEST' })}</h5>
        <p className="card-text">{intl.formatMessage({ id: 'ADMIN_DASHBOARD_APPROVE_SUBSCRIPTION_REQUEST_DESC' })}</p>
        {isLoading || loading ? (<div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">
              {intl.formatMessage({ id: 'LOADING' })}
            </span>
          </div>
        </div>) : (
          <>
            <h1>Approve Request #:{subscriptionRequestId}</h1>

            {isLoadingProducts ? (
              <p>Loading Products...</p>
            ) : (
              <>
                {
                  (user?.role === 'Super Admin' || user?.role === 'Data Analyst') ?
                    (
                      <>

                        <Slider {...slider_settings}>
                          {products.map(product => (
                            <div key={product.id} className="card card-custom gutter-b">
                              <div className="card-header">
                                <div className="card-title">
                                  <h3 className="card-label">{product.name}</h3>
                                </div>
                                <div className="card-toolbar">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info font-weight-bold"
                                    onClick={() => onSelectProduct(product)}
                                  >
                                    <i className="fa-solid fa-bolt"></i>
                                    {intl.formatMessage({ id: 'SELECT' })}
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                Price: {product.price} {product.price?.[0]?.currency || 'MYR'}
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Features Included</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {product.features?.map((feature: any) => (
                                      <tr key={feature.id}>
                                        <td>{feature.feature_name}</td>
                                        <td>
                                          {feature.feature_value === 'Yes' ? (
                                            <span style={{ color: 'green' }}>
                                              <i className="fa-solid fa-circle-check"></i>
                                            </span>
                                          ) : (
                                            <span style={{ color: 'red' }}>
                                              <i className="fa-solid fa-circle-xmark"></i>
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </Slider>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          {/** if product list not null, preselect */}
                          <Form.Group className="mb-3">
                            <Form.Label>{intl.formatMessage({ id: 'PRODUCT_LIST' })}</Form.Label>
                            <Controller
                              name="product_list"
                              defaultValue={product_list}
                              control={control}
                              rules={{ required: 'Please select at least one product.' }}
                              render={({ field, fieldState: { error } }) => (
                                <Select
                                  {...field}
                                  options={products.map(product => ({ value: product.id, label: product.name }))}
                                  isMulti
                                  className={error ? 'is-invalid' : ''}
                                />
                              )}
                            />
                            {errors.product_list && <>{errors.product_list.message}</>}
                          </Form.Group>
                          <PeriodDropDownAutoPopulate control={control} preselectedValue={subscription_period} />

                          <Form.Group className="mb-3">
                            <Form.Label>
                              {intl.formatMessage({ id: 'NAME' })}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {...register('name', { required: true, value: user?.name })}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {intl.formatMessage({ id: 'NAME_REQUIRED' })}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>
                              {intl.formatMessage({ id: 'EMAIL' })}
                            </Form.Label>
                            <Form.Control
                              type="email"
                              {...register('email', {
                                required: true,
                                pattern: /^\S+@\S+$/i,
                                value: user?.email
                              })}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {intl.formatMessage({ id: 'EMAIL_INVALID' })}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>
                              {intl.formatMessage({ id: 'TELEPHONE' })}
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              {...register('phone', {
                                required: true,
                                pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/
                              })}
                              isInvalid={!!errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {intl.formatMessage({ id: 'PESR.PHONE.PHONE_INVALID' })}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage({ id: 'ORGANIZATION' })
                              }
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {
                              ...register('organization_name',
                                {
                                  required: true,
                                  minLength: 3,
                                  maxLength: 255

                                }


                              )}
                              isInvalid={!!errors.organization}

                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                intl.formatMessage({
                                  id: 'FORM_FEEDBACK.ORGANIZATION_REQUIRED'
                                })
                              }
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage({ id: 'organization_phone' })
                              }
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              {...register('organization_phone',
                                { required: false, pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/ })}
                              isInvalid={!!errors.organization_phone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                intl.formatMessage({ id: 'PESR.PHONE.PHONE_INVALID' })
                              }
                            </Form.Control.Feedback>
                          </Form.Group>



                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage(
                                  {
                                    id: 'organization_address'
                                  }
                                )
                              }
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {
                              ...register('organization_address',
                                {
                                  required: true,
                                  minLength: 3,
                                  maxLength: 255

                                }
                              )}
                              isInvalid={!!errors.organization_address}
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                intl.formatMessage({
                                  id: 'PESR.FORM.ORGANIZATION_ADDRESS_REQUIRED'
                                })
                              }
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage({
                                  id: 'organization_website'
                                })
                              }
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {
                              ...register('organization_website',
                                {
                                  required: true,
                                  minLength: 3,
                                  maxLength: 255,
                                  pattern: /^(http|https):\/\/[^ "]+$/
                                }
                              )}
                              isInvalid={!!errors.organization_website}
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                intl.formatMessage({
                                  id: 'PESR.FORM.ORGANIZATION_WEBSITE_REQUIRED'
                                })
                              }
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage({ id: 'organization_note' })
                              }
                            </Form.Label>
                            <Controller
                              name="organization_note"
                              control={control}
                              rules={{
                                required: false,
                                validate: validateQuillContent
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <>
                                  <ReactQuill
                                    theme="snow"
                                    {...field}
                                  />
                                  {error && <p style={{ color: '#EA868F' }}>{error.message}</p>}
                                </>
                              )}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {
                                intl.formatMessage({ id: 'ADMIN_CREATE_SUBSCRIPTION.LABEL.REQUEST_LIMIT_LABEL' })
                              }
                            </Form.Label>
                            <Form.Control
                              type="number"
                              {...register('request_limit',
                                {
                                  required: true,
                                  valueAsNumber: true,
                                  min: 0
                                })}
                              isInvalid={!!errors.request_limit}
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                intl.formatMessage({ id: 'FORM_FEEDBACK_POSITIVE_NUMERICAL_VALUE' })
                              }
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ?
                              intl.formatMessage({ id: 'UPDATING' })
                              : intl.formatMessage({ id: 'APPROVE_REQUEST' })}
                          </Button>

                          <Button variant="secondary" onClick={handleReset}>
                            {intl.formatMessage({ id: 'RESET_INITIAL_DATA_BTN' })}
                          </Button>
                          <Button variant="danger" onClick={handleClearForm}>
                            {intl.formatMessage({ id: 'CLEAR_BTN' })}
                          </Button>


                        </Form>
                      </>

                    ) : (<><ErrorMessage message={intl.formatMessage({ id: 'YOU_ARE_NOT_AUTHORIZED_TO_VIEW_THIS_PAGE' })} title={intl.formatMessage({ id: 'TITLE_UNAUTHORIZED' })} /></>)
                }

              </>
            )}
          </>
        )}

      </div>
    </div>
  );
};

export default SubscriptionRequestApprovePage;