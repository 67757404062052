import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers';
import { useAuth } from '../../../hooks/auth';
import { ErrorMessage } from '../../components/messageAlertComponents';
import { useIntl } from 'react-intl';

type Subscription = {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  name: string;
  description: string;
  is_subscription_admin: number;
  parent_subscription_id: number | null;
  status: 'pending' | 'active' | 'cancelled' | 'deactivated' | 'suspended' | 'expired';
  is_shared: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  usage: Usage[];
  company_subscription: CompanySubscription[];
  histories: History[];
  products: ProductArray
};

type Usage = {
  id: number;
  subscription_id: number;
  description: string | null;
  usage: number;
  date: string;
  created_at: string;
  updated_at: string;
  limit: number;
  is_limit_exceeded: boolean;
};

type CompanySubscription = {
  id: number;
  name: string;
  address: string;
  email: string;
  website: string;
  phone: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  note: string;
  status: 'active' | 'inactive';
  original_admin_id: number | null;
  pivot: {
    subscription_id: number;
    company_id: number;
  };
};

type History = {
  id: number;
  subscription_id: number;
  subscription_status: 'pending' | 'active' | 'cancelled';
  user_id: number;
  start_date: string | null;
  end_date: string | null;
  event_type: string;
  description: string;
  event_date: string;
  product_ids: number;
  multiple_products: number;
  created_at: string;
  updated_at: string;
};

type LocationState = {
  subscriptionId: string;
};

type SubscriptionProduct = {
  id: number;
  usage_limit: number;
  usage_count: number;
  status: string;
};
interface Product {
  id: number;
  name: string;
  product_key: string;
  description: string;
  type: string;
  active: number;
  price: number;
  quantity: number;
  is_subscription: number;
  subscription_period: string;
  created_at: string;
  updated_at: string;
  pivot: {
    subscription_id: number;
    product_id: number;
  },
  subscription_product: SubscriptionProduct[];
}

type ProductArray = Product[];
const AdminViewSubscription: React.FC = () => {
  const intl = useIntl();
  const { user } = useAuth('auth');
  const location = useLocation();
  const navigate = useNavigate();
  const subscriptionId = (location.state as LocationState)?.subscriptionId;
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [localLoading, setLocalLoading] = useState(false);

  const fetchSubscriptionData = async () => {
    setLocalLoading(true);
    try {
      const response = await axios.get(`/api/subscription/view/${subscriptionId}`);
      setSubscription(response.data.data);
    } catch (error: any) {
      console.error('Failed to fetch subscription:', error);
      // Consider showing an error message to the user here
    } finally {
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      fetchSubscriptionData();
    }
  }, [subscriptionId]);

  const handleEdit = () => {
    if (subscriptionId) {
      navigate('/subscriptions/admin-edit-subscription', { state: { subscriptionId } });
    } else {
      console.error('Subscription ID is null');
    }
  };

  const handleRefresh = () => {
    if (subscriptionId) {
      fetchSubscriptionData();
    }
  };

  const IsSuperAdmin = user && user.role === 'Super Admin';

  if (localLoading === true) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        {IsSuperAdmin ? (
          <div className="card mb-2">
            <div className="card-header">
              <h3 className="card-title">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.SUBSCRIPTION_DETAILS_FOR_ID` })} {subscriptionId} </h3>
              <div className="card-toolbar">
                <a href="/subscriptions/admin-all-subscriptions" className="btn btn-sm btn-light-primary">
                  <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
                  {intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.BACK_TO_LIST` })}
                </a>
                <button className="btn btn-sm btn-light-warning" onClick={handleEdit}>
                  <i className="fa fa-pen"></i>
                  {intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.EDIT` })}
                </button>
                <button className="btn btn-sm btn-light-system" onClick={handleRefresh}>
                  <i className="fa fa-refresh"></i>
                  {intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.REFRESH` })}
                </button>
              </div>
            </div>
            <div className="card-body">
              {subscription ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.NAME` })}</label>
                        <p className="form-control-plaintext">{subscription.name}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.DESCRIPTION` })}</label>
                        <p className="form-control-plaintext">{subscription.description}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.START_DATE` })}</label>
                        <p className="form-control-plaintext">{subscription.start_date}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.END_DATE` })}</label>
                        <p className="form-control-plaintext">{subscription.end_date}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.STATUS` })}</label>
                        <p className="form-control-plaintext">{subscription.status}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.ADMIN_SUBSCRIPTION` })}</label>
                        <p className="form-control-plaintext">
                          {subscription.parent_subscription_id ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.SHARED` })}</label>
                        <p className="form-control-plaintext">{subscription.is_shared ? 'Yes' : 'No'}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.CREATED_AT` })}</label>
                        <p className="form-control-plaintext">{subscription.created_at}</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.UPDATED_AT` })}</label>
                        <p className="form-control-plaintext">{subscription.updated_at}</p>
                      </div>
                      {subscription.usage[0] && (
                        <>
                          <div className="mb-3">
                            <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.USAGE` })}</label>
                            <p className="form-control-plaintext">{subscription.usage[0].usage}</p>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.USAGE_LIMIT` })}</label>
                            <p className="form-control-plaintext">{subscription.usage[0].limit}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {subscription.histories.length > 0 ? (
                    <div className="mb-3">
                      <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.HISTORIES` })}</label>
                      <table className="table table-striped table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                          <tr>
                            <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.EVENT_TYPE` })}</th>
                            <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.EVENT_DATE` })}</th>
                            <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.DESCRIPTION` })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscription.histories.map((history) => (
                            <tr key={history.id}>
                              <td>{history.event_type}</td>
                              <td>{history.event_date}</td>
                              <td>{history.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.HISTORIES` })}</label>
                      <p className="form-control-plaintext">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.NO_HISTORIES_FOUND` })}</p>
                    </div>
                  )}



                  {
                    subscription.products.length > 0 ? (
                      <div className="mb-3">
                        <label className="form-label">{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION_PAGE.LABEL.PRODUCTS_LINKED` })}</label>
                        <table className="table table-striped table-row-dashed table-row-gray-300 gy-7">
                          <thead>
                            <tr>
                              <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION.TABLE_LABEL.NAME` })}</th>
                              <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION.TABLE_LABEL.TYPE` })}</th>
                              <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION.TABLE_LABEL.PRICE` })}</th>
                              <th>{intl.formatMessage({ id: `ADMIN_VIEW_SUBSCRIPTION.TABLE_LABEL.SUBSCRIPTION` })}</th>
                              <th>{intl.formatMessage({ id: `CREATED_AT` })}</th>
                              <th>{intl.formatMessage({ id: `UPDATED_AT` })}</th>
                              <th>{intl.formatMessage({ id: `USAGE_COUNT` })}</th>
                              <th>Usage Limit</th>
                              <th>Link Status</th>

                            </tr>
                          </thead>
                          <tbody>
                            {subscription.products.map((product) => (
                              <tr key={product.id}>
                                <td>{product.name}</td>
                                <td>{product.type}</td>
                                <td>{product.price}</td>
                                <td>{product.is_subscription ? 'Yes' : 'No'}</td>
                                <td>{new Date(product.created_at).toLocaleDateString()}</td>
                                <td>{new Date(product.updated_at).toLocaleDateString()}</td>
                                <td>{product.subscription_product[0].usage_count}</td>
                                <td>{product.subscription_product[0].usage_limit}</td>
                                <td>{product.subscription_product[0].status}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="mb-3">
                        <label className="form-label">Products:</label>
                        <p className="form-control-plaintext">No products found</p>
                      </div>
                    )
                  }

                </>
              ) : (
                <p>Subscription not found</p>
              )}
            </div >
          </div >
        ) : (
          <ErrorMessage message="You are not authorized to view this content." title="Unauthorized" />
        )}
      </>
    );
  }


};

export default AdminViewSubscription;