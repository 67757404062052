import React, { createContext, useContext, useState } from 'react';
import ToastComponent from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastContext = createContext<{
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  showInfo: (message: string) => void;
  showWarning: (message: string) => void;

} | null>(null);

interface Toast {
  id: string;
  message: string;
  variant: 'danger' | 'success' | 'info' | 'warning';
}

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]); // Specify the type here


  const addToast = (message: string, variant: 'danger' | 'success' | 'info' | 'warning') => {
    const id = Math.random().toString(36).substring(2, 9);
    const newToast = { id, message, variant };
    setToasts(toasts => [...toasts, newToast]);

    setTimeout(() => {
      setToasts(toasts => toasts.filter(toast => toast.id !== id));
    }, 5000);
  };

  const toastMethods = {
    showError: (message: string) => addToast(message, 'danger'),
    showSuccess: (message: string) => addToast(message, 'success'),
    showInfo: (message: string) => addToast(message, 'info'),
    showWarning: (message: string) => addToast(message, 'warning')

  };

  return (
    <ToastContext.Provider value={toastMethods}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        {toasts.map(({ id, message, variant }) => (
          <ToastComponent key={id} bg={variant} autohide delay={5000} onClose={() => setToasts(toasts => toasts.filter(toast => toast.id !== id))}>
            <ToastComponent.Header closeButton>
              <strong className="me-auto">Notification</strong>
            </ToastComponent.Header>
            <ToastComponent.Body><p style={{ color: 'white' }}>{message}</p></ToastComponent.Body>
          </ToastComponent>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};
