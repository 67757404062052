import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface ButtonSubscriptionViewProps {
  flagParentSubscription: boolean;
  flagDefaultAdmin: boolean;
  flagDefaultOwnerSubscription: boolean;
  subscriptionId: string;
  companyId: string;
  setSubscriptionAsCompanyDefault: (subscriptionId: string, companyId: string) => void;
  setSubscriptionAsNotCompanyDefault: (subscriptionId: string, companyId: string) => void;
  markSubscriptionForAdmin: (subscriptionId: string) => void;
  unmarkSubscriptionForAdmin: (subscriptionId: string) => void;
}

const ButtonSubscriptionView = ({
  flagParentSubscription,
  flagDefaultAdmin,
  flagDefaultOwnerSubscription,
  subscriptionId,
  companyId,
  setSubscriptionAsCompanyDefault,
  setSubscriptionAsNotCompanyDefault,
  markSubscriptionForAdmin,
  unmarkSubscriptionForAdmin
}: ButtonSubscriptionViewProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const URL_EDIT_SUBSCRIPTION = '/subscriptions/admin-edit-subscription';

  const handleEditClick = () => {
    navigate(URL_EDIT_SUBSCRIPTION, {
      state: { subscriptionId },
    });
  };

  const renderAdminMarkButton = () => {
    const handleClick = flagDefaultOwnerSubscription
      ? () => unmarkSubscriptionForAdmin(subscriptionId)
      : () => markSubscriptionForAdmin(subscriptionId);

    const buttonLabelId = flagDefaultOwnerSubscription
      ? 'LABEL.MARK_UNMARK_DEFAULT_FOR_ADMIN'
      : 'LABEL.MARK_DEFAULT_FOR_ADMIN';

    return (
      <Button
        className='btn btn-sm btn-light-warning me-2'
        onClick={handleClick}
      >
        <i className='fa fa-wrench'></i>
        {intl.formatMessage({ id: buttonLabelId })}
      </Button>
    );
  };

  const renderDefaultSubscriptionButton = () => {
    const handleClick = flagDefaultAdmin
      ? () => setSubscriptionAsNotCompanyDefault(subscriptionId, companyId)
      : () => setSubscriptionAsCompanyDefault(subscriptionId, companyId);

    const buttonLabelId = flagDefaultAdmin
      ? 'LABEL.UNSET_AS_DEFAULT_SUBSCRIPTION'
      : 'LABEL.SET_AS_DEFAULT_SUBSCRIPTION';

    return (
      <Button
        className='btn btn-sm btn-light-warning me-2'
        onClick={handleClick}
      >
        <i className='fa fa-wrench'></i>
        {intl.formatMessage({ id: buttonLabelId })}
      </Button>
    );
  };

  return (
    <div className='btn-group-vertical'>
      <Button
        className='btn btn-sm btn-light-primary me-2'
        onClick={handleEditClick}
      >
        <i className='fa fa-wrench'></i>
        {intl.formatMessage({ id: 'EDIT_BTN' })}
      </Button>
      {!flagParentSubscription && (
        <>
          {renderAdminMarkButton()}
          {renderDefaultSubscriptionButton()}
        </>
      )}
    </div>
  );
}

export default ButtonSubscriptionView;