import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import { SubscriptionsResponse } from '../../pages/subscriptions/Subscription';
import { Button } from 'react-bootstrap';
import TabComponent from './components/TabComponent';
import ConvertDateComponent from '../../components/ConvertDateComponent';
import { useNavigate } from 'react-router-dom';
import { IUserSubscription } from '../../pages/subscriptions/Subscription';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';

const AdminAllSubscriptions: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { loading, user } = useAuth('auth');
  const [subscriptions, setSubscriptions] = useState<SubscriptionsResponse | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<IUserSubscription | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { showError, showSuccess, showWarning } = useToast();

  const hasFetchedData = useRef(false);
  const hasShownError = useRef(false);

  const resetSelection = () => {
    setSelectedId(null);
    setSelectedSubscription(null);
  };


  const fetchSubscriptions = useCallback(async () => {
    try {
      const response = await axios.get(`/api/subscription`);
      setSubscriptions(response.data.subscriptions);
      showSuccess('Subscriptions fetched successfully');
    } catch (error) {
      showError('Failed to fetch subscriptions');
    }
  }, [showError, showSuccess]);

  const refreshData = useCallback(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const handleRefreshAllSubscription = async () => {
    const result = await confirm(`Confirm refresh entire subscription? `);
    if (result) {


      try {
        const response = await axios.get('/api/subscription/refresh-all-subscription');

        if (response.status === 200) {
          showSuccess('Success refresh subscription');
        } else {
          showError('Subscription not set as  default');
        }
      } catch (error) {
        console.error('Error refreshing all subscription:', error);
        showError('Failed to refresh subscription ');
      }
    } else {
      showWarning('You have cancelled the action');
    }
  };
  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchSubscriptions().then(() => {
        setIsDataLoaded(true);
      });
      hasFetchedData.current = true;
    }
  }, [fetchSubscriptions]);

  useEffect(() => {
    if (!loading && !['Super Admin', 'Data Analyst'].includes(user?.role ?? '') && !hasShownError.current) {
      showError('You are not authorized to view this page');
      hasShownError.current = true;
    }
  }, [loading, user, showError]);

  const statusWrapper = (status: string) => {
    switch (status) {
      case 'active':
        return <span className="badge bg-success">{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.active' })}</span>;
      case 'pending':
        return <span className="badge bg-warning">{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.pending' })}</span>;
      case 'cancelled':
        return <span className="badge bg-danger">{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.cancelled' })}</span>;
      case 'deactivated':
        return <span className="badge bg-danger">{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.deactivated' })}</span>;
      default:
        return <span className="badge bg-info">{intl.formatMessage({ id: 'SUBSCRIPTION_PAGE.ADMIN_ALL_SUBCRIPTION.unknown' })}</span>;
    }
  };

  const TableHeader = () => (
    <thead>
      <tr>
        <th>{intl.formatMessage({ id: 'ID' })}</th>
        <th>{intl.formatMessage({ id: 'name' })}</th>
        <th>{intl.formatMessage({ id: 'email' })}</th>
        <th>{intl.formatMessage({ id: 'Company' })}</th>
        <th>{intl.formatMessage({ id: 'Start Date' })}</th>
        <th>{intl.formatMessage({ id: 'Expired Date' })}</th>
        <th>{intl.formatMessage({ id: 'Shared' })}  </th>
        <th>{intl.formatMessage({ id: 'User Is Subscription Admin' })}</th>
        <th>{intl.formatMessage({ id: 'Status' })}</th>
        <th>{intl.formatMessage({ id: 'Created At' })}</th>
        <th>{intl.formatMessage({ id: 'Updated At' })}</th>
        <th>{intl.formatMessage({ id: 'Description' })}</th>
        <th>{intl.formatMessage({ id: 'Actions' })}</th>
      </tr>
    </thead>
  );

  const tableIsShared = (isShared: boolean) => (isShared ? 'Yes' : 'No');
  const tableIsSubAdmin = (IsSubAdmin: boolean) => (IsSubAdmin ? 'Yes' : 'No');

  const TableBody = ({ subscriptions }) => {
    if (subscriptions && subscriptions.length > 0) {
      return (
        <tbody>
          {subscriptions.map((subscription) => (
            <tr key={subscription.id}>
              <td>{subscription.id}</td>

              <td>{subscription.username}</td>
              <td>{subscription.usermail}</td>
              <td>{(subscription.company !== '') ? (subscription.company) : null}</td>
              <td><ConvertDateComponent utcTimestamp={subscription.start_date} /></td>
              <td><ConvertDateComponent utcTimestamp={subscription.end_date} /></td>
              <td>{tableIsShared(subscription.is_shared)}</td>
              <td>{tableIsSubAdmin(subscription.is_subscription_admin)}</td>
              <td>{statusWrapper(subscription.status)}</td>
              <td><ConvertDateComponent utcTimestamp={subscription.created_at} /></td>
              <td><ConvertDateComponent utcTimestamp={subscription.updated_at} /></td>
              <td>{subscription.description}</td> {/* Fixed description rendering */}
              <td>
                <Button className="btn btn-success" onClick={() => {
                  setSelectedId(subscription.id)
                  setSelectedSubscription(subscription)
                }
                }>
                  <i className="fa-regular fa-hand"></i>{intl.formatMessage({ id: 'SELECT' })}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (subscriptions === null) {
      return (
        <tbody>
          <tr>
            <td colSpan={12}>{intl.formatMessage({ id: 'LOADING_SUBSCRIPTIONS' })}</td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={12}>{intl.formatMessage({ id: 'NO_SUBSCRIPTIONS_FOUND' })}</td>
          </tr>
        </tbody>
      );
    }
  };

  const navigateAdminSubscriptionPage = () => {
    setTimeout(() => {
      navigate('/subscriptions/admin-create-subscription');
    }, 2000);
  };

  const Roles = ['Super Admin', 'Data Analyst'];

  return (
    <div>
      {
        (isDataLoaded === false && !Roles.includes(user?.role ?? '')) ? (
          <div></div> // Do not render anything when user is not authorized
        ) : (
          <>
            <div className="card">
              <div className='card-header'>
                <div className="card-toolbar">
                  <button className="btn btn-sm btn-primary" onClick={navigateAdminSubscriptionPage}>
                    <i className="bi bi-plus"></i>
                    <span className="ms-1">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.ADD_SUBSCRIPTION' })}</span>
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={refreshData}>
                    <i className="fa fa-refresh"></i>
                    <span className="ms-1">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.REFRESH_ALL_SUBSCRIPTIONS' })}</span>
                  </button>
                  <button className="btn btn-sm btn-dark" onClick={handleRefreshAllSubscription}>
                    <i className="fa fa-circle"></i>
                    <span className="ms-1">{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.REFRESH_ALL_SUBSCRIPTION_USAGE' })}</span>
                  </button>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <TableHeader />
                    <TableBody subscriptions={subscriptions} />
                  </table>
                </div>
              </div>

              <div className="card-footer">
                <h1>{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.SUBSCRIPTION_DETAILS_FOR' })} {selectedId}</h1>
                {
                  (selectedId == null) ? (
                    <div>{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.CLICK_ANY_SUBSCRIPTION_TO_ACCESS_THIS_PANEL' })}</div>
                  ) : (
                    <div className="card">
                      <div className='card-header'>
                        <div>{intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.SUBSCRIPTION_DETAILS_FOR' })} {selectedId}</div>
                        <button className="btn btn-sm btn-primary" onClick={resetSelection}>
                          <i className="fa-solid fa-caret-down"></i>
                          {intl.formatMessage({ id: 'ADMIN_ALL_SUBSCRIPTIONS.LABEL.CLOSE_PANEL' })}
                        </button>
                      </div>
                      <div className="card-body">
                        <TabComponent
                          subscriptionId={selectedId}
                          Subscription={selectedSubscription}
                          showSuccess={showSuccess}
                          showError={showError}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

export default AdminAllSubscriptions;
