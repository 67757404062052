import React, { useEffect, useRef, useState } from 'react';
import axios from './../../../../lib/axios';
import { Modal, Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import Editor from '../../../components/Editor';
import Quill from 'quill';

const URL_ADD_NOTES = '/api/subscription-requests/notes/save-notes';
const URL_EDIT_NOTES = '/api/subscription-requests/notes/update';
const URL_DELETE_NOTES = '/api/subscription-requests/notes/delete-notes';
const URL_ADD_REPLY = '/api/subscription-requests/notes/reply';
const URL_EDIT_REPLY = '/api/subscription-requests/notes/replies/update';
const URL_DELETE_REPLY = '/api/subscription-requests/notes/delete-reply';

interface Note {
  id: number;
  subscription_request_id: number;
  user_id: number;
  note: string;
  created_at: Date | null;
  updated_at: Date | null;
  user: User;
  replies: NoteReply[];
}

interface NoteReply {
  id: number;
  srn_id: number;
  replier_id: number;
  reply: string;
  created_at: Date | null;
  updated_at: Date | null;
  replier: User;
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface Props {
  Notes: Note[];
  subscriptionRequestId: string;
  userId: string | undefined;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
}

const Notes: React.FC<Props> = ({ Notes, subscriptionRequestId, userId, showSuccess, showError }) => {
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [currentReply, setCurrentReply] = useState<NoteReply | null>(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const noteEditorRef = useRef<Quill | null>(null);
  const replyEditorRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (currentNote && noteEditorRef.current) {
      noteEditorRef.current.setContents(noteEditorRef.current.clipboard.convert({ html: currentNote.note }));
    }
  }, [currentNote]);

  useEffect(() => {
    if (currentReply && replyEditorRef.current) {
      replyEditorRef.current.setContents(replyEditorRef.current.clipboard.convert({ html: currentReply.reply }));
    }
  }, [currentReply]);

  const handleNoteSubmit = async () => {
    if (isEditorReady) {
      const payload = currentNote
        ? {
          note: DOMPurify.sanitize(noteEditorRef.current?.root.innerHTML || ''),
          subscription_request_id: currentNote.subscription_request_id || subscriptionRequestId,
          note_id: currentNote.id,
        }
        : {
          note: DOMPurify.sanitize(noteEditorRef.current?.root.innerHTML || ''),
          subscription_request_id: subscriptionRequestId,
        };
      const url = currentNote ? URL_EDIT_NOTES : URL_ADD_NOTES;
      try {
        await axios.post(url, payload);
        window.location.reload();
        noteEditorRef.current?.setContents([]);
        closeNoteModal();
        showSuccess('Note saved successfully');
      } catch (error) {
        showError('Failed to save note');
      }
    }
  };

  const editNote = (note: Note) => {
    setCurrentNote(note);
    setShowNoteModal(true);
    setIsEditorReady(true);
  };

  const closeNoteModal = () => {
    setShowNoteModal(false);
    setCurrentNote(null);
    setIsEditorReady(false);
    if (noteEditorRef.current) {
      noteEditorRef.current.setContents([]);
    }
  };

  const handleDeleteNote = async (noteId: number) => {
    try {
      await axios.post(`${URL_DELETE_NOTES}`, {
        note_id: noteId,
      });
      window.location.reload();
      showSuccess('Note deleted successfully');
    } catch (error) {
      showError('Failed to delete note');
    }
  };

  const handleDeleteNoteReply = async (replyId: number) => {
    try {
      await axios.post(`${URL_DELETE_REPLY}`, {
        reply_id: replyId,
      });
      window.location.reload();
      showSuccess('Reply deleted successfully');
    } catch (error) {
      showError('Failed to delete reply');
    }
  };

  const handleReplyModal = (note: Note, reply: NoteReply | null = null) => {
    setCurrentNote(note);
    setCurrentReply(reply);
    setShowReplyModal(true);
    setIsEditorReady(true);
  };

  const closeReplyModal = () => {
    setShowReplyModal(false);
    setCurrentReply(null);
    setIsEditorReady(false);
    if (replyEditorRef.current) {
      replyEditorRef.current.setContents([]);
    }
  };

  const handleReplySubmit = async () => {
    if (isEditorReady) {
      const payload = {
        reply: DOMPurify.sanitize(replyEditorRef.current?.root.innerHTML || ''),
        srn_id: currentNote?.id,
        replier_id: userId,
        reply_id: currentReply?.id,
      };
      const url = currentReply ? URL_EDIT_REPLY : URL_ADD_REPLY;
      try {
        await axios.post(url, payload);
        window.location.reload();
        replyEditorRef.current?.setContents([]);
        closeReplyModal();
        showSuccess('Reply saved successfully');
      } catch (error) {
        showError('Failed to save reply');
      }
    }
  };

  const openNoteModal = () => {
    setShowNoteModal(true);
    setCurrentNote(null);
    setIsEditorReady(true);
    if (noteEditorRef.current) {
      noteEditorRef.current.setContents([]);
    }
  };

  return (
    <>
      <Button onClick={openNoteModal} variant="primary">Add Note</Button>

      <Modal show={showNoteModal} onHide={closeNoteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{currentNote ? 'Edit Note' : 'Add Note'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor ref={noteEditorRef} readOnly={false} defaultValue={currentNote?.note || ''} onTextChange={() => { }} onSelectionChange={() => { }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeNoteModal} variant="secondary">Cancel</Button>
          <Button onClick={handleNoteSubmit} variant="primary">Save Note</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReplyModal} onHide={closeReplyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{currentReply ? 'Edit Reply' : 'Add Reply'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor ref={replyEditorRef} readOnly={false} defaultValue={currentReply?.reply || ''} onTextChange={() => { }} onSelectionChange={() => { }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeReplyModal} variant="secondary">Cancel</Button>
          <Button onClick={handleReplySubmit} variant="primary">Save Reply</Button>
        </Modal.Footer>
      </Modal>

      {Notes.length > 0 ? (
        <div className="row">
          {Notes.map((note) => (
            <div key={note.id} className="col-md-12">
              <div className="card card-custom">
                <div className="card-header">
                  <h3 className="card-title">
                    <p className="text-gray-800 text-hover-primary fs-6 fw-bold">
                      {note.user.name}
                    </p>
                  </h3>
                  <div className="card-toolbar">
                    <div className="btn btn-sm btn-light-warning" onClick={() => editNote(note)}>Edit Note</div>
                    <div className="btn btn-sm btn-light-danger" onClick={() => handleDeleteNote(note.id)}>Delete Note</div>
                  </div>
                </div>
                <div className="card-body card-scroll h-200px">
                  <span
                    className="text-gray-800 fw-normal mb-5"
                    dangerouslySetInnerHTML={{ __html: note.note }}
                  />
                </div>
                <div className="card-footer">
                  {note.replies.length > 0 ? (
                    note.replies.map((reply) => (
                      <div key={reply.id} className="col-md-12">
                        <div className="mb-10 bg-opacity-10 bg-primary">
                          <span className="text-gray-800 fs-7 fw-normal pt-1">
                            {reply.reply}
                          </span>
                          <div className="d-flex align-items-center">
                            <Button className="btn btn-sm bg-light-info" onClick={() => handleReplyModal(note)} >
                              <i className="fa-solid fa-reply"></i> Add Reply
                            </Button>
                            <Button className="btn btn-sm bg-light-warning" onClick={() => handleReplyModal(note, reply)} >
                              <i className="fa-solid fa-pen-to-square"></i> Edit Reply
                            </Button>
                            <Button className="btn btn-sm btn-light-danger" onClick={() => handleDeleteNoteReply(reply.id)}>
                              <i className="fa-solid fa-trash"></i> Delete Reply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="card-body pb-0">
                        <p className="text-gray-800 fw-normal mb-5">No Replies</p>
                      </div>
                      <Button variant="primary" onClick={() => handleReplyModal(note)}>Add Reply</Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="card mb-5 mb-xxl-8">
          <div className="card-body pb-0">
            <p className="text-gray-800 fw-normal mb-5">No Notes, please add one</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Notes;
