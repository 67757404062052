import React from 'react';

interface ConvertDateComponentProps {
  utcTimestamp: string;
}

const ConvertDateComponent: React.FC<ConvertDateComponentProps> = ({ utcTimestamp }) => {
  // Function to convert UTC timestamp to local date
  const convertUtcToLocal = (utcTimestamp: string): Date => {
    const date = new Date(utcTimestamp);
    return date;
  };

  // Convert the provided UTC timestamp to local date
  const localDate = convertUtcToLocal(utcTimestamp);

  // Format date to a readable string
  const formattedDate = localDate.toLocaleString();  // Converts Date object to string in the local date and time format

  return (
    <div>
      {formattedDate}
    </div>
  );
};

export default ConvertDateComponent;
