import React from 'react';
import Button from 'react-bootstrap/Button';

type Props = {
  subscription_status: string | null;
};

const SubscriptionButtonHelper: React.FC<Props> = ({
  subscription_status
}): React.ReactElement | null => {

  console.log('-- subscription  status  --');
  console.log(subscription_status);
  if (subscription_status === 'active') {
    return (
      <div>
        {/* Render your component UI here */}
        <Button variant="warning">
          <i className="fas fa-pause"></i> Pause Subscription
        </Button>
      </div>
    );
  } else if (subscription_status === 'pending') {
    return (
      <div>
        {/* Render your component UI here */}
        <Button variant="warning">
          <i className="fas fa-spinner"></i> Pending Subscription Approval
        </Button>
      </div>
    );

  } else if (subscription_status === 'cancelled') {
    return (
      <div>
        {/* Render your component UI here */}
        <Button variant="warning">
          <i className="fas fa-redo-alt"></i> Renew Subscription
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        {/* Render your component UI here */}
        <Button variant="primary">
          <i className="fas fa-plus"></i> Subscribe
        </Button>
      </div>
    );
  }
};

export default SubscriptionButtonHelper;