import React, { useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useAuth } from '../../../hooks/auth';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import 'react-datepicker/dist/react-datepicker.css';
import { Company, Subscription, Member } from './Companies';
import { useIntl, FormattedMessage } from 'react-intl';

type FormInputs = {
  name: string;
  address?: string;
  email?: string;
  website?: string;
  phone?: string;
  note?: string;
  status: string;
  company_id: number;
  original_admin_id: number;
};

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: Subscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: string;
  usage: string;
  check_limit: Boolean;
}

const AdminEditCompany: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<{ value: string; label: string } | null>(null);

  const { loading } = useAuth('auth');
  const { showError, showSuccess } = useToast();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue, getValues
  } = useForm<FormInputs>();

  const [note, setNote] = useState<string>('');
  const [pageLoading, setPageLoading] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  const fetchCompany = useCallback(() => {
    setPageLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        const companyData = response.data;
        setCompany(companyData);
        setValue('name', companyData.name);
        setValue('address', companyData.address);
        setValue('email', companyData.email);
        setValue('website', companyData.website);
        setValue('phone', companyData.phone);
        setValue('status', companyData.status);
        setValue('original_admin_id', companyData.original_admin_id);
        setNote(companyData.note);
      })
      .catch(() => showError(intl.formatMessage(
        {
          id: 'ERROR.FAILED_FETCH_COMPANY_DETAILS',
          defaultMessage: 'Failed to fetch company details'
        })))
      .finally(() => setPageLoading(false));
  }, [companyId, setValue, showError, intl]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post('/api/user/get-company-member-list', {
          'company_id': companyId
        });
        setUsers(response.data.data);
      } catch (error) {
        showError(intl.formatMessage({ id: 'ERROR.FAILED_FETCH_USERS', defaultMessage: 'Failed to fetch users' }));
        // Add "ERROR.FAILED_FETCH_USERS": "Failed to fetch users" to en.json
      }
    };
    fetchUsers();
    fetchCompany();
  }, [fetchCompany, showError, companyId, intl]);

  useEffect(() => {
    if (company && users.length > 0) {
      const preselectedUserId = company.original_admin_id;
      const preselectedUser = users.find(user => user.id === String(preselectedUserId));
      if (preselectedUser) {
        setSelectedAdmin({ value: preselectedUser.id, label: `${preselectedUser.name}, ${preselectedUser.email}` });
      } else {
        console.log('Preselected User not found');
      }
    }
  }, [users, company]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setPageLoading(true);
    data.note = note;
    data.company_id = parseInt(companyId);
    data.original_admin_id = getValues('original_admin_id');
    try {
      await axios.post('/api/companies/update', { ...data });
      showSuccess(intl.formatMessage({ id: 'SUCCESS.COMPANY_UPDATED', defaultMessage: 'Company updated successfully' }));
      // Add "SUCCESS.COMPANY_UPDATED": "Company updated successfully" to en.json
      navigate('/companies/admin-all-companies');
    } catch (error) {
      showError(intl.formatMessage({ id: 'ERROR.FAILED_UPDATE_COMPANY', defaultMessage: 'Failed to update company' }));
      // Add "ERROR.FAILED_UPDATE_COMPANY": "Failed to update company" to en.json
    } finally {
      setPageLoading(false);
    }
  };

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  const TitleBreadcrumb = () => {
    return intl.formatMessage(
      {
        id: 'COMPANY.ADMIN.ALL_COMPANIES',
      }
    )
  }

  const companyName = company?.name ?? '';
  const TitleSub = () => {
    return intl.formatMessage({
      id: 'COMPANY.EDIT_COMPANY',
    }
    ) + ' ' + companyName;
  }

  const companyBreadCrumbs: Array<PageLink> = [
    {
      title: TitleBreadcrumb(),
      path: '/companies/admin-all-companies',
      isSeparator: false,
      isActive: false,
    },
    {
      title: TitleSub(),
      path: '',
      isSeparator: true,
      isActive: true,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>
        <FormattedMessage id="COMPANY.EDIT_COMPANY" /> {' ' + companyName}
      </PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar">
            <a href="/companies/admin-all-companies" className="btn btn-sm btn-light-primary">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              <FormattedMessage id="GENERAL.BACK_TO_LIST" defaultMessage="Back to List" />
            </a>
          </div>
          <div className="card mb-2">
            <div className="card-header">
              <h3 className="card-title">
                <FormattedMessage id="COMPANY.EDIT_COMPANY_TITLE" />
              </h3>
            </div>
            <div className="card-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.COMPANY_NAME" defaultMessage="Company Name" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('name', { required: intl.formatMessage({ id: 'ERROR.COMPANY_NAME_REQUIRED', defaultMessage: 'Company name is required' }) })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.ADDRESS" defaultMessage="Address" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('address')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.EMAIL" defaultMessage="Email" />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    {...register('email', {
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: intl.formatMessage({ id: 'ERROR.INVALID_EMAIL', defaultMessage: 'Invalid email address' })
                      }
                    })}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.WEBSITE" defaultMessage="Website" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('website')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.PHONE" defaultMessage="Phone" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('phone')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.NOTES" defaultMessage="Notes" />
                  </Form.Label>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <ReactQuill
                        value={note}
                        onChange={(e) => { setNote(e); field.onChange(e); }}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.STATUS" defaultMessage="Status" />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    {...register('status', { required: intl.formatMessage({ id: 'ERROR.STATUS_REQUIRED', defaultMessage: 'Status is required' }) })}
                    isInvalid={!!errors.status}
                  >
                    <option value="">{intl.formatMessage({ id: 'FORM.SELECT_STATUS', defaultMessage: 'Select Status' })}</option>
                    <option value="active">{intl.formatMessage({ id: 'FORM.STATUS_ACTIVE', defaultMessage: 'Active' })}</option>
                    <option value="deactivated">{intl.formatMessage({ id: 'FORM.STATUS_DEACTIVATED', defaultMessage: 'Deactivated' })}</option>
                    <option value="dormant">{intl.formatMessage({ id: 'FORM.STATUS_DORMANT', defaultMessage: 'Dormant' })}</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.status?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="FORM.COMPANY_OWNER" defaultMessage="Company Owner" />
                  </Form.Label>
                  <Select
                    value={selectedAdmin}
                    options={users.map((user) => ({ value: user.id, label: `${user.name}, ${user.email}` }))}
                    onChange={(selectedOption) => {
                      setSelectedAdmin(selectedOption);
                      setValue('original_admin_id', Number(selectedOption?.value));
                    }}
                    placeholder={intl.formatMessage({ id: 'FORM.SELECT_OWNER', defaultMessage: 'Select Owner' })}
                  // Add "FORM.SELECT_OWNER": "Select Owner" to en.json
                  />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : <FormattedMessage id="SUBMIT_BTN" defaultMessage="Submit" />}
                </Button>
                <Button variant="secondary" type="button" className="btn btn-secondary" onClick={() => reset()}>
                  <FormattedMessage id="RESET_BTN" defaultMessage="Reset" />
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditCompany;
