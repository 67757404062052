import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { SubscriptionRequest, SubscriptionsRequestResponse } from './UserSubscriptionRequest';
import { useToast } from '../../components/ToastProvider';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { useAuth } from '../../../hooks/auth';
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';


const UserSubscriptionRequestsPage: React.FC = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();
  const { showError } = useToast();
  const [subscriptions, setSubscriptions] = useState<SubscriptionRequest[]>([]);
  const URL_SUBSCRIPTION_REQUESTS = '/api/subscription-requests/';
  const ADD_SUBSCRIPTION_PAGE = '/subscriptions/subscribe';
  const { loading } = useAuth('auth');
  const [searchParams, setSearchParams] = useState({
    id: '',
    name: '',
    email: '',
    organization_name: '',
    organization_phone: '',
    status: '',
    created_at: '',
    updated_at: ''
  });
  const requestBreadCrumb: Array<PageLink> = [
    {
      title: 'All Subscription Requests',
      path: '/subscriptions/user-subscription-request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  // Add search input fields and update handlers in the table headers
  const handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };
  const statusWrapper = (status: string) => {
    let classNameString = 'bg-light-' + getSeverity(status) + ' text-' + getSeverity(status);
    return (
      <div className={classNameString}>{status}</div>
    );
  };
  // Update useEffect to include search parameters
  useEffect(() => {
    const params = new URLSearchParams(searchParams).toString();
    axios.get<SubscriptionsRequestResponse>(`${URL_SUBSCRIPTION_REQUESTS}?${params}`)
      .then(response => {
        setSubscriptions(response.data.subscriptions);
        setDataLoading(false);
      })
      .catch(error => {
        console.error('Error fetching subscriptions:', error);
        showError('Failed to fetch subscription requests.');
        setDataLoading(false);
      });
  }, [searchParams]);


  const refreshPage = () => {
    setDataLoading(true);
    const params = new URLSearchParams(searchParams).toString();
    axios.get<SubscriptionsRequestResponse>(`${URL_SUBSCRIPTION_REQUESTS}?${params}`)
      .then(response => {
        setSubscriptions(response.data.subscriptions);
        setDataLoading(false);
      })
      .catch(error => {
        console.error('Error fetching subscriptions:', error);
        showError('Failed to fetch subscription requests.');
        setDataLoading(false);
      });
  };


  const getSeverity = (status: string) => {
    return {
      'pending': 'warning',
      'rejected': 'danger',
      'approved': 'success'
    }[status] || 'info';
  };

  const statusBodyTemplate = (rowData: SubscriptionRequest) => {
    const severity = getSeverity(rowData.status);
    const classNameString = 'badge badge-light-' + severity;
    return (
      <div><span className={classNameString}>{rowData.status}</span></div>

    );
  }



  const navigateSubscriptionPage = () => navigate(ADD_SUBSCRIPTION_PAGE);

  const viewPageButtonTemplate = (rowData: SubscriptionRequest) => (
    <Button onClick={() => navigate(`/subscription-requests/view`, {
      state: { subscriptionRequestId: rowData.id }
    })} >
      View Page
    </Button>
  );

  const header = (
    <div className="flex justify-content-end gap-2">
      <Button onClick={navigateSubscriptionPage} ><i className='fa fa-plus'></i>Create Subscription Request</Button>
    </div>
  );


  const Title = () => {
    return (
      'Subscription Requests Created By You'
    );
  };

  return (
    <>
      {dataLoading ? (<LoadingSpinner />) : (
        <>
          <PageTitle breadcrumbs={requestBreadCrumb}>{Title()}</PageTitle>
          <KTCard>

            <div className="card-header">
              <h1 className="card-title">User Subscription Requests</h1>
              <div className="card-toolbar">
                <Button type="button" className="btn btn-sm btn-sucess" onClick={() => refreshPage()}>
                  <i className='fa fa-refresh'></i>

                </Button>
                <Button type="button" className="btn btn-sm btn-primary" onClick={navigateSubscriptionPage}>
                  <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
                  Add Subscription Request
                </Button>
              </div>
            </div>
            <KTCardBody>
              {(loading || dataLoading) ? <LoadingSpinner /> : (
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th><input type="text" className="form-control" placeholder="Search ID" name="id" value={searchParams.id} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Name" name="name" value={searchParams.name} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Email" name="email" value={searchParams.email} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Organization" name="organization_name" value={searchParams.organization_name} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Phone" name="organization_phone" value={searchParams.organization_phone} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Status" name="status" value={searchParams.status} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Created At" name="created_at" value={searchParams.created_at} onChange={handleSearchChange} /></th>
                        <th><input type="text" className="form-control" placeholder="Search Updated At" name="updated_at" value={searchParams.updated_at} onChange={handleSearchChange} /></th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map((subscription) => (
                        <tr key={subscription.id}>
                          <td>{subscription.id}</td>
                          <td>{subscription.name}</td>
                          <td>{subscription.email}</td>
                          <td>{subscription.organization_name}</td>
                          <td>{subscription.organization_phone}</td>
                          <td>{statusWrapper(subscription.status)}</td>
                          <td>{subscription.created_at}</td>
                          <td>{subscription.updated_at}</td>
                          <td>
                            <div className='btn-group'>
                              {
                                subscription.status !== 'approved' ?

                                  (
                                    <>
                                      <Button
                                        className="btn btn-sm btn-success"
                                        onClick={() => navigate('/subscriptions/edit-subscription-request', {
                                          state: { subscriptionRequestId: subscription.id }
                                        })}
                                      >

                                        <i className='fa fa-pen'></i>
                                      </Button>
                                    </>
                                  )

                                  :
                                  (null)
                              }

                              <Button
                                className="btn btn-sm btn-info"
                                onClick={() => navigate('/subscriptions/user-view-subscription-request', {
                                  state: { subscriptionRequestId: subscription.id }
                                })}
                              >
                                <i className='fa fa-eye'></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </KTCardBody>
          </KTCard>
        </>
      )
      }
    </>
  );
};

export default UserSubscriptionRequestsPage;
