import React from 'react';
import clsx from 'clsx'

const SME_IDENTIFIER = 'SME';
const CORPORATE_IDENTIFIER = 'Corporate';
interface ResultButtonExternalProps {
  buttonText: string;
  onClick: () => void;
  CompanyInformation: ICompanyInformation;
  pageLoading: boolean;
  buttonDisabled: boolean;
}

interface ICompanyInformation {
  financial_year?: string
  three_financial_years: boolean
  company_name?: string
  register_number?: string
  incorporate_date?: string
  bumiputera_status?: string
  business_type?: string
  company_classification?: string
  company_classification_c?: string
  status_c?: string
}


function isButtonDisabled(companyInfo: ICompanyInformation): boolean {

  if (companyInfo.company_classification_c === SME_IDENTIFIER && companyInfo.status_c === 'retrieved') {
    return false;
  } else if (companyInfo.company_classification_c === SME_IDENTIFIER && companyInfo.status_c === 'created') {

    return true;
  } else if (companyInfo.company_classification_c === SME_IDENTIFIER && companyInfo.status_c === 'result') {

    return false;
  } else if (companyInfo.company_classification_c === CORPORATE_IDENTIFIER && companyInfo.status_c === 'result') {
    return true;

  } else if (companyInfo.company_classification_c === CORPORATE_IDENTIFIER && companyInfo.status_c === 'created') {
    return true;

  } else if (companyInfo.company_classification_c === CORPORATE_IDENTIFIER && companyInfo.status_c === 'retrieved') {
    return false;

  } else {
    return true;

  }
}
const ResultButtonExternal: React.FC<ResultButtonExternalProps> = ({
  buttonText,
  onClick,
  CompanyInformation,
  pageLoading,
  buttonDisabled }) => {

  if (buttonDisabled) {
    return (
      <button
        disabled={true}
        className='btn btn-success'
        onClick={onClick}
      >
        <span
          className={clsx('spinner-border', 'spinner-border-sm', {
            'visually-hidden': !pageLoading,
          })}
        ></span>
        {buttonText}
      </button>
    );
  } else {
    return (
      <button
        disabled={isButtonDisabled(CompanyInformation)}
        className='btn btn-success'
        onClick={onClick}
      >
        <span
          className={clsx('spinner-border', 'spinner-border-sm', {
            'visually-hidden': !pageLoading,
          })}
        ></span>
        {buttonText}
      </button>
    );
  }

};

export default ResultButtonExternal;