import React from 'react'
import {IRequestComputeForm} from '../model'
import {ICompanyFinancialAnalysis} from '../model/SmeModelModel'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {numericFormatter} from 'react-number-format'

type TProps = {
  name?: string
  label?: string
  type?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
  value?: IRequestComputeForm | ICompanyFinancialAnalysis
  error?: string
  required?: boolean
  disabled?: boolean
  onchange?: (
    value: string | number,
    param1: string,
    param2: string,
    param3?: string,
    param4?: string
  ) => void
  tooltip?: any
  list?: any
  long?: boolean
}

export function TransposeLineInput({
  name,
  label,
  type,
  param1,
  param2,
  param3,
  param4,
  value,
  error,
  required,
  disabled,
  onchange,
  tooltip,
  list,
  long,
}: TProps) {
  const styles = {
    inputNumberStyle: {
      textAlign: 'right' as const,
    },
    inputDefaultStyle: {},
  }

  const getValue = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return value![p1][p2][p3][p4] || ''
    } else if (p1 && p2 && p3) {
      return value![p1][p2][p3] || ''
    } else if (p1 && p2) {
      return value![p1][p2] || ''
    } else if (p1) {
      return value![p1] || ''
    }
  }

  const getName = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return p1 + '.' + p2 + '.' + p3 + '.' + p4
    } else if (p1 && p2 && p3) {
      return p1 + '.' + p2 + '.' + p3
    } else if (p1 && p2) {
      return p1 + '.' + p2
    } else if (p1) {
      return p1
    }
  }

  const formatValue = (value) => {
    if (value === '-') {
      return value
    }
    if (value === '.') {
      return value
    }
    //check if the value end with '.'
    if (value.toString().endsWith('.')) {
      return value
    }

    return numericFormatter(Number(value) ? Number(value).toString() : '0', {
      thousandSeparator: ',',
      decimalScale: 2,
      fixedDecimalScale: false,
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, type: string | undefined) => {
    if (type === 'number') {
      // List of key codes for arrow keys
      const arrowKeys = ['ArrowUp', 'ArrowDown']

      // Check if the pressed key is an arrow key
      if (arrowKeys.includes(e.key)) {
        // Prevent the default behavior
        e.preventDefault()
      }
    }
  }

  return (
    <>
      {/*can add class required here later*/}
      <td className='transpose-table'>
        <div className='row'>
          {list === 0 && (
            <label
              className={clsx('col-lg-6', 'col-form-label', 'fw-bold', 'fs-6', {
                'bg-light-primary': disabled,
                required: required,
                'pt-4': !long,
                'pt-0': long,
              })}
              style={{whiteSpace: 'normal'}}
            >
              {label}
              {tooltip && (
                <>
                  <OverlayTrigger
                    placement='right'
                    overlay={
                      <Tooltip id='tooltip-right' className='fs-8'>
                        {tooltip}
                      </Tooltip>
                    }
                  >
                    <span className=''>
                      <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            </label>
          )}
          <div className={clsx('', {'col-lg-6': list === 0})}>
            <input
              id={getName(param1, param2, param3, param4)}
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                type === 'number'
                  ? formatValue(getValue(param1!, param2!, param3!, param4!))
                  : getValue(param1!, param2!, param3!, param4!)
              }
              required={required}
              disabled={disabled}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d.-]/g, '')
                if (value === '') {
                  value = '0'
                }
                onchange!(value, param1!, param2!, param3!, param4!)
              }}
              onKeyDown={(e) => handleKeyDown(e, type)}
              onWheel={(e) => e.currentTarget.blur()}
              onFocus={(e) => e.target.value === '0' && e.currentTarget.select()}
              style={type === 'number' ? styles.inputNumberStyle : styles.inputDefaultStyle}
            />
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{error}</div>
            </div>
          </div>
        </div>
      </td>
    </>
  )
}
