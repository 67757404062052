import React from 'react';
import { useIntl } from 'react-intl';

const AsideLoading: React.FC = () => {
  const intl = useIntl();
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">
          {intl.formatMessage({ id: 'EDIT_SUBSCRIPTION_REQUEST_LOADING' })}

        </span>
      </div>
    </div>
  );
};

export default AsideLoading;