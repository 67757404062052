import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

interface SubmitButtonExternalProps {
    loading: boolean;
    companyStatus?: string;
    onClick: () => void;
}

const SubmitButtonExternal: React.FC<SubmitButtonExternalProps> = ({
    loading,
    companyStatus,
    onClick,
}) => {
    const intl = useIntl();
    console.log('SubmitButtonExternalProps', loading, companyStatus);

    // State to manage when the button should be enabled
    const [isReady, setIsReady] = useState(false);

    // Example condition to enable the button - adapt this logic to your needs
    useEffect(() => {
        // Example condition: enable the button after some condition is met
        // Replace this with your actual condition
        const timer = setTimeout(() => setIsReady(true), 1000); // Example: enable after 1 second

        return () => clearTimeout(timer);
    }, []);

    const checkCompanyStatus = (status: string): boolean => {
        if (status === 'retrieved') {
            return true;
        } else if (status === 'processing') {
            return true;
        } else if (status === 'created') {
            return false;
        }
        return false;
    }

    const theButtonDisabled = !isReady || buttonDisabled(loading, checkCompanyStatus(companyStatus || ''));

    const label = intl.formatMessage({ id: 'GENERAL.SAVE' });
    const tooltip = theButtonDisabled ? 'Submit disabled' : 'Save';

    return (
        <button
            disabled={theButtonDisabled}
            className='btn btn-primary mb-4'
            onClick={onClick}
            title={tooltip}
        >
            <span
                className={clsx('spinner-border spinner-border-sm', {
                    'visually-hidden': !loading,
                })}
            ></span>
            {label}
        </button>
    );
};

const buttonDisabled = (loading: boolean, disabled: boolean): boolean => {
    return disabled || loading;
};

export default SubmitButtonExternal;
