import {useEffect, useState} from 'react'
import {Link, NavLink, useParams} from 'react-router-dom'
import {useAuth} from '../../../../hooks/auth'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
}
export function Login() {
  const intl = useIntl()
  const {login} = useAuth('guest', '/dashboard')
  const params = useParams()
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(null)
  const [PageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
  })

  useEffect(() => {
    if (params.reset) {
      setStatus(atob(params.reset!))
    }
  }, [params.reset])

  const submitForm = async (event) => {
    event.preventDefault()
    login({email, password, setErrors, setStatus, setLoading})
  }

  return (
    <>
      <form onSubmit={submitForm}>
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: 'GENERAL.SIGN_IN' })}</h1>
        </div>
        {/* end::Heading */}

        {errors?.length > 0 ? (
          <div className='mb-lg-15 alert alert-danger'>
            {errors?.map((error) => (
              <div className='alert-text font-weight-bold' key={error}>
                {intl.formatMessage({ id: error})}
              </div>
            ))}
          </div>
        ) : (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{status}</div>
            <div className='text-info'>{intl.formatMessage({ id: 'GENERAL.PLEASE_ENTER_USERNAME_AND_PASSWORD' })}</div>
          </div>
        )}

        {/* Email Address */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark' htmlFor='email'>
          {intl.formatMessage({ id: 'GENERAL.EMAIL' })}
          </label>
          <input
            id='email'
            type='email'
            value={email}
            className='form-control bg-transparent'
            onChange={(event) => setEmail(event.target.value)}
            required
            autoFocus
          />
        </div>
        {/* Password */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0' htmlFor='password'>
          {intl.formatMessage({ id: 'GENERAL.PASSWORD' })}
          </label>
          <input
            id='password'
            type='password'
            value={password}
            className='form-control bg-transparent'
            onChange={(event) => setPassword(event.target.value)}
            required
            autoComplete='current-password'
          />
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          {/* Remember Me */}
          <div className='form-check form-check-custom form-check-solid form-check-lg'>
            <input id='remember_me' type='checkbox' name='remember' className='form-check-input' />
            <label htmlFor='remember_me' className='form-check-label fw-bolder text-dark fs-6 mb-0'>
              <span className='ml-2 text-sm text-gray-600'>{intl.formatMessage({ id: 'GENERAL.REMEMBER_ME' })}</span>
            </label>
          </div>

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({ id: 'GENERAL.FORGOT_PASSWORD' })}
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}
        <div className='d-grid mb-10'>
          <button className={clsx('btn btn-primary', {disabled: loading})}>
            <span
              className={clsx('spinner-border', 'spinner-border-sm', {
                'visually-hidden': !loading,
              })}
            ></span>
            {intl.formatMessage({ id: 'GENERAL.LOGIN' })}
          </button>
        </div>
      </form>
    </>
  )
}
