import React, { useEffect, useReducer, useCallback, useMemo } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../../hooks/auth'
import axios from '../../../lib/axios'
import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import clsx from 'clsx'
import ListLoading from './components/ListLoading'
import SearchFilterDropDown from './components/SearchFilterDropDown'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useToast } from '../../components/ToastProvider'
import LoadingOverlay from '../corporatemodel/components/compute/components/LoadingOverlay';
import { SME_MODEL_REPORT_EXTERNAL, SME_MODEL_REPORT_INTERNAL, REPORT_TYPE_MAP } from '../../settings/constants'
import { getUsageCountByProductKey } from '../../../util/checkAccessUtil'
import { useIntl } from 'react-intl'

type Report = {
  data: ReportData[]
  links: ReportLinks[]
  current_page: number
  last_page: number
  per_page: number
  total: number
}

type ReportData = {
  sme_model_no: string
  report_type: string
  financial_year: string
  register_number: string
  incorporate_date: string
  bumiputera_status: string
  business_type: string
  company_classification_c: string
  status_c: string
  company_name: string
  created_at: string
  updated_at: string
  name: string
}

type ReportLinks = {
  label: string
  url: string
  active: boolean
}

async function getReports(page = 1, column = '', search = ''): Promise<Report> {
  let searchURI = ''
  if (column !== '' && search !== '') {
    searchURI = `&column=${column}&q=${search}`
  }

  const url = `/api/model/sme/list?page=${page}${searchURI}`
  const response = await axios.get(url)
  return response.data.data
}

async function createReport(reportType: string): Promise<string> {
  try {

    /**
     *   const response = await axios.post(url, {
      report_type: reportType,
      product_identifier: CORPORATE_MODEL_REPORT,
    });
     */
    const url = '/api/model/sme/init'
    const response = await axios.post(
      url, {
      report_type: reportType,
      product_identifier: getProductIdentifier(reportType)
    }
    )

    if (response.status !== 200) {
      throw new Error(`Request failed with status code ${response.status}`)
    }

    const smeModelNo = response.data?.data?.sme_model_no

    if (!smeModelNo) {
      throw new Error('sme_model_no not found in response data')
    }

    return smeModelNo
  } catch (error) {
    console.error(error)
    throw error
  }
}


function getProductIdentifier(reportType: string) {
  return reportType === 'external' ? SME_MODEL_REPORT_EXTERNAL : SME_MODEL_REPORT_INTERNAL;
}



function reducer(state, action) {
  switch (action.type) {
    case 'SET_SPLIT_STATE':
      return { ...state, subscriptionUsageIsSplit: action.payload }
    case 'SET_REPORTS':
      return { ...state, reports: action.payload }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_SEARCH':
      return { ...state, search: action.payload }
    case 'SET_FILTER':
      return { ...state, filter: action.payload.filter, column: action.payload.column }
    case 'SET_USAGE':
      return {
        ...state,
        usageLimit: action.payload.usageLimit,
        usageValue: action.payload.usageValue,
        usageUnlimited: action.payload.usageUnlimited,
      }
    case 'SET_TITLE_BUTTON':
      return { ...state, titleButton: action.payload }
    default:
      return state
  }
}

export function SmeModel() {
  const PRODUCT_IDENTIFIER_EXTERNAL = REPORT_TYPE_MAP.sme_model;
  const PRODUCT_IDENTIFIER_INTERNAL = REPORT_TYPE_MAP.CreditMapper_report_2;
  const intl = useIntl()
  const { showError } = useToast()
  const { user, userCan } = useAuth('auth')
  const navigate = useNavigate()
  const initialState = {
    reports: {
      data: [],
      links: [],
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
    },
    subscriptionUsageIsSplit: false,
    loading: false,
    search: '',
    filter: intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' }),
    column: 'company_name',
    usageLimit: 0,
    usageValue: 0,
    usageUnlimited: false,
    titleButton: '',
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchReports = useCallback(async (page = 1, column = '', search = '') => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const reports = await getReports(page, column, search)
      dispatch({ type: 'SET_REPORTS', payload: reports })
    } catch (error) {
      showError('Error fetching reports')
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }, [showError])

  const handleViewDetails = useCallback((sme_model_no: string, report_type: string) => {
    const path = report_type === 'internal' ? '/sme-model-main/compute' : '/sme-model-main/compute-ex'
    navigate(path, { state: { sme_model_no } })
  }, [navigate])

  const handleCreateReport = useCallback(async (reportType: string) => {
    try {
      const sme_model_no = await createReport(reportType)
      fetchReports()
      handleViewDetails(sme_model_no, reportType)
    } catch (error) {
      showError('Error creating report')
    }
  }, [fetchReports, handleViewDetails, showError])

  const handleTriggerSearch = useCallback(() => {
    if (state.filter !== 'Filter' && state.search !== '') {
      fetchReports(1, state.column, state.search)
    }
  }, [state.filter, state.search, state.column, fetchReports])

  const handleReset = useCallback(() => {
    dispatch({ type: 'SET_FILTER', payload: { filter: intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' }), column: 'company_name' } })
    dispatch({ type: 'SET_SEARCH', payload: '' })
    fetchReports()

  }, [fetchReports])

  const handlePage = useCallback((page: number) => {
    fetchReports(page, state.column, state.search)
  }, [fetchReports, state.column, state.search])

  useEffect(() => {
    if (user && user?.role !== 'Super Admin') {
      if (user?.limit.is_split === true) {
        dispatch({ type: 'SET_SPLIT_STATE', payload: true })
      }
      if (user.limit.is_split === false) {
        dispatch({ type: 'SET_USAGE', payload: { usageLimit: user.limit.total_limit, usageValue: getUsageCountByProductKey(user, PRODUCT_IDENTIFIER_EXTERNAL) ?? 0, usageUnlimited: user.usage_is_unlimited } })
      } else {
        dispatch({ type: 'SET_USAGE', payload: { usageLimit: user.limit.own_limit, usageValue: getUsageCountByProductKey(user, PRODUCT_IDENTIFIER_EXTERNAL) ?? 0, usageUnlimited: user.usage_is_unlimited } })
      }
    }
  }, [user, PRODUCT_IDENTIFIER_EXTERNAL])

  useEffect(() => {
    if (user?.is_company_admin === true) {


      if (state.usageUnlimited === false) {

        dispatch({ type: 'SET_TITLE_BUTTON', payload: `${intl.formatMessage({ id: 'GENERAL.TOTAL_USAGE' })} ${user?.usage.usage_all}/${user?.limit.total_limit} \r\n ${intl.formatMessage({ id: 'GENERAL.YOUR_PERSONAL_USAGE' })} ${state.usageValue}/${state.usageLimit}` })
      } else if (state.usageUnlimited === true) {


        dispatch({ type: 'SET_TITLE_BUTTON', payload: `${intl.formatMessage({ id: 'GENERAL.TOTAL_USAGE' })} ${user?.usage.usage_all}  \r\n  ${intl.formatMessage({ id: 'GENERAL.YOUR_PERSONAL_USAGE' })} ${state.usageValue}.` })
      }
    } else if (user?.is_company_admin === false) {

      if (state.usageUnlimited === false) {

        dispatch({ type: 'SET_TITLE_BUTTON', payload: `${intl.formatMessage({ id: 'GENERAL.YOUR_PERSONAL_USAGE' })} ${state.usageValue}/${state.usageLimit}` })
      } else if (state.usageUnlimited === true) {

        dispatch({ type: 'SET_TITLE_BUTTON', payload: `${intl.formatMessage({ id: 'GENERAL.YOUR_PERSONAL_USAGE' })} ${state.usageValue}` })
      }
    }
  }, [user, state.usageValue, state.usageLimit, state.usageUnlimited])

  useEffect(() => {
    fetchReports()
  }, [fetchReports])

  const renderButtonInternal = useMemo(() => {
    if (!user || !user.role) {
      return null
    }
    if (userCan('Access Internal SME Model Report') && user.role === 'Data Analyst' && user?.mode === 'personal') {
      console.log('  render button condition A')

      return (
        <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('internal')}>
          {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
        </button>
      )
    }

    console.log(' no condition rendeer ')

    return null
  }, [user, userCan, handleCreateReport])
  const renderButtonExternal = useMemo(() => {
    console.log('Rendering External Button');
    console.log('User:', user);

    const isUsageLimitReached = user?.usage_is_unlimited === true || (state.usageValue === state.usageLimit && state.usageLimit !== 0);
    const isUsageBelowLimit = state.usageUnlimited || (state.usageValue !== undefined && state.usageValue < (state.usageLimit ?? 0) && state.usageLimit !== 0);
    const isSubscriptionActive = user?.subscription_active;
    const isRoleUserRegular = user?.role === 'User Regular' && user?.mode === 'organization';
    const isRoleUserData = user?.role === 'Data Analyst' && user?.mode === 'organization';

    console.log('Conditions:', {
      isUsageLimitReached,
      isUsageBelowLimit,
      isSubscriptionActive,
      isRoleUserRegular,
      isRoleUserData,
      canAccessExternalReport: userCan('Access External SME Model Report')
    });

    if (userCan('Access External SME Model Report') && user?.role === 'Super Admin') {
      console.log('Rendering for Super Admin');
      return (
        <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
          {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
        </button>
      );
    }

    {/* change allow user data analyst  to create model  */ }

    if (isRoleUserData === true) {
      console.log('User is Regular or Data Analyst with correct permission');

      if (user?.usage_is_unlimited === false) {
        console.log('Usage is not unlimited');

        if (isUsageBelowLimit && isSubscriptionActive) {
          console.log('Rendering for usage below limit and active subscription');
          return (
            <>
              <button className='btn btn-info btn-sm' onClick={() => handleCreateReport('external')}>
                {state.titleButton}
              </button>
              <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
                {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (isUsageLimitReached && isSubscriptionActive) {
          console.log('Rendering for usage limit reached');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-danger btn-sm' disabled>
                Limit Reached<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (state.usageLimit === 0 && isSubscriptionActive) {
          console.log('Rendering for usage limit 0 and active subscription');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
                {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (isUsageBelowLimit && !isSubscriptionActive) {
          console.log('Rendering for usage below limit but inactive subscription');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-danger btn-sm' disabled>
                Subscription Issue<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }
      } else if (user?.usage_is_unlimited === true) {
        console.log('Rendering for unlimited usage');
        return (
          <>
            <button className='btn btn-info btn-sm me'>
              {state.titleButton}
            </button>
            <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
              {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
            </button>
          </>
        );
      }


    }
    {/* change allow user data analyst  to create model  */ }





    if (userCan('Access External SME Model Report')) {
      console.log('User is Regular or Data Analyst with correct permission');

      if (user?.usage_is_unlimited === false) {
        console.log('Usage is not unlimited');

        if (isUsageBelowLimit && isSubscriptionActive) {
          console.log('Rendering for usage below limit and active subscription');
          return (
            <>
              <button className='btn btn-info btn-sm' onClick={() => handleCreateReport('external')}>
                {state.titleButton}
              </button>
              <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
                {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (isUsageLimitReached && isSubscriptionActive) {
          console.log('Rendering for usage limit reached');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-danger btn-sm' disabled>
                Limit Reached<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (state.usageLimit === 0 && isSubscriptionActive) {
          console.log('Rendering for usage limit 0 and active subscription');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
                {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }

        if (isUsageBelowLimit && !isSubscriptionActive) {
          console.log('Rendering for usage below limit but inactive subscription');
          return (
            <>
              <button className='btn btn-info btn-sm me'>
                {state.titleButton}
              </button>
              <button className='btn btn-danger btn-sm' disabled>
                Subscription Issue<i className='ki-duotone ki-plus fs-2'></i>
              </button>
            </>
          );
        }
      } else if (user?.usage_is_unlimited === true) {
        console.log('Rendering for unlimited usage');
        return (
          <>
            <button className='btn btn-info btn-sm me'>
              {state.titleButton}
            </button>
            <button className='btn btn-success btn-sm' onClick={() => handleCreateReport('external')}>
              {intl.formatMessage({ id: 'GENERAL.CREATE' })}<i className='ki-duotone ki-plus fs-2'></i>
            </button>
          </>
        );
      }
    }

    console.log('No conditions met, returning null');
    return null;
  }, [state.usageValue, state.usageLimit, user, userCan, handleCreateReport, state.titleButton, state.usageUnlimited]);

  const smeModelBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'SME_M.SME_MODEL_LIST' }),
      path: '/sme-model',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (


    <>


      {user?.mode === 'organization' && user?.role === 'Data Analyst' && (
        <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
          <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
            <h5 className="mb-1">Hi, you are on organization mode</h5>
            <span>Currently you are consuming subscription under organization, to use personal subscription toggle mode to personal.</span>
          </div>
        </div>
      )}

      <PageTitle breadcrumbs={smeModelBreadCrumbs}>{intl.formatMessage({ id: 'SME_M.SME_MODEL_LIST' })}</PageTitle>
      {
        user?.is_company_admin === true ? (<div className="d-flex align-items-center rounded py-5 px-5 bg-light-success"><span className="svg-icon svg-icon-3x svg-icon-danger me-5"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect></svg></span><div className="text-gray-700 fw-bold fs-6">{intl.formatMessage({ id: 'INFO.YOU_ARE_COMPANY_ADMIN' })}</div></div>) : null
      }
      <div className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <div className='d-flex justify-content-end'>
              <SearchFilterDropDown filter={state.filter} onclick={(column, filter) => { dispatch({ type: 'SET_FILTER', payload: { filter, column } }) }} />
              <input
                type='text'
                value={state.search}
                className='form-control form-control-solid w-250px ms-2'
                placeholder={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
                onChange={(e) => dispatch({ type: 'SET_SEARCH', payload: e.target.value })}
              />
              <a href='#' className='btn btn-sm btn-light-primary ms-2' onClick={handleTriggerSearch}>
                <KTIcon iconName='search-list' className='fs-2' />
              </a>
              <a href='#' className='btn btn-sm btn-light-primary ms-2' onClick={handleReset}>
                <KTIcon iconName='arrows-circle' className='fs-2' />
              </a>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id='tooltip-right' className='fs-8'>
                    • {intl.formatMessage({ id: 'GENERAL.PICK_FILTER' })}
                    <br />
                    • {intl.formatMessage({ id: 'GENERAL.KEY_IN_YOUR_SEARCH' })}
                    <br />• {intl.formatMessage({ id: 'GENERAL.CLICK' })}
                    <span className='badge badge-light-danger'>
                      <KTIcon iconType='solid' iconName='magnifier' className='fs-8' />
                    </span>
                    {intl.formatMessage({ id: 'GENERAL.TO_SEARCH' })}
                    <br />• {intl.formatMessage({ id: 'GENERAL.CLICK' })}
                    <span className='badge badge-light-danger'>
                      <KTIcon iconType='solid' iconName='arrows-circle' className='fs-8' />
                    </span>
                    {intl.formatMessage({ id: 'GENERAL.TO_RESET' })}
                  </Tooltip>
                }
              >
                <span className='ms-2'>
                  <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                </span>
              </OverlayTrigger>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end'>
              <div className='btn-group me-3'>
                {renderButtonInternal}

                {renderButtonExternal}
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start fw-bolder fs-7 gs-0'>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.REPORT_NO' })}</th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' })}</th>
                  <th className='min-w-100px'>{intl.formatMessage({ id: 'GENERAL.COMPANY_REGISTRATION_NO' })}</th>
                  <th className='min-w-80px'>{intl.formatMessage({ id: 'GENERAL.FINANCIAL_YEAR' })}</th>
                  <th className='min-w-80px'>
                    {intl.formatMessage({ id: 'GENERAL.STATUS' })}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-right' className='fs-8 text-left'>
                          <ul className='mb-0 py-0 px-3'>
                            <li>
                              <span className='badge badge-secondary'>CREATED</span> {intl.formatMessage({ id: 'GENERAL.CREATED_DESCRIPTION' })}
                            </li>
                            <li>
                              <span className='badge badge-success'>RESULT</span> {intl.formatMessage({ id: 'GENERAL.RESULT_DESCRIPTION' })}
                            </li>
                            <li>
                              <span className='badge badge-primary'>RETRIEVED</span> {intl.formatMessage({ id: 'GENERAL.RETRIEVED_DESCRIPTION' })}
                            </li>
                          </ul>
                        </Tooltip>
                      }
                    >
                      <span className='ms-2'>
                        <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.CREATE_DATE' })}</th>
                  <th className='min-w-200px'>{intl.formatMessage({ id: 'GENERAL.CREATED_BY' })}</th>
                </tr>
              </thead>
              <tbody>
                {state.reports.data.length > 0 ? (
                  state.reports.data.map((report: ReportData) => (
                    <tr key={report.sme_model_no}>
                      <td>
                        <span
                          style={{ cursor: 'pointer', fontFamily: 'monospace', fontSize: '1.1rem' }}
                          className='badge badge-light fw-bold'
                          onClick={() => handleViewDetails(report.sme_model_no, report.report_type)}
                        >
                          {report.sme_model_no}
                        </span>
                      </td>
                      <td>{report.company_name}</td>
                      <td>{report.register_number}</td>
                      <td>{report.financial_year}</td>
                      <td>
                        <span
                          className={clsx('badge', {
                            'badge-secondary': report.status_c === 'created',
                            'badge-success': report.status_c === 'result',
                            'badge-primary': report.status_c === 'retrieved',
                          })}
                        >
                          {report.status_c.toUpperCase()}
                        </span>
                      </td>
                      <td>{new Date(report.created_at).toLocaleString()}</td>
                      <td>{report.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'GENERAL.NO_RECORDS' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {state.loading && <ListLoading /> && <LoadingOverlay />}
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div id='kt_table_users_paginate'>
                <ul className='pagination'>
                  <li className={clsx('page-item', { disabled: state.reports.current_page === 1 })}>
                    <button
                      className='page-link'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.FIRST' })}
                    </button>
                  </li>
                  <li className={clsx('page-item', { disabled: state.reports.current_page === 1 })}>
                    <button
                      className='page-link previous'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(state.reports.current_page - 1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.PREVIOUS' })}
                    </button>
                  </li>
                  {Array(state.reports.last_page)
                    .fill(1)
                    .map((_, i) => (
                      <li
                        key={i}
                        className={clsx('page-item', { active: state.reports.current_page === i + 1 })}
                      >
                        <button
                          className='page-link'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handlePage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                  <li className={clsx('page-item', {
                    disabled: state.reports.last_page === state.reports.current_page,
                  })}>
                    <button
                      className='page-link next'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(state.reports.current_page + 1)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.NEXT' })}
                    </button>
                  </li>
                  <li className={clsx('page-item', {
                    disabled: state.reports.last_page === state.reports.current_page,
                  })}>
                    <button
                      className='page-link'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePage(state.reports.last_page)}
                    >
                      {intl.formatMessage({ id: 'GENERAL.LAST' })}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
