import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import { SubscribedProduct } from '../ProductUsage';


interface ProductCardProps {
  product: SubscribedProduct;
  subscription_id: string;
  is_parent: boolean;
  makeUsageUnlimited(subscriptionId: string): void;
  removeProduct(product: SubscribedProduct): void;
  reactivateProductLink(product: SubscribedProduct): void;
  navigateAdminSetSubscriptionLimit(): void;
}

const formatDescription = (description: string): React.ReactNode => {
  if (!description) return null;

  const formattedDescription = description
    .replace(/\n/g, '<br>')
    .replace(/\\n/g, '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

  return (
    <div style={{ fontSize: '0.8rem' }} dangerouslySetInnerHTML={{ __html: formattedDescription }} />
  );
};

const ProductAttachCardComponent: React.FC<ProductCardProps> = ({
  product,
  subscription_id,
  makeUsageUnlimited,
  removeProduct,
  is_parent,
  reactivateProductLink,
  navigateAdminSetSubscriptionLimit
}) => {


  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">{product.product.name}</h5>
        <p className="card-text">
          <strong>Price:</strong> {product.product.price} MYR
        </p>
        <p className="card-text">
          <strong>Description:</strong> {formatDescription(product.product.description)}
        </p>
        <p className="card-text">
          <strong>Status:</strong> {product.status}
        </p>
        <p className="card-text">
          <strong>Subscription Period:</strong> {product.product.subscription_period}
        </p>
        <p className="card-text">
          <strong>Usage:</strong> {product.usage.usage} / {product.usage.limit || 'Unlimited'}
        </p>
        <p className="card-text">
          <strong>Usage Description:</strong> {product.usage.description}
        </p>
        <p className="card-text">
          <strong>Is Consumed:</strong> {product.is_consumed ? 'Yes' : 'No'}
        </p>
        <p className="card-text">
          <strong>Is Unlimited:</strong> {product.is_unlimited ? 'Yes' : 'No'}
        </p>
        <div className="mt-3">
          {product.status === 'active' ? (
            <Button variant="danger" onClick={() => removeProduct(product)}>
              <i className="fa-regular fa-square-minus"></i> Remove From Subscription
            </Button>
          ) : (
            <Button variant="primary" onClick={() => reactivateProductLink(product)}>
              <i className="fa-solid fa-paperclip"></i> Reattach Product Subscription
            </Button>
          )}

          {product.is_unlimited ? (
            <Button variant="warning" onClick={navigateAdminSetSubscriptionLimit}>
              Make Subscription Limited
            </Button>
          ) : is_parent ? (
            <Button variant="success" onClick={() => makeUsageUnlimited(subscription_id)}>
              Make Subscription Unlimited
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductAttachCardComponent;
