import React from 'react'
import { useNavigate } from 'react-router'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

function LinkExpired() {
    const navigate = useNavigate()
    
    const handleClick = () => {
        navigate('/verify-email')
    }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <>
                {/* begin::Title */}
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Link Expired!</h1>
                {/* end::Title */}

                {/* begin::Text */}
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                  Please request a new link.
                </div>
                {/* end::Text */}

                {/* begin::Illustration */}
                <div className='mb-3'>
                  <img
                    src={toAbsoluteUrl('/media/auth/chart-graph.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                  <img
                    src={toAbsoluteUrl('/media/auth/chart-graph-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                  />
                </div>
                {/* end::Illustration */}

                {/* begin::Link */}
                <div className='mb-0'>
                  <button
                    onClick={() => handleClick()}
                    className='btn btn-sm btn-primary'
                  >
                    <span>Next</span>
                  </button>
                </div>
                {/* end::Link */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkExpired
