import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const SubscriptionPage: React.FC = () => {
    const [data, setData] = useState([]);


    useEffect(() => {
        // Fetch data from API or any other source
        // and update the "data" state
    }, []);

    return (
        <div>
            <h1>Subscription page</h1>
        </div>
    );
};

export default SubscriptionPage;