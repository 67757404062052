import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { confirm } from '../app/modules/confirmation/BasicConfirmation'
import axios from '../lib/axios'
import { useIntl } from 'react-intl'

async function ReportResultViewed(corporate_model_no: string) {
  // try {
  const url = '/api/model/corporate/report-retrieved'
  const response = await axios.post(url, {
    corporate_model_no: corporate_model_no,
  })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

export const useMultiStepForm = (
  product_key: string,
  stepsTitle: string[],
  steps: ReactElement[]
) => {
  const intl = useIntl()
  let navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(0)

  const nextStep = async (corporate_model_no: string | '' = '') => {
    /*  --------------------------------------------------------
        if the last page redirect to corporatemodel result page 
        --------------------------------------------------------*/
    if (currentStep === steps.length - 1) {
      if (
        await confirm(
          intl.formatMessage({ id: 'CM.VIEW_RESULT_CONFIRMATION' }),
          intl.formatMessage({ id: 'CM.YES_PROCEED' }),
          intl.formatMessage({ id: 'CM.CANCEL' }),
          { title: intl.formatMessage({ id: 'POPUP.CONFIRMATION' }) }
        )
      ) {
        setCurrentStep(currentStep + 1)
        ReportResultViewed(corporate_model_no)
        let model_uri = 'corporate-model-main'
        if (product_key === 'corporate-model-report-11112024') {
          model_uri = 'corporate-model-main'
        }
        if (product_key === 'corporate-model-report-1723004859') {
          model_uri = 'corporate-model-bursa'
        }
        if (product_key === 'corporate-model-report-marc-universe-1723878243') {
          model_uri = 'corporate-model-mu'
        }
        if (product_key === 'mid-tier-report-1723872854') {
          model_uri = 'corporate-model-mid-tier'
        }
        navigate(`/${model_uri}/result`, { state: { corporate_model_no: corporate_model_no } })
      }
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
    if (currentStep === 0) {
      navigate('/corporate-model')
    }
  }

  const goToStep = (step, corporate_model_no: string | '' = '') => {
    setCurrentStep(step)
    console.log(`Go to step ${step}`)
    if (step === 6) {
      let model_uri = 'corporate-model-main'
      if (product_key === 'corporate-model-report-11112024') {
        model_uri = 'corporate-model-main'
      }
      if (product_key === 'corporate-model-report-1723004859') {
        model_uri = 'corporate-model-bursa'
      }
      if (product_key === 'corporate-model-report-marc-universe-1723878243') {
        model_uri = 'corporate-model-mu'
      }
      if (product_key === 'mid-tier-report-1723872854') {
        model_uri = 'corporate-model-mid-tier'
      }
      navigate(`/${model_uri}/result`, { state: { corporate_model_no: corporate_model_no } })
    }
  }

  return { currentStep, step: steps[currentStep], stepsTitle, steps, nextStep, prevStep, goToStep }
}