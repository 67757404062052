import React from 'react'
import { Table } from 'react-bootstrap'
import ReactSelect from 'react-select'
import InputSelect from './components/InputSelect'
import { useIntl } from 'react-intl'

function Step4({ companyInformation, companyAnalysis, handleChange }) {
  const intl = useIntl()
  const options = [
    { value: 0, label: '0%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    { value: 55, label: '55%' },
    { value: 60, label: '60%' },
    { value: 65, label: '65%' },
    { value: 70, label: '70%' },
    { value: 75, label: '75%' },
    { value: 80, label: '80%' },
    { value: 85, label: '85%' },
    { value: 90, label: '90%' },
    { value: 95, label: '95%' },
    { value: 100, label: '100%' },
  ]

  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>{intl.formatMessage({ id: 'COMPANY.FINANCIAL_ANALYSIS' })}:</h2>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'CM.FACTORS' })}</th>
              <th>{intl.formatMessage({ id: 'CM.WEIGHT' })} </th>
            </tr>
          </thead>
          <tbody>
            {/* PROFITABILITY */}
            <tr className='bg-light-primary'>
              <td>{intl.formatMessage({ id: 'CM.PROFITABILITY' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='profitability_weight'
                  selectedValue={companyAnalysis.profitability_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.OPERATING_MARGIN' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='operating_margin_weight'
                  selectedValue={companyAnalysis.operating_margin_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.EBITDA_MARGIN' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='ebitda_margin_weight'
                  selectedValue={companyAnalysis.ebitda_margin_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr className='bg-light-dark'>
              <td style={{ textAlign: 'right' }}>{intl.formatMessage({ id: 'CM.TOTAL_PROFITABILITY_WEIGHT' })}</td>
              <td>
                {companyAnalysis.operating_margin_weight + companyAnalysis.ebitda_margin_weight ??
                  0}
                %
              </td>
            </tr>
            {/* LEVERAGE */}
            <tr className='bg-light-primary'>
              <td>{intl.formatMessage({ id: 'CM.LEVERAGE' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='leverage_weight'
                  selectedValue={companyAnalysis.leverage_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='debt_to_equity_weight'
                  selectedValue={companyAnalysis.debt_to_equity_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='debt_to_ebitda_weight'
                  selectedValue={companyAnalysis.debt_to_ebitda_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr className='bg-light-dark'>
              <td style={{ textAlign: 'right' }}>{intl.formatMessage({ id: 'CM.TOTAL_LEVERAGE_WEIGHT' })}</td>
              <td>
                {companyAnalysis.debt_to_equity_weight + companyAnalysis.debt_to_ebitda_weight ?? 0}
                %
              </td>
            </tr>
            {/* CASH FLOW COVERAGE */}
            <tr className='bg-light-primary'>
              <td>{intl.formatMessage({ id: 'CM.CASH_FLOW_COVERAGE' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='cash_flow_weight'
                  selectedValue={companyAnalysis.cash_flow_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='ebitda_to_intexp_weight'
                  selectedValue={companyAnalysis.ebitda_to_intexp_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.CFO_TO_DEBT' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='cfo_to_debt_weight'
                  selectedValue={companyAnalysis.cfo_to_debt_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.FCF_TO_DEBT' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='fcf_to_netdebt_weight'
                  selectedValue={companyAnalysis.fcf_to_netdebt_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })}t</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='cfo_to_intexp_weight'
                  selectedValue={companyAnalysis.cfo_to_intexp_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr className='bg-light-dark'>
              <td style={{ textAlign: 'right' }}>{intl.formatMessage({ id: 'CM.TOTAL_CASH_FLOW_COVERAGE_WEIGHT' })}</td>
              <td>
                {companyAnalysis.ebitda_to_intexp_weight +
                  companyAnalysis.cfo_to_debt_weight +
                  companyAnalysis.fcf_to_netdebt_weight +
                  companyAnalysis.cfo_to_intexp_weight ?? 0}
                %
              </td>
            </tr>
            {/* LIQUIDITY MANAGEMENT */}
            <tr className='bg-light-primary'>
              <td>{intl.formatMessage({ id: 'CM.LIQUIDITY_MANAGEMENT' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='liquidity_weight'
                  selectedValue={companyAnalysis.liquidity_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.CASH_RATIO' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='cash_ratio_weight'
                  selectedValue={companyAnalysis.cash_ratio_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr>
              <td>- {intl.formatMessage({ id: 'CM.CFO_GROWTH' })}</td>
              <td>
                <InputSelect
                  param1='companyAnalysis'
                  param2='cfo_growth_weight'
                  selectedValue={companyAnalysis.cfo_growth_weight}
                  options={options}
                  handleChange={(v, p1, p2) => handleChange(v, p1, p2)}
                />
              </td>
            </tr>
            <tr className='bg-light-dark'>
              <td style={{ textAlign: 'right' }}>{intl.formatMessage({ id: 'CM.TOTAL_LIQUIDITY_MANAGEMENT_WEIGHT' })}</td>
              <td>{companyAnalysis.cash_ratio_weight + companyAnalysis.cfo_growth_weight ?? 0}%</td>
            </tr>
            <tr className='bg-light-primary'>
              <td style={{ textAlign: 'right' }}>{intl.formatMessage({ id: 'CM.TOTAL_QUANTITATIVE_WEIGHT' })}</td>
              <td>
                {companyAnalysis.profitability_weight +
                  companyAnalysis.leverage_weight +
                  companyAnalysis.cash_flow_weight +
                  companyAnalysis.liquidity_weight ?? 0}
                %
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Step4