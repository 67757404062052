import { useIntl } from 'react-intl'
import styled from 'styled-components'

const WatermarkContainer = styled.div`
  position: relative;
  width: 100%;
  
  @media print {
    &::before, &::after {
      position: fixed;
      left: 50%;
      transform-origin: center;
      color: #FF0000;
      opacity: 0.15;
      pointer-events: none;
      z-index: 1000;
      text-align: center;
      text-transform: uppercase;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      mix-blend-mode: multiply;
      text-shadow: 0px 0px 1px rgba(255, 0, 0, 0.3);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0.1em;
    }

    &::before {
      content: attr(data-watermark-main);
      top: calc(50% - 1.5rem);
      transform: translate(-50%, -50%) rotate(-45deg);
      font-size: 4rem;
    }

    &::after {
      content: attr(data-watermark-sub);
      top: calc(50% + 3.5rem);
      transform: translate(-50%, -50%) rotate(-45deg);
      font-size: 1.5rem;
    }
  }
`

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
`



function AFSPrintingHeader({ Title, ComputeForm, CurrentTime }) {
  const intl = useIntl()
  const confidentialText = intl.formatMessage({ id: 'WATERMARK.CONFIDENTIAL' })
  const internalUseText = intl.formatMessage({ id: 'WATERMARK.INTERNAL_USE_ONLY' })

  return (
    <>
      <WatermarkContainer
        data-watermark-main={confidentialText}
        data-watermark-sub={internalUseText}
      >
        <ContentContainer>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 fw-bold fs-4 mb-2'>
            <div className='d-flex flex-column my-4'>
              <div className='d-flex align-items-center mb-2' style={{ fontSize: '32px' }}>
                {Title}
              </div>
            </div>

          </div>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
            <div className='d-flex flex-column my-4'>
              <div className='d-flex align-items-center'>
                <h2>{intl.formatMessage({ id: 'COMPANY.COMPANY_NAME' })}</h2>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <h1 style={{ fontStyle: 'italic' }}>{ComputeForm.companyInformation.company_name}</h1>
              </div>
            </div>

            <div className='d-flex flex-column my-4 mr-4' style={{ paddingRight: '200px' }}>
              <h2>{intl.formatMessage({ id: 'AFS.BUSINESS_INFORMATION' })}</h2>
              <table>
                <tr>
                  <td>{intl.formatMessage({ id: 'COMPANY.REGISTER_NUMBER' })}</td>
                  <td>
                    : <strong>{ComputeForm.companyInformation.register_number}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({ id: 'COMPANY.REFERENCE_NO' })}</td>
                  <td>
                    : <strong>{ComputeForm.sme_model_no}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({ id: 'COMPANY.ISSUE_DATE_TIME' })}</td>
                  <td>
                    : <strong>{CurrentTime}</strong>
                  </td>
                </tr>
              </table>
            </div>
          </div>

        </ContentContainer>
      </WatermarkContainer>
    </>
  )
}

export default AFSPrintingHeader
