import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useToast } from '../../components/ToastProvider';
import { useIntl, FormattedMessage } from 'react-intl';

type LocationState = {
  product_id: string;
};

interface Feature {
  id?: string;
  feature_name: string;
  feature_value: string;
  active: boolean;
}

interface Price {
  id?: string;
  amount: number;
  currency: string;
  active: boolean;
  is_default: boolean;
}

interface ProductFormData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
  subscription_period: string;
  prices: Price[];
  features: Feature[];
}

const AdminEditProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const product_id = (location.state as LocationState)?.product_id;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProductFormData>();

  const { fields: featureFields, append: appendFeature, remove: removeFeature } = useFieldArray({ control, name: 'features' });
  const { fields: priceFields, append: appendPrice, remove: removePrice } = useFieldArray({ control, name: 'prices' });

  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const { showError, showSuccess } = useToast();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/show/${product_id}`);
        const product = response.data;

        setValue('name', product.name);
        setValue('type', product.type);
        setValue('active', product.active);
        setValue('is_subscription', product.is_subscription);
        setValue('subscription_period', product.subscription_period);
        setValue('prices', product.prices);
        setValue('features', product.features);

        product.prices.forEach((price: Price) => appendPrice(price));
        product.features.forEach((feature: Feature) => appendFeature(feature));

        setDataLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setDataLoading(false);
      }
    };

    fetchProduct();
  }, [product_id, setValue, appendPrice, appendFeature]);

  const onSubmit: SubmitHandler<ProductFormData> = async (data) => {
    setDataLoading(true);
    data.id = product_id;
    try {
      const response = await axios.post(`/api/products/update`, data);
      if (response.status === 200) {
        setDataLoading(false);
        showSuccess(intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_TOAST_SUCCESS_PRODUCT_UPDATED' }));
        setTimeout(() => navigate(`/product/admin-all-product`), 2000);
      } else {
        showError(intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_TOAST_ERROR_UPDATE_FAILED' }));
        setDataLoading(false);
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setDataLoading(false);
    }
  };


  const ADMIN_PAGE_TITLE = intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_PAGE_TITLE_ALL_PRODUCT' });

  const ADMIN_PAGE_SUB_TITLE = intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_PAGE_TITLE_EDIT_PRODUCT' });


  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: ADMIN_PAGE_TITLE,
      path: '/product/admin-all-product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: ADMIN_PAGE_SUB_TITLE,
      path: '/product/admin-edit-product',
      isSeparator: false,
      isActive: true,
    },
  ];

  if (dataLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>
        <FormattedMessage id="EDIT_PRODUCT_ELEMENT_PAGE_TITLE_EDIT_PRODUCT" />
      </PageTitle>
      <div className="container">
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">
              <FormattedMessage id="EDIT_PRODUCT_ELEMENT_PAGE_TITLE_EDIT_PRODUCT" />
            </h3>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Product Name */}
              <Form.Group controlId="productName">
                <Form.Label>
                  <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('name', { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_NAME_REQUIRED' }) })}
                  isInvalid={!!errors.name}
                />
                {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
              </Form.Group>

              {/* Product Type */}
              <Form.Group controlId="productType">
                <Form.Label>
                  <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_TYPE" />
                </Form.Label>
                <Form.Control as="select" {...register('type', { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_TYPE_REQUIRED' }) })}>
                  <option value="">{intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_PLACEHOLDER_SELECT_TYPE' })}</option>
                  <option value="Business Report Service">
                    {intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_OPTION_BUSINESS_REPORT_SERVICE' })}
                  </option>
                  <option value="Business Analytics">
                    {intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_OPTION_BUSINESS_ANALYTICS' })}
                  </option>
                </Form.Control>
                {errors.type && <Form.Control.Feedback type="invalid">{errors.type.message}</Form.Control.Feedback>}
              </Form.Group>

              {/* Prices Section */}
              <h4>
                <FormattedMessage id="EDIT_PRODUCT_ELEMENT_HEADING_PRICES" />
              </h4>
              {priceFields.map((field, index) => (
                <div key={field.id}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`priceAmount-${index}`}>
                        <Form.Label>
                          <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_AMOUNT" />
                        </Form.Label>
                        <Form.Control
                          type="number"
                          {...register(`prices.${index}.amount`, { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_AMOUNT_REQUIRED' }) })}
                          isInvalid={!!errors.prices?.[index]?.amount}
                        />
                        {errors.prices?.[index]?.amount && <Form.Control.Feedback type="invalid">{errors.prices?.[index]?.amount?.message}</Form.Control.Feedback>}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`priceCurrency-${index}`}>
                        <Form.Label>
                          <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_CURRENCY" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register(`prices.${index}.currency`, { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_CURRENCY_REQUIRED' }) })}
                          isInvalid={!!errors.prices?.[index]?.currency}
                        />
                        {errors.prices?.[index]?.currency && <Form.Control.Feedback type="invalid">{errors.prices?.[index]?.currency?.message}</Form.Control.Feedback>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId={`priceActive-${index}`}>
                    <Form.Check
                      type="checkbox"
                      label={intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_LABEL_ACTIVE' })}
                      {...register(`prices.${index}.active`)}
                    />
                    <Form.Check
                      type="checkbox"
                      label={intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_LABEL_DEFAULT_PRICE' })}
                      {...register(`prices.${index}.is_default`)}
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => removePrice(index)} className="mt-2">
                    <FormattedMessage id="EDIT_PRODUCT_ELEMENT_BUTTON_REMOVE_PRICE" />
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={() => appendPrice({ amount: 0, currency: '', active: true, is_default: false })} className="mt-3 btn btn-success">
                <FormattedMessage id="EDIT_PRODUCT_ELEMENT_BUTTON_ADD_PRICE" />
              </Button>

              {/* Features Section */}
              <h4>
                <FormattedMessage id="EDIT_PRODUCT_ELEMENT_HEADING_FEATURES" />
              </h4>
              {featureFields.map((field, index) => (
                <Row key={field.id}>
                  <Col md={6}>
                    <Form.Group controlId={`featureName-${index}`}>
                      <Form.Label>
                        <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_FEATURE_NAME" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.feature_name`, { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_FEATURE_NAME_REQUIRED' }) })}
                        isInvalid={!!errors.features?.[index]?.feature_name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId={`featureValue-${index}`}>
                      <Form.Label>
                        <FormattedMessage id="EDIT_PRODUCT_ELEMENT_LABEL_FEATURE_VALUE" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.feature_value`, { required: intl.formatMessage({ id: 'EDIT_PRODUCT_ELEMENT_ERROR_FEATURE_VALUE_REQUIRED' }) })}
                        isInvalid={!!errors.features?.[index]?.feature_value}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ))}
              <Button type="button" onClick={() => appendFeature({ feature_name: '', feature_value: '', active: true })} className="mt-3 btn btn-primary">
                <FormattedMessage id="EDIT_PRODUCT_ELEMENT_BUTTON_ADD_FEATURE" />
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditProduct;
